
export function getGroupUpdateBody({ itemOptions2, groupList }) {
  const posName = itemOptions2.optionName
  const jsonValue = JSON.stringify(groupList)

  if (posName === "OKPOS") return { "TuClsList": jsonValue }
  if (posName === "EASY_POS") return { "touchClassList": jsonValue }
  if (posName === "unionpos") return { "GrpList": jsonValue }
  if (posName === "DUMMY_POS" || posName === "OASIS_POS") return { groupList: jsonValue }
  return {}
}