import { PubSub } from "@aws-amplify/pubsub";
// import { AWSIoTProvider } from "@aws-amplify/pubsub/lib/Providers";
// import { conf } from "../settings";

/** ***************************************************************************
 * @PubSub
 *  **************************************************************************/
// export const connect = () => {
//   Amplify.addPluggable(
//     new AWSIoTProvider({
//       aws_pubsub_region: conf.iot.REGION,
//       aws_pubsub_endpoint: conf.iot.ENDPOINT,
//     })
//   );
// };

/**
 * 
 * @param {S} props.topic
 * @param {Object} props.message
 */
export const publish = ({ topic, message }) => {
  return PubSub.publish(topic, message);
};

/**
 *
 * @param {S} props.topic
 * @param {Fn} props.messageHandler
 */
export const subscribe = ({ topic, messageHandler }) => {
  return PubSub.subscribe(topic).subscribe({
    next: messageHandler,
    error: (err) => console.log("Subscribe Error:", err),
    close: () => console.log("Connection Closed!!"),
    complete: () => console.log("Subscribe Done")
  });
};

/**
 * @Request_Order_to_tablet
 * 
 * @param {S} props.shopId
 * @param {S} props.tableNo
 * @param {S} props.userId
 * @param {S} props.orderId
 * @param {A} props.orderList object Array [{itemName, count, price, ...rest}], shopItem 내용과 동일함
 */
export const publishOrder = ({ 
  shopId, 
  tableNo, 
  userId, 
  orderId, 
  orderName, 
  orderList,
  param     // "/order" | "/order/ordering "
}) => {
  const topic = `/tablepay/tablet/${shopId}/${tableNo}`;
  const msg = {
    msgType: "request",
    param,
    rTopic: `${topic}/${userId}`,
    userId,
    tableNo,
    data: {
      orderId,
      orderName,
      orderList, // [{ itemName: "아무거나", count: 2, price: 7000 },],
    },
  };
  publish({ topic, message: msg });
};

/**
 * 
 * @Request_Dutchpay_Join_Pay
 * 
 * @param {S} props.pathParam
 */
export const publishDpRequest = ({
  shopId, 
  tableNo, 
  userId, 
  dpItems, 
  persons, 
  dpUsers, // pay 요청 때만
  param
})=>{
  const topic = `/tablepay/dp/${shopId}/${tableNo}`;
  const msg = {
    msgType: "request",
    param, // /dutchpay/join | /dutchpay/pay
    ownerId: userId,
    rTopic: `/tablepay/dp/${shopId}/${tableNo}/${userId}`,
    data: {
      dpItems,
      persons, 
      dpUsers // pay only
    },
  };
  publish({ topic, message: msg });
}
