import { notSupported } from "./posList"

const pos = {
  "UNIONPOS": { getTableInfo: getTableInfoUnionpos },
  "OKPOS": { getTableInfo: getTableInfoOkpos },
  "DUMMY_POS": { getTableInfo: notSupported },
  "OASIS_POS": { getTableInfo: getTableInfoOasispos },
  "EASY_POS": { getTableInfo: getTableInfoOasispos }
}

const posList = Object.keys(pos)

export function getTableInfo({ shopInfo, tableNo, itemOptions2 }) {
  if (!posList.includes(shopInfo.posName)) return notSupported()
  return pos[shopInfo.posName].getTableInfo({ tableNo, itemOptions2 })
}

function getTableInfoUnionpos({ tableNo, itemOptions2 }) {
  const tables = itemOptions2.TableList.filter(tbl => tbl.TableNo === parseInt(tableNo))
  if (tables.length === 0) return { err: { message: "TABLE NO NOT EXIST" } }

  const tableText = tables[0].TableName.includes("테이블")
    ? tables[0].TableName
    : "테이블 " + tables[0].TableNo

  const table = { ...tables[0], tableText }

  return { table }
}

function getTableInfoOkpos({ tableNo, itemOptions2 }) {
  const tables = itemOptions2.TableList.filter(tbl => {
    return parseInt(tbl.TableNo).toString() === parseInt(tableNo).toString()
  })
  if (tables.length === 0) return { err: { message: "TABLE NO NOT EXIST" } }

  const tableText = tables[0].TableName.includes("테이블")
    ? tables[0].TableName
    : "테이블 " + tables[0].TableName

  const table = { ...tables[0], tableText }

  return { table }
}

function getTableInfoOasispos({ tableNo, itemOptions2 }) {
  const tables = itemOptions2.tableList.filter(tbl => {
    return parseInt(tbl.tableNo).toString() === parseInt(tableNo).toString()
  })
  if (tables.length === 0) return { err: { message: "TABLE NO NOT EXIST" } }

  const tableText = tables[0].tableName.includes("테이블")
    ? tables[0].tableName
    : "테이블 " + tables[0].tableName

  const table = { ...tables[0], tableText }
  return { table }
}

function getTableInfoEasypos({ tableNo, itemOptions2 }) {
  const tables = itemOptions2.tableList.filter(tbl => {
    return parseInt(tbl.tableNo).toString() === parseInt(tableNo).toString()
  })
  if (tables.length === 0) return { err: { message: "TABLE NO NOT EXIST" } }

  const tableText = tables[0].tableName.includes("테이블")
    ? tables[0].tableName
    : "테이블 " + tables[0].tableName

  const table = { ...tables[0], tableText }
  return { table }
}
