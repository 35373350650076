import {
  okposItemOptionUpdate, okposItemUpdate,
  shopItemOptionUpdate,  shopItemUpdate,
  shopItemDelete,
} from "../lib/ec2-api-lib";
import { isJson } from "../lib/utils";
import { debug } from "../settings";

const pos = {
  "UNIONPOS": { resetOnlMenu: resetOnlMenuUnionpos },
  "OKPOS": { resetOnlMenu: resetOnlMenuOkpos },
  "EASY_POS": { resetOnlMenu: resetEasypos },
}

const posList = Object.keys(pos)

/**
 * 
 * @param {*} params { shopInfo, itemList, itemOptions, itemOptions2 }
 * @returns 
 */
export function resetOnlMenu(params) {
  if (!posList.includes(params.shopInfo.posName)) return { err: { message: "INVALID POSNAME" } };
  return pos[params.shopInfo.posName].resetOnlMenu(params)

}

async function resetOnlMenuOkpos({ shopInfo, itemList, itemOptions, itemOptions2 }) {
  try {

    // 
    // reset GrpList
    //  
    const TuClsList = itemOptions2.TuClsList;
    // const GrpList = itemOptions2.GrpList;
    // const MidGrpList = itemOptions2.MidGrpList;
    // const SmGrpList = itemOptions2.SmGrpList;

    const newTuClsList = TuClsList.map(group => {
      return {
        ...group,
        GrpName: group.TU_CLS_NM,
        name: group.TU_CLS_NM,
        displayName: group.TU_CLS_NM,
        hidden: false
      }
    })

    let res = await okposItemOptionUpdate({
      shopId: shopInfo.shopId,
      optionName: itemOptions.optionName,
      body: {
        TuClsList: JSON.stringify(newTuClsList)
      }
    });
    if (res.err) throw Error(res.err.message + ` - 그룹리스트 초기화 중`);

    // 
    // reset itemList
    // 
    await Promise.all(itemList.map(async item => {
      const newItem = {
        itemName2: item.itemName,
        // nameLang: JSON.stringify([]),
        description: "",
        // descriptionLang: JSON.stringify([]),
        // image: "",
        removed: false,
        isSoldout: item.SOLD_OUT_YN === "Y",
        isNew: false,
        isHot: false,
        isBest: false,
      }

      const res = await okposItemUpdate({ shopId: shopInfo.shopId, id: item.id, body: newItem })
      if (res.err) throw Error(res.err.message + ` - 메뉴항목 itemCode ${item.itemCode} 초기화 중`);
      return newItem;
    }));

    return {}
  } catch (e) {
    return { err: { message: "초기화 과정에서 에러가 발생하였습니다. 관리자에게 문의하세요!\n" + e.message } }
  }
}

async function resetOnlMenuUnionpos({ shopInfo, itemList, itemOptions }) {
  try {

    // 
    // reset GrpList
    //  
    const groupList = isJson(itemOptions.GrpList) ? JSON.parse(itemOptions.GrpList) : [];

    const newGrpList = await Promise.all(groupList.map(async group => {
      // console.log(group.GrpCode, group.name, group.displayName, group.hidden)
      return {
        ...group,
        name: group.GrpName,
        displayName: group.GrpName,
        hidden: false
      }
    }))

    if (debug) {
      newGrpList.forEach(group => {
        if (group.GrpName !== group.displayName) {
          console.log("Reset Failed Grp Found")
          console.log('  - ', group.GrpCode, group.name, group.displayName, group.hidden)
        }
      })
    }

    let res = await shopItemOptionUpdate({
      shopId: shopInfo.shopId,
      optionName: "unionpos",
      body: { GrpList: JSON.stringify(newGrpList) }
    });
    if (res.err) throw Error(res.err.message + ` - 그룹리스트 초기화 중`);

    // 
    // reset itemList
    // 
    await Promise.all(itemList.map(async item => {
      const newItem = {
        itemName2: item.itemName,
        nameLang: JSON.stringify([]),
        description: "",
        descriptionLang: JSON.stringify([]),
        // image: "",
        removed: false,
        isSoldout: item.itemSoldOut === "Y",
        isNew: item.itemTagNew === "Y",
        isHot: item.itemTagHot === "Y",
        isBest: item.itemTagBest === "Y",
      }


      const res = await shopItemUpdate({ shopId: shopInfo.shopId, id: item.id, body: newItem })
      if (res.err) throw Error(res.err.message + ` - 메뉴항목 itemCode ${item.itemCode} 초기화 중`);
      return newItem;
    }));

    // if (debug) console.log(newServerItems)

    return {}
  } catch (e) {
    return { err: { message: "초기화 과정에서 에러가 발생하였습니다. 관리자에게 문의하세요!\n" + e.message } }
  }
}

async function resetEasypos({ shopInfo, itemList }) {
  try {
    const optionNames = [
      "tableGroupList", "tableList",
      "touchClassList",   // groupList 에 해당
      // "menuSettings",
      "tableCallList",
      "subItemList", "orderClassList", "orderList", "itemOrderClassList"
    ]

    const optionBody = {}
    optionNames.map(n=>{
      optionBody[n] = JSON.stringify([])
    })

    let res = await shopItemOptionUpdate({
      shopId: shopInfo.shopId,
      optionName: shopInfo.posName,
      body: optionBody
    });
    if (res.err) throw Error(res.err.message + ` - 그룹리스트 초기화 중`);

    // 
    // delete itemList
    // 
    await Promise.all(itemList.map(async item => {
      const res = await shopItemDelete({ shopId: shopInfo.shopId, id: item.id })
      if (res.err) throw Error(res.err.message + ` - 메뉴항목 itemCode ${item.itemCode} 초기화 중`);
      return null;
    }));

    // if (debug) console.log(newServerItems)

    return {}
  } catch (e) {
    return { err: { message: "초기화 과정에서 에러가 발생하였습니다. 관리자에게 문의하세요!\n" + e.message } }
  }
}
