import { useEffect, useState } from "react"
import { LanguageIcon } from "../../../components/Icons"
import { targetLangs } from "../../../settings/strings";
import { FormInput, ModalForm, Button, useStyles, FormBox } from "../../../components";
import { useInputFields } from "../../../lib/hooksLib";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedLang } from "../../../redux/shop";

export default function ChangeLangModal(props) {
  const {
    shopInfo,
    item,
    itemOptions3, itemOptions2,
    visible,
    setVisible,
    onload,
  } = props;

  const { selectedLanguage } = useSelector(state => state.shop)
  const dispatch = useDispatch()

  const title = `메뉴 언어`;
  const [isLoading, setIsLoading] = useState(false);

  const supportedLangs = itemOptions3.menuSettings?.supportedLangs || [];
  const selectList = [...supportedLangs]

  const [fields, setFields] = useInputFields({
    selectedLang: selectedLanguage
  })

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      dispatch(setSelectedLang({ selectedLanguage: fields.selectedLang }))
    } catch (e) {
      alert(e.message);
    } finally {
      setIsLoading(false);
      setVisible(false);
    }
  };

  return (
    <ModalForm
      visible={visible} setIsVisible={setVisible} title={title}
      onSubmit={onSubmit} isLoading={isLoading}
    >

      <FormInput
        label="메뉴 언어 선택"
        name="selectedLang"
        value={fields.selectedLang}
        onChange={setFields}
        inputType="select"
        selectList={selectList}
      />

    </ModalForm>
  )
}