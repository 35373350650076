import React, { useEffect, useState } from "react";
import { CardContainer, useStyles, CustomTable } from "../../../components";
import { Pencil } from "../../../components/Icons";
import { debug } from "../../../settings";
import OKPosSdsModal from "./OKPosSdsModal";

const tableHeaders = {
  SdsGrList: [
    { name: "SDS_GRP_CD", displayName: "그룹코드(SDS_GRP_CD)" },
    { name: "SDS_GRP_NM", displayName: "이름" },
    { name: "USE_YN", displayName: "사용여부" },
  ],
  SdsClsList: [
    { name: "SDS_GRP_CD", displayName: "그룹코드(SDS_GRP_CD)" },
    { name: "SDS_CLS_CD", displayName: "코드(SDS_CLS_CD)" },
    { name: "SDS_CLS_NM", displayName: "이름" },
    { name: "USE_YN", displayName: "사용여부" },
    { name: "required", displayName: "필수여부", type: "boolean" },
  ],
  SdsCdList: [
    { name: "SDS_CLS_CD", displayName: "분류코드(SDS_CLS_CD)" },
    { name: "SDS_CD", displayName: "코드(SDS_CD)" },
    { name: "SDS_NM", displayName: "이름" },
    // { name: "PROD_CD", displayName: "아이템코드" },
    { name: "SDS_PROD_UPRC", displayName: "가격", type: "number" },
  ],
  checkObj: { name: "checked", displayName: "선택", type: "checkbox" },
  sorting: { name: "sorting", displayName: "순서", type: "sorting" },
}

export default function PosSideMenus(props) {
  const { itemOptions3 } = props

  const { gStyle, colors } = useStyles();
  const title = "포스 옵션메뉴 필수여부 편집";

  const [sdsGrList, setSdsGrList] = useState([])
  const [sdsClsList, setSdsClsList] = useState([])
  const [sdsCdList, setSdsCdList] = useState([])

  const [visibleSdsEdit, setVisibleSdsEdit] = useState(false)

  useEffect(() => {
    const start = () => {
      if (debug) {
        console.table(itemOptions3.SdsGrList)
        console.table(itemOptions3.SdsClsList)
      }

      // console.table(itemOptions3.SdsGrList)
      // console.table(itemOptions3.SdsClsList)
      // console.table(itemOptions3.SdsCdList)

      const _sdsGrList = itemOptions3.SdsGrList || []
      const _sdsClsList = itemOptions3.SdsClsList || []
      const _sdsCdList = itemOptions3.SdsCdList || []

      setSdsGrList(_sdsGrList)
      setSdsClsList(_sdsClsList)
      setSdsCdList(_sdsCdList)
    }
    start();
  }, [itemOptions3])

  const modalProps = {
    sdsClsList,
    tableHeaders,
  }

  const styles = {
    subTitleBar: {
      margin: 5,
      display: "flex",
      alignItems: "center"
    },
    subTitle: {
      ...gStyle.subtitle
    },
    iconPlus: { ...gStyle.subtitle, color: colors.primary, marginLeft: "20px" },
  }

  return (
    <div>
      <CardContainer
        title={title}
        onClickBack={() => props.history.goBack()}
      >
        <div style={styles.subTitleBar}>
          <p style={styles.subTitle}>선택그룹 리스트</p>
        </div>
        <CustomTable tableHeader={tableHeaders.SdsGrList} rowItems={sdsGrList} />
        <NoItems items={sdsGrList} />
        <br />

        <div style={styles.subTitleBar}>
          <p style={styles.subTitle}>선택분류 리스트</p>
          <Pencil onClick={() => setVisibleSdsEdit(true)} style={styles.iconPlus} />
        </div>
        <CustomTable tableHeader={tableHeaders.SdsClsList} rowItems={sdsClsList} />
        <NoItems items={sdsClsList} />
        <br />

        <div style={styles.subTitleBar}>
          <p style={styles.subTitle}>선택아이템 리스트</p>
        </div>
        <CustomTable tableHeader={tableHeaders.SdsCdList} rowItems={sdsCdList} />
        <NoItems items={sdsCdList} />

      </CardContainer>

      {visibleSdsEdit &&
        <OKPosSdsModal
          {...props}
          {...modalProps}
          visible={visibleSdsEdit}
          setVisible={setVisibleSdsEdit}
        />
      }

    </div>

  )
}

function NoItems({ items }) {
  const { gStyle } = useStyles()
  const styles = {
    noItems: { ...gStyle.subtitle, padding: "2px 15px", fontWeight: "500", textAlign: "center" },
  }

  if (items.length === 0) return <p style={styles.noItems}>No Items</p>
  return null
}