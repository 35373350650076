import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartItems: [], // {itemName, price, count, amount, itemId}  
};

export const cart = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addCartItem: (state, action) => {
      const newItem = action.payload.item;
      newItem.dp = false;
      newItem.payAmount = newItem.count * parseInt(newItem.price);
      const index = state.cartItems.findIndex(item => item.itemName === newItem.itemName);
      if (index < 0) state.cartItems.push(newItem); // 없으면 추가
      else {
        state.cartItems[index].count += newItem.count; // 있으면 count만 udpate
        state.cartItems[index].payAmount += newItem.payAmount
      } 
    },
    removeCartItem: (state, action) => {
      let arr = [...state.cartItems];      
      state.cartItems = arr.filter(
        (item) => item.itemName !== action.payload.itemName
      );
    },
    upCartItemCount: (state, action) => {
      const newItems = state.cartItems;
      const index = newItems.findIndex(item => item.itemName === action.payload.itemName);
      newItems[index].count += 1;
      newItems[index].payAmount = newItems[index].count * newItems[index].price;
    },
    downCartItemCount: (state, action) => {
      const newItems = state.cartItems;
      const index = newItems.findIndex(item => item.itemName === action.payload.itemName);
      newItems[index].count -= 1;
      newItems[index].payAmount = newItems[index].count * newItems[index].price;
    },

    /**
     * 
     * @deprecated
     */
    replaceCartItem: (state, action) => {      
      const index = state.cartItems.findIndex(item => item.itemName === action.payload.item.itemName);
      state.cartItems[index] = action.payload.item;
    },

    resetCart: (state, action) => {
      state.cartItems = initialState.cartItems;
    },
  },
});

export const { 
  addCartItem, 
  removeCartItem, 
  upCartItemCount,
  downCartItemCount,
  // replaceCartItem,   
  resetCart 
} = cart.actions;

export default cart.reducer;

// function replaceItemIfExist(items, item) {
//   for (let i = 0; i < items.length; i++) {
//     if (items[i].itemName === item.itemName) {
//       items[i].count += item.count;
//       return items;
//     }
//   }
//   return null;
// }
