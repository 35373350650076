import { unionposProxy2, okposProxy } from "../lib/ec2-api-lib";
import { getOnlOptionItems } from "./getOnlOptionItems";
import { posList, notSupported } from "./posList";

const pos = {
  "UNIONPOS": { createItemObj: createItemObjUnionpos },
  "OKPOS": { createItemObj: notSupported },
  "DUMMY_POS": { createItemObj: notSupported },
  "OASIS_POS": { createItemObj: notSupported }
}

/**
 * 
 * @param {*} props {shopInfo, item, itemList, itemOptions2}
 * @returns 
 */
export function createItemObj(props) {
  if (!posList.includes(props.shopInfo.posName)) return { err: { message: "INVALID POSNAME" } };
  return pos[props.shopInfo.posName].createItemObj(props);
}

function createItemObjUnionpos({ item, itemList, itemOptions2 }) {
  const SetMenuList = itemOptions2.SetMenuList;
  const ChoiceMenuList = itemOptions2.ChoiceMenuList;
  const orderItem = item
  const itemObj = {}

  if (orderItem.menuType2 !== "CHOICE") {
    // console.log("\nOrderItem")
    // showItemList([orderItem]);
    itemObj.item = orderItem;
  }

  /** @단품메뉴 */
  if (orderItem.menuType2 === undefined || orderItem.menuType2 === "") {
    return itemObj;
  }

  if (orderItem.menuType2 === "FIXED" || orderItem.menuType2 === "DYNAMIC") {

    let menuList = SetMenuList.filter(el => el.MenuCode === orderItem.menuCode)
    itemObj.SetMenuList = menuList

    const setMenuItemList = []
    menuList.map(menu => {
      const newList = itemList.filter(el => el.itemCode === menu.ItemCode)
      if (newList.length === 1) {
        setMenuItemList.push({ ...newList[0], ...menu })
      }
      return null
    })

    /** @고정세트메뉴 */
    itemObj.SetMenuItemList = setMenuItemList
    if (orderItem.menuType2 === "FIXED") return itemObj
  } else {
    // console.log("\nINVALID MenuType")
    return { err: { message: "INVALID MenuType" } }
  }

  // 
  // SetMenuItemList.item.ChoiceMenuList 추가 - ChoiceMenuList = [ {...menu, itemList}, ]
  // 
  /** @변동세트메뉴 */
  const choiceMenuList = []
  itemObj.SetMenuItemList.map(item => {
    const myChoiceMenuList = ChoiceMenuList.filter(el => el.MenuCode === item.menuCode)

    const updatedChoiceMenuList = myChoiceMenuList.map(menu => {
      const items = itemList.filter(el => el.itemCode === menu.ItemCode)
      return { ...menu, itemList: items }
    })

    choiceMenuList.push({ ...item, ChoiceMenuList: updatedChoiceMenuList })
    return null
  })

  itemObj.SetMenuItemList = choiceMenuList
  return itemObj;
}
