import React, { useEffect, useState } from "react";
import { useStyles, ModalForm, Button, Input } from "../../../components";
import { getSecurePhoneNumber, getMidNumber } from "../../../lib/utils";
import { kisPayCancel } from "../../../lib/pay-api-lib";
import { debug } from "../../../settings";

const str = {
  noItemsSelected: "선택된 항목이 없습니다!",
  noName: "명칭이 비어 있습니다!"
}

export function PayDataViewModal(props) {
  const {
    visible, setVisible,
    payItem,
    next
  } = props;

  const [payInfo, setPayInfo] = useState([])

  useEffect(() => {
    if (payItem.shopId) onload_kispg()
  }, [payItem])

  const onload_kispg = () => {
    if (payItem.pgName === "KISPG") {

      if (debug) console.log(payItem)

      const pData = payItem.payDataObj

      const kispgParams2 = [
        { name: "tid", displayName: "결제 번호" },
        { name: "amt", displayName: "결제 금액" },
        { name: "goodsName", displayName: "상품명" },
        { name: "payMethod", displayName: "결제 방법" },
        { name: "fnNm", displayName: "카드사" },
        { name: "cardNo", displayName: "카드 번호" },
        { name: "appDtm", displayName: "주문 일시" },
      ]
      const pInfo = kispgParams2.map(item => {
        return { name: item.displayName, value: pData[item.name] }
      })
      pInfo.push({ name: "휴대폰 번호", value: getSecurePhoneNumber(payItem.phone_number) })

      setPayInfo(pInfo)
    }
  }

  const onSubmit = async () => {
    if(payItem.isPayCanceled) {
      alert("이미 취소된 거래입니다.")
      setVisible(false)
    } else next()
  }

  return (
    <ModalForm
      visible={visible}
      setIsVisible={setVisible}
      title={"결제 정보 확인"}
      onSubmit={onSubmit}
      btnText={"결제 취소 (추가 확인 절차를 진행합니다.)"}
    >

      <div style={{ padding: "15px 0px" }}>
        <NameValueTable data={payInfo} />
      </div>

    </ModalForm>
  )
}

/**
 * 
 * @param {Array} data
 */
function NameValueTable({ data }) {
  const { gStyle, colors } = useStyles()
  const styles = {
    line: { ...gStyle.text, padding: "2px 5px" }
  }
  return (
    data.map((item, i) => {
      const bgColor = (i % 2 === 0) ? colors.light : "transparent"
      return (
        <div key={i} style={{ display: "flex", justifyContent: "space-between", backgroundColor: bgColor }}>
          <p style={{ ...styles.line }}>{item.name}</p>
          <p style={{ ...styles.line, width: "60%" }}>{item.value}</p>
        </div>

      )
    })
  )
}

export function CancelConfirmModal(props) {
  const {
    visible, setVisible,
    payItem,
    onload
  } = props;

  const { gStyle, colors } = useStyles()

  const [isLoading, setIsLoading] = useState(false)
  const [inputNum, setInputNum] = useState("")

  useEffect(() => {
    if(debug) setInputNum("5317")
  }, [payItem])

  const onSubmit = async () => {
    try {
      setIsLoading(true)

      const phoneMidNum = getMidNumber(payItem.phone_number)
      if (inputNum !== phoneMidNum) throw Error("유효하지 않은 번호입니다.")

      const body = {
        shopId: payItem.shopId,
        orderId: payItem.orderId,
        tid: payItem.payDataObj.tid,
        // goodsAmt: payItem.payDataObj.amt,
        orderAmt: payItem.payDataObj.amt,  // 오아시스 포스에서와 같은 파라미터로 통일함
      }

      let res = await kisPayCancel({ body })
      if (res.err) throw Error(res.err.message)

      alert(res.result.message)
      onload()
    } catch (e) {
      alert(e.message)
    } finally {
      setIsLoading(false)
      setVisible(false)
    }
  }

  return (
    <ModalForm
      visible={visible}
      setIsVisible={setVisible}
      title={"결제 취소 재확인"}
      onSubmit={onSubmit}
      isLoading={isLoading}
      btnText={"결제 취소 확인"}
    >
      <div style={{ padding: "15px 0px" }}>
        <p style={gStyle.text}>휴대전화 번호: {getSecurePhoneNumber(payItem.phone_number)}</p>
        <p style={{ ...gStyle.text, ...gStyle.bold, color: colors.danger }}>휴대전화 번호의 가운데 숫자를 입력하세요!</p>
        <Input name="digits" value={inputNum} onChange={(e) => setInputNum(e.target.value)} />
      </div>
    </ModalForm>
  )
}
