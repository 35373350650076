import axios from "axios";
import { debug, conf } from "../../settings";

const axiosCall = async ({ method, path, body }) => {
  try {
    // const baseUrl = conf.oasispos.debug
    //   ? conf.oasispos.apiEndpoint_local
    //   : conf.oasispos.apiEndpoint_public
    const baseUrl = conf.ec2Api.debug
      ? conf.ec2Api.endpoint_debug
      : conf.ec2Api.endpoint_public

    let res = await axios({
      method,
      url: baseUrl + path,
      data: body,
      headers: { 'x-taptag-key': conf.apiKey }
    });

    if (res.status !== 200) throw new Error("Response Error");
    return res.data;
  } catch (e) {
    return { err: { message: e.message } };
  }
}

/** @OASIS_POS_Proxy */
export const oasisposProxy = async ({ apiName, body }) => {
  // 
  // apiNames: 
  //  "allTable": "/allTable",        // 모든 테이블 정보
  //  "inqTable": "/inqTable",        // 특정 테이블 정보
  //  "callManager": "/callManager",  // 직원 콜
  //  "callService": "/callService",  // 서비스 콜        - 필요한가
  //  "orderInq": "/orderInq",        // 주문 확인
  //  "orderInput": "/orderInput",    // 주문
  //  "menuGroup": "/menuGroup",      // 메뉴그룹 리스트
  //  "setMenu": "/setMenu",          // 세트메뉴 리스트
  //  "menu": "/menu",
  // 
  let path = `/pos/oasispos?apiName=${apiName}`;
  // if (debug) path += '&mode=debug';
  path += '&mode=debug';
  return await axiosCall({ method: "POST", path, body });
};