import { OnlDropdown, DropdownItem, Divider } from "./CustomDropdown";
// import { OnlDropdown } from "./CustomDropdown";

OnlDropdown.Item = DropdownItem
OnlDropdown.Divider = Divider
export { OnlDropdown, DropdownItem }

export { Page, Section } from "./Layout";

export { colors, gStyle, gStyleMobile, useStyles } from "./ui";

export {
  FormInput,
  InputWithBtn,
  Input,
  CheckBox,
  FileInput,
  Search,
  SelectFilters,
} from "./FormInputs";

// export { default as LoaderButton } from "./LoaderButton";

export { LoaderFullPage, Loader } from "./Loaders";

export { Alert, ModalView, PostCodeView, CustomModal, ModalForm, FormGroup, FormBox } from "./Modals";

export {
  Button,
  UnderlineButton,
  // OutlineButton 
} from "./Buttons";

// export { CustomModal } from "./CustomModal";

export { CardContainer } from "./CardContainer";

export { CustomTable } from "./Tables";

export { CalendarDropdown } from "./CalendarInput2"
