import React from "react";
import { Page } from "../components";
import "./NotFound.css";

export default function NotFound() {
  const styles = {
    title: {
      paddingTop: "30%",
      fontSize: "48px",
      fontWeight: "600",
      textAlign: "center",
      color: "#555"
    }
  }
  return (
    <Page>
      <h1 style={styles.title}>Sorry!! the Page is Not Found</h1>
    </Page>
  )
}
