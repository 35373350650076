export const posList = [
  "OKPOS",
  "UNIONPOS",
  "DUMMY_POS",
  "OASIS_POS",
  "EASY_POS",
]

export const notSupported = () => {
  return { err: { message: "Not Suppported" } }
}