import conf from "../conf";
const debug = conf.debug;

export const signup = {
  inputFields: [
    {
      name: "username",
      displayName: "사용자 아이디",
      comment: "영문자, 숫자 등 5자 이상이면 됩니다.",
      validate: { type: "username" },
      defaultValue: debug ? "psdl-easypos" : "",
    },
    {
      name: "phone_number",
      displayName: "휴대폰 번호",
      comment: "휴대폰번호('-' 없이)",
      validate: { type: "phone_number" },
      defaultValue: debug ? "01053174673" : "",
    },
    {
      name: "password",
      displayName: "비밀번호",
      type: "password",
      inputType: "password",
      validate: { type: "password" },
      comment: "A-a, 특수문자, 숫자 조합의 8자 이상이 필요합니다.",
      defaultValue: debug ? "a123456!" : "",
    },
    {
      name: "confirmPassword",
      displayName: "비밀번호 확인",
      type: "password",
      inputType: "password",
      validate: { type: "password" },
      defaultValue: debug ? "a123456!" : "",
    },

  ],
};

export const findPassword = {
  inputFields: [
    {
      name: "username",
      displayName: "사용자 아이디",
      editable: false,
      defaultValue: debug ? "shop-4673-4" : "",
    },
    {
      name: "phone_number",
      displayName: "휴대폰 번호",
      editable: false,
      comment: "- 를 제외하고 입력하세요!",
      defaultValue: debug ? "01053174673" : "",
      validate: { type: "phone_number" }
    },
    {
      name: "code",
      displayName: "인증코드",
      comment: "휴대폰에 전송된 코드를 입력하세요!",
    },
  ],
  inputFieldsPw: [
    {
      name: 'password',
      type: 'password',
      inputType: "password",
      displayName: "새 비밀번호",
      comment: "비밀번호(8~25자리 영문자, 숫자, 특수문자 조합)",
      validate: { type: "password" },
      defaultValue: debug ? "psdl12@$" : ""
    },
    {
      name: 'confirmPassword',
      type: 'password',
      inputType: "password",
      comment: "비밀번호 확인",
      defaultValue: debug ? "psdl12@$" : ""
    },
  ]
}