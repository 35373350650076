import React, { useEffect, useState } from "react";
import { useStyles, LoaderFullPage, Alert } from "../../../components";
import { Pencil, ArrowL, Clock, Sorting } from "../../../components/Icons";
import ModifyGroupAliasModal from "./ModifyGroupAliasModal";
import MenuItem from "./MenuItem";
import { updateItem, getSelectedGroup, getSelectedItems, updateItemOption } from "../../../lib-pos";
import { getGroupName } from "../../../lib-pos/parseNames";
import { useDraggable } from "../../../lib/hooksLib";
import { debug } from "../../../settings";
import ModifyServiceTimeModal from "./ModifyServiceTimeModal";
import SortingModal from "./SortingModal2";
import { useSelector } from "react-redux";
import { serviceTimeSet } from "./shop-utils";

const str = {
  alertFailUpdate: "정보를 수정하는 데 실패했습니다!",
}

export default function MenuList(props) {
  const {
    shopInfo,
    selectedGroup2,
    itemList,
    usePosMenuFirst,
    itemOptions2,
    setMenuClicked,
    showRemoved,
    onload
  } = props;

  const { gStyle, colors } = useStyles();

  const { selectedLanguage } = useSelector(state => state.shop)

  const [sortedItems, setSortedItems] = useState([]);
  const [selGroup, setSelGroup] = useState({})
  const [groupAlias, setGroupAlias] = useState(false);
  const [visibleServiceTime, setVisibleServiceTime] = useState(false)
  const [visibleSorting, setVisibleSorting] = useState(false)

  const [groupDispName, setGroupDispName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [grpSTime, setGrpSTime] = useState(false)

  useEffect(() => {
    // 
    // group명 변경 시 auto refresh를 위해 itemOpitons2 에서 grp을 가져옴
    // 
    const _selGrp = getSelectedGroup({ itemOptions2, selectedGroup: selectedGroup2 })

    setGrpSTime(serviceTimeSet(_selGrp.serviceTime))

    // 
    // group을 가져온 후 selItems를 가져옴
    // 
    let selItems = getSelectedItems({ posName: shopInfo.posName, group: _selGrp, itemList, showRemoved })

    setSortedItems(selItems)
    setSelGroup(_selGrp)

    const groupName = getGroupName({ shopInfo, group: _selGrp, usePosMenuFirst, selectedLanguage })

    setGroupDispName(groupName);
  }, [itemOptions2, itemList, showRemoved]);


  /** *************************************************************************
   * @메뉴보기_순서편집_시작
   * **************************************************************************/
  const [alertMsg, setAlertMsg] = useState("");

  // 
  // draggable 아이템 대신에 메뉴 아이템 항목이 선택되어 의미가 없음.
  //
  const originalColor = colors.light;
  const draggedColor = colors.light + "80";
  const dropToColor = colors.danger + "80";

  const {
    dragStart,
    dragOver,
    dragEnter,
    dragLeave,
    drop,
    dragEnd,
    dragged,
    dropTo
  } = useDraggable({
    originalColor,
    draggedColor,
    dropToColor,
    onEnd: () => {
      let txt = `메뉴항목 보기 순서에 변경이 있습니다.`;
      setAlertMsg(txt);
    },
  });

  const updateSortOrder = async () => {
    try {
      setIsLoading(true);

      let newItems = [...sortedItems];
      let draggedItem = newItems[dragged];
      newItems.splice(dragged, 1);

      let dropPosition;
      if (dragged < dropTo) dropPosition = dropTo - 1;
      else dropPosition = dropTo;
      newItems.splice(dropPosition, 0, draggedItem);

      const newItems2 = newItems.map((item, i) => {
        return { ...item, sortOrder: i };
      });

      if (debug) {
        logMenuNames(sortedItems);
        logMenuNames(newItems2);
      }

      // 
      // sortOrder 삽입
      // 
      await Promise.all(newItems.map(async (item, i) => {
        let res = await updateItem({ shopInfo, id: item.id, body: { sortOrder: i } });
        if (res.err) throw new Error(str.alertFailUpdate + "\n" + res.err.message);
        return null;
      }));

      if (shopInfo.posName === "OKPOS") {
        // 
        // TuClsList > itemList 추가
        //
        const newList = newItems
        const itemCodeList = newList.map(item => item.itemCode)
        const newGroup = { ...selGroup, itemList: itemCodeList }

        if (debug) console.table([newGroup])
        if (debug) console.log(newGroup.itemList)

        const key = "TU_CLS_CD"
        const newGroupList = itemOptions2.TuClsList.map(cls => {
          if (cls[key] === newGroup[key]) return newGroup
          else return cls
        })

        let res = await updateItemOption({
          shopInfo,
          body: { TuClsList: JSON.stringify(newGroupList) }
        })
        if (res.err) throw Error(res.err.message)
      }

      await onload();
    } catch (e) {
      alert(e.message);
    } finally { setIsLoading(false); }
  }

  const myStyle = {
    container: { margin: "0px" },
    titleBar: { padding: "10px 0" },
    title: { ...gStyle.subtitle, ...gStyle.bold, cursor: "pointer" },
    innerContainer: {
      width: "100%",
      border: "1px solid " + colors.light,
      borderRadius: 10,
      boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.08)",
    },
    menuWrap: {
      padding: "15px",
      borderTop: "1px solid " + originalColor,
    },
    btn: { cursor: "pointer" }

  }

  return (
    <div style={myStyle.container}>
      <div style={myStyle.titleBar}>
        <p style={myStyle.title}>
          <ArrowL onClick={() => setMenuClicked(false)} />
          &nbsp;&nbsp;
          <span style={{ cursor: "auto" }}>{groupDispName}</span>
          &nbsp;&nbsp;
          <Pencil style={{ fontSize: "14px" }} onClick={() => setGroupAlias(true)} />
          &nbsp;&nbsp;
          <Clock
            style={{ fontSize: "14px", color: grpSTime ? colors.danger : null }}
            onClick={() => setVisibleServiceTime(true)}
          />
          &nbsp;&nbsp;
          <Sorting style={{ fontSize: "14px" }} onClick={() => setVisibleSorting(true)} />
        </p>
      </div>

      <div style={myStyle.innerContainer}>
        {
          sortedItems?.map((item, idx) => {
            return (
              <div
                style={myStyle.menuWrap}
                key={idx}
                className="draggable-item"
                draggable={true}
                onDragStart={(e) => dragStart(e, idx)}
                onDragOver={(e) => dragOver(e, idx)}
                onDragEnter={(e) => dragEnter(e, idx)}
                onDragLeave={(e) => dragLeave(e, idx)}
                onDrop={(e) => drop(e, idx)}
                onDragEnd={(e) => dragEnd(e, idx)}
              >
                <MenuItem {...props} item={item} setIsLoading={setIsLoading} />
              </div>
            )
          })
        }
      </div>

      {/* onload를 반드시 넘겨주어야 함. */}
      <ModifyGroupAliasModal
        {...props}
        visible={groupAlias}
        setIsVisible={setGroupAlias}
      />
      {
        visibleServiceTime &&
        <ModifyServiceTimeModal
          {...props}
          type="group"
          visible={visibleServiceTime}
          setIsVisible={setVisibleServiceTime}
        />
      }
      {
        (visibleSorting) &&
        <SortingModal
          {...props}
          type="item"
          visible={visibleSorting}
          setVisible={setVisibleSorting}
        />
      }
      {isLoading && <LoaderFullPage />}
      <Alert
        title={alertMsg}
        visible={alertMsg !== ""}
        onClickCancel={() => setAlertMsg("")}
        onClickOk={() => {
          setAlertMsg("");
          updateSortOrder();
        }}>
        <p>확인을 누르면 변경사항이 저장됩니다.</p>
      </Alert>

    </div>
  )
}

function logMenuNames(arr) {
  let newArr = arr.map(item => item.itemName);
  console.log('itemList', newArr);
}
