import { axiosCall } from "../../lib/ec2-api-lib";

/** @EASY_POS_Proxy */
export const easyposProxy = async ({ apiName, body }) => {
  // 
  // apiNames: 
  //  "tableGroup"          // 모든 테이블 정보
  //  "table":      `       // 특정 테이블 정보
  //
  let path = `/pos/easypos?apiName=${apiName}`;
  path += '&mode=debug';
  return await axiosCall({ method: "POST", path, body });
};

export const easyposCopyImage = async ({ body }) => {
  // body = {shopId, imageUrl}
  let path = `/pos/easypos/copy-image`;
  return await axiosCall({ method: "POST", path, body });
};
