import "react-app-polyfill/ie9";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import {store} from './redux/store';
import { Provider } from "react-redux";
// import Amplify from "aws-amplify"; // deprecated  at "^4.3.37", "^2.2.1" 으로 down grade?
import { Amplify } from 'aws-amplify'; // stable-5 5.3.19 stable-4 4.3.46
import { AWSIoTProvider } from "@aws-amplify/pubsub";

import { conf } from "./settings";
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import App from './App';
import * as serviceWorker from './serviceWorker';
// import reportWebVitals from './reportWebVitals';

// import '@fortawesome/fontawesome-free/css/all.min.css';

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: conf.cognito.REGION,
    userPoolId: conf.cognito.USER_POOL_ID,
    identityPoolId: conf.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: conf.cognito.APP_CLIENT_ID
  },
  Storage: {
    region: conf.s3.REGION,
    bucket: conf.s3.BUCKET,
    identityPoolId: conf.cognito.IDENTITY_POOL_ID
  },
  API: {
    endpoints: [
      {
        name: conf.apiGateway.USER.API_NAME,
        endpoint: conf.apiGateway.USER.URL,
        region: conf.apiGateway.REGION
      },
      {
        name: conf.apiGateway.SHOP.API_NAME,
        endpoint: conf.apiGateway.SHOP.URL,
        region: conf.apiGateway.REGION
      },
      {
        name: conf.apiGateway.TAG.API_NAME,
        endpoint: conf.apiGateway.TAG.URL,
        region: conf.apiGateway.REGION
      },
      {
        name: conf.apiGateway.SMS.API_NAME,
        endpoint: conf.apiGateway.SMS.URL,
        region: conf.apiGateway.REGION
      },
      {
        name: conf.apiGateway.POS.API_NAME,
        endpoint: conf.apiGateway.POS.URL,
        region: conf.apiGateway.REGION
      },
      // {
      //   // CODE API
      //   name: conf.apiGateway.CODE.API_NAME,
      //   endpoint: conf.apiGateway.CODE.URL,
      //   region: conf.apiGateway.REGION,
      // },
    ]
  }
})

Amplify.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: conf.iot.REGION,
    aws_pubsub_endpoint: conf.iot.ENDPOINT,
  })
);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.unregister();
