import React from "react";
import { ModalForm } from "../../../components";
import { useSelector } from "react-redux";
import { export2ExcelFile } from "../../../lib-pos/dummy-pos/menu-export";

export default function MenuSave2ExcelModal(props) {
  const {
    visible,
    setVisible,
  } = props;

  const { itemList3, itemOptions3 } = useSelector(state => state.items)
  const title = "메뉴를 엑셀파일로 저장합니다.";

  const onSubmit = async () => {
    try {
      export2ExcelFile({ itemList: itemList3, itemOptions: itemOptions3 })
    } catch (e) {
      alert(e.message)
    } finally {
      setVisible(false)
    }
  }

  return (
    <ModalForm
      visible={visible} setIsVisible={setVisible}
      title={title}
      onSubmit={onSubmit}
      backdrop={"static"}
    />
  )
}
