import { createSlice } from "@reduxjs/toolkit";

/**
 * @Using_Slices 
 *  참조: https://redux-toolkit.js.org/usage/usage-guide 
 *  > Exporting and Using Slices
 * 
 * @Defining_Async_Logic_in_Slices
 *  참조: https://redux-toolkit.js.org/usage/usage-guide 
 *  > 우선은 간단한 reducer만 채용하고 API call은 추후에 진행함.
 * 
 * @RTK_Query
 *  참조: https://redux-toolkit.js.org/tutorials/rtk-query
 *  > Thunk 혹은 rtk query 를 사용할 것인지.....
 *  > RtkQuery는 Fetch를 사용한다?????
 *  > 따라서 AWS amplify  는 사용할 수 없다??????
 */

const initialState = {
  selectedLanguage: "KR", // default language
  posName: "",
  shopInfo: {},
};

export const shop = createSlice({
  name: "shop",
  initialState,
  reducers: {
    setShopInfo: (state, action) => {
      state.shopInfo = action.payload.shopInfo;
      state.posName = action.payload.shopInfo.posName
    },
    resetShopInfo: (state) => {
      state.shopInfo = initialState.shopInfo;
      state.posName = initialState.posName;
    },

    setSelectedLang: (state, action) => {
      state.selectedLanguage = action.payload.selectedLanguage;
    },
    resetSeletectedLang: (state) => {
      state.selectedLanguage = initialState.selectedLanguage;
    },
  },
});

export const { 
  setShopInfo, resetShopInfo,
  setSelectedLang, resetSeletectedLang
} = shop.actions;

export default shop.reducer;
