import React, { useEffect, useState, useRef, useCallback } from "react";
import { ThreeDotsV } from "./Icons";
import { useStyles } from "./";

export function OnlDropdown(props) {
  const { title, children, id, width, size } = props;
  const { gStyle } = useStyles()
  const [visibleDD, setVisibleDD] = useState(false); // dropDown show
  const [listening, setListening] = useState(false)

  const ddRef = useRef(null)

  useEffect(() => {
    if (listening) return
    if (!ddRef.current) return
    setListening(true)
    document.addEventListener(`click`, handleClick);
    document.addEventListener(`touchstart`, handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('touchstart', handleClick);
    }
  }, [])

  const handleClick = (evt) => {
    const cur = ddRef?.current
    if (!cur) return
    const node = evt.target
    if (cur?.contains(node)) return
    setVisibleDD(false)
  }

  const toggle = () => {
    const dropdowns = document.getElementsByClassName("onl-dropdown");
    for (let i = 0; i < dropdowns.length; i++) {
      dropdowns[i].style.display = "none";
    }
    setVisibleDD(!visibleDD);
  }

  const styles = {
    onlDropdown: {
      padding: 0,
      position: "relative",
      // backgroundColor: 'yellow',
      // marginBottom: 15
    },
    btn: {
      display: "flex", alignItems: "center",
      ...gStyle.subtitle,
      cursor: "pointer",
      // fontWeight: "600",
      fontSize: size === "lg" ? gStyle.subtitle.fontSize : gStyle.text.fontSize
    },
    dropDownMenu: {
      display: visibleDD ? "block" : "none",
      width: width || "auto",
      padding: "8px 0", // item은 7px 로 합쳐서 15px
      border: "0.5px solid #dfdfdf",
      borderRadius: 7,
      position: "absolute",
      top: 35,
      right: 0,
      backgroundColor: "#fff",
      // backgroundColor: colors.light,
      zIndex: 999
    },
    dropDownItem: {
      ...gStyle.text,
      padding: "12px 12px 0",
      cursor: "pointer"
    }
  }

  return (
    <div style={styles.onlDropdown} ref={ddRef}>
      <p onClick={toggle} style={styles.btn}>
        {title} <ThreeDotsV />
      </p>
      <div style={styles.dropDownMenu} className="onl-dropdown">
        {
          React.Children.map(children, (child, i) => {
            if (React.isValidElement(child)) return React.cloneElement(child, { setVisibleDD })
            return child
          })
        }
      </div>
    </div>
  )
}

export function DropdownItem(props) {
  const { children, onClick, setVisibleDD } = props;
  const { gStyle } = useStyles()

  const styles = {
    dropDownItem: {
      ...gStyle.text,
      padding: "7px 15px",
      cursor: "pointer"
    }
  }

  const onClickItem = () => {
    setVisibleDD(false)
    onClick()
  }

  return <p style={styles.dropDownItem} onClick={onClickItem}>{children}</p>
}

export function Divider(props) {
  const styles = {
    divider: {
      margin: "7px 0",
      borderTop: "0.5px solid #dfdfdf",
      opacity: "1",
    }
  }
  return <hr style={styles.divider} />
}

