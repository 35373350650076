import React from "react";
import { Button, useStyles, Input, ModalForm } from "../../../components";
import { useInputFields } from "../../../lib/hooksLib";
import { resetOnlMenu } from "../../../lib-pos";
import { debug } from "../../../settings";
import { okposItemDelete, okposItemOptionDelete } from "../../../lib/ec2-api-lib";

const confirmWord = "9385";
const str = {
  comment: `"${confirmWord}"를 입력 후 확인을 누르세요.`,
  comment1: `메뉴명이 포스의 메뉴명으로 모두 변경됩니다.`,
  comment2: `메뉴 이미지도 모두 삭제되니 POS의 이미지를 업로드하려면 POS메뉴 불러오기를 수행하세요.`,
  comment3: `나머지는 모두 유지됩니다.`,
  noItemFound: "NO ITEM FOUND",
  invalidPos: "유효한 포스명이 아닙니다.",
  alertFail: "정보를 가져오는 데 실패했습니다!",
  alertFailUpdate: "정보를 수정하는 데 실패했습니다!",
}

/**
 * @info_POS_메뉴_불러오기
 */
export default function MenuResetModal(props) {
  const {
    visible,
    setVisible,
    isLoading,
    setIsLoading,
    shopInfo,
    itemOptions, itemOptions2,
    itemList,
    onload,
  } = props;

  const title = "메뉴데이터를 초기화 합니다.";
  const { gStyle } = useStyles();
  const [fields, setFields] = useInputFields({ word: debug ? confirmWord : "" });

  const onClick = async () => {
    try {
      setIsLoading(true);
      let resp = await resetOnlMenu({ shopInfo, itemList, itemOptions, itemOptions2 });
      if (resp.err) throw new Error(resp.err.message);
      await onload();
      alert("초기화에 성공 하였습니다!!");
    } catch (e) {
      alert(e.message);
    } finally {
      setIsLoading(false);
      // window.location.reload();
      setVisible(false);
    }
  }

  const onClickDel = async () => {
    try {
      if (shopInfo.posName !== "OKPOS") throw Error("NOT SUPPORTED!")
      setIsLoading(true);

      let res = await okposItemOptionDelete({shopId: shopInfo.shopId})
      if(res.err) throw Error(res.err.message)

      let errMsg = ""

      for (let i = 0; i < itemList.length; i++) {
        setFields({ 
          target: { 
            name: "word", 
            value: `Deleting ${itemList[i].itemCode} ${itemList[i].itemName}...` 
          } 
        })

        res = await okposItemDelete({ shopId: shopInfo.shopId, id: itemList[i].id })
        if (res.err) {
          errMsg = res.err.message
          break;
        }
      }

      if (errMsg !== "") throw Error(errMsg)

      window.location.reload();
    } catch (e) {
      setIsLoading(false);
      alert(e.message);
    }
  }


  const styles = {
    titleWrap: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: "10px",
      marginBottom: "15px"
    },
    title: { ...gStyle.subtitle, ...gStyle.bold, textAlign: "center" },
    text: { ...gStyle.text, marginBottom: "5px" }
  }

  return (
    <ModalForm visible={visible} setIsVisible={setVisible} title={title}>
      <div>
        <p style={styles.text}>{str.comment}</p>
        <p style={styles.text}>{str.comment1}</p>
        <p style={styles.text}>{str.comment2}</p>
        <p style={styles.text}>{str.comment3}</p>
        <br />

        <Input name="word" value={fields.word} onChange={setFields} />

        <Button
          disabled={confirmWord !== fields.word}
          variant="primary"
          width="100%"
          title='확인'
          onClick={onClick}
          isLoading={isLoading}
        />

        {/* {(debug && shopInfo.posName === "OKPOS") &&
          <><br />
            <Button
              disabled={confirmWord !== fields.word}
              variant="secondary"
              title='삭제'
              onClick={onClickDel}
              isLoading={isLoading}
            />
          </>
        } */}
      </div>
    </ModalForm>
  )
}
