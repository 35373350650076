import React, { useEffect, useState } from "react";
import { CardContainer, FormInput, Button } from "../../../../components";
import { useInputFields } from "../../../../lib/hooksLib";
import {
  getPaymentKeys,
  savePaymentKeys,
  getPaymentKeysKCP, savePaymentKeysKCP
} from "../../../../lib/ec2-api-lib";
import { isJson } from "../../../../lib/utils";
import { debug } from "../../../../settings";

const str = {
  keyExistComment: "보안정보가 이미 존재합니다.",
  failure: "저장에 실패했습니다.",
  success: "성공적으로 저장하였습니다."
}

/** @deprecated */
export function KcpKeys(props) {
  // const { shopInfo } = useSelector(state => state.shop);
  const { shopInfo } = props

  const [isLoading, setIsLoading] = useState(false);

  const [keysExist, setKeysExist] = useState(false)
  const [keyFields, setKeyFields] = useInputFields({
    siteCd: "",
    siteKey: "",
    cert: "",
    privkey: "",
    privkeyPw: "",
  })

  useEffect(() => {
    async function start() {
      const res = await getPaymentKeysKCP({ shopId: shopInfo.shopId })
      if (res.err) return null
      if (res.result?.Item?.kcpCert) {
        if (debug) console.log('keys', res.result.Item)
        setKeysExist(true)
      }
    }
    start()
  }, [])

  const onChangeInputFile = (e, type) => {
    const reader = new FileReader()
    reader.onload = () => {
      if (debug) console.log(reader.result)
      setKeyFields({ target: { name: type, value: reader.result } })
    }
    reader.readAsText(e.target.files[0])
  }

  const submitKeyInfo = async () => {
    try {
      setIsLoading(true)

      if (keyFields.siteCd === "") throw Error("NO SITE CODE")
      if (keyFields.siteKey === "") throw Error("NO SITE KEY")
      if (keyFields.cert === "") throw Error("NO CERT DATA")
      if (keyFields.privkey === "") throw Error("NO PRIVKEY DATA")
      if (keyFields.privkeyPw === "") throw Error("NO PRIVKEY PW")

      const body = { shopId: shopInfo.shopId, ...keyFields }
      const res = await savePaymentKeysKCP({ body })

      if (res.err) throw Error(res.err.message)
      if (debug) console.log('res', res.result)

      alert("결제 보안데이터 저장 성공")
    } catch (e) {
      alert(e.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <CardContainer
      title="결제 보안정보 등록"
      onClickBack={() => props.history.push("/")}
      variant="md"
    // width={isMobileDevice ? "100%" : 474}
    >
      {keysExist && <p style={{ color: "red" }}>KCP 보안정보가 이미 존재합니다. 수정하려면 다시 업로드 하세요!</p>}
      <FormInput
        label="KCP 인증서 파일 업로드"
        name="certFile"
        onChangeInputFile={(e) => onChangeInputFile(e, "cert")}
        title="인증서 파일"
        accept='.pem'
        inputType="file"
      />

      <FormInput
        label="KCP 개인키 파일 업로드"
        name="privKeyFile"
        onChangeInputFile={(e) => onChangeInputFile(e, "privkey")}
        title="개인키 파일"
        accept='.pem'
        inputType="file"
      />

      <FormInput
        label="KCP 개인키 비밀번호"
        name="privkeyPw"
        value={keyFields.privkeyPw}
        onChange={setKeyFields}
      />

      <FormInput
        label="KCP 사이트 코드"
        name="siteCd"
        value={keyFields.siteCd}
        onChange={setKeyFields}
      />

      <FormInput
        label="KCP 사이트 키"
        name="siteKey"
        value={keyFields.siteKey}
        onChange={setKeyFields}
      />

      <br />
      <Button
        size="sm"
        width="100%"
        variant="primary"
        title={keysExist ? "재 업로드" : "업로드"}
        isLoading={isLoading}
        onClick={submitKeyInfo}
      />

    </CardContainer>
  );
}

export function KisPg(props) {
  // const { shopInfo } = useSelector(state => state.shop);
  const { shopInfo } = props
  const pgName = "KISPG"

  const testShop = {
    mid: "kistest00m",
    mkey: "2d6ECGhR1pg/1QGE1lcRI4awsWEgshjEyI8UgYslLPJSuNeyPTkdrT8XWARezvDTUJClWQWhjxzBbu7AsuLZqg=="
  }

  const testShop_prod = {
    "mid":"kistest00m",
    "mkey":"keBkUR8km5JCTnuUIIjL0LGXES/YxIzhyfg14ZOYcmWiIfx3XUpdBNy/1OaCG+MzahKiDbvX3ThjSszxZiRniA=="
  }

  const [isLoading, setIsLoading] = useState(false);

  const [keysExist, setKeysExist] = useState(false)
  const [keyFields, setKeyFields] = useInputFields({ mid: "", mkey: "" })
  // const [keyFields, setKeyFields] = useInputFields(testShop)

  useEffect(() => {
    async function start() {
      const res = await getPaymentKeys({ shopId: shopInfo.shopId })
      if (res.err) return null
      if (res.result?.Item[pgName]) {
        const keys = isJson(res.result?.Item[pgName]) ? JSON.parse(res.result?.Item[pgName]) : {}
        // setKeyFields({ target: { name: "mid", value: keys.mid } })
        // setKeyFields({ target: { name: "mkey", value: keys.mkey } })
        setKeysExist(true)
      }
    }
    start()
  }, [])

  const submitKeyInfo = async () => {
    try {
      setIsLoading(true)

      if(keyFields.mid==="" || keyFields.mkey==="") throw Error("EMPTY Keys")

      const body = { [pgName]: JSON.stringify(keyFields) }
      const res = await savePaymentKeys({ shopId: shopInfo.shopId, body })
      if (res.err) throw Error(res.err.message)
      if (debug) console.log('res', res.result)

      alert(str.success)
    } catch (e) {
      alert(str.failure + " - " + e.message)
    } finally {
      setIsLoading(false)
      props.history.push("/")
    }
  }

  return (
    <CardContainer
      title="KISPG 보안정보 등록"
      onClickBack={() => props.history.push("/")}
      variant="md"
    >
      {keysExist && <p style={{ color: "red" }}>{str.keyExistComment}</p>}

      <FormInput
        label="KISPG Merchant ID"
        name="mid"
        value={keyFields.mid}
        onChange={setKeyFields}
      />

      <FormInput
        label="KISPG Merchant Key"
        name="mkey"
        value={keyFields.mkey}
        onChange={setKeyFields}
      />

      <br />
      <Button
        size="sm"
        width="100%"
        variant="primary"
        title={keysExist ? "재 업로드" : "업로드"}
        isLoading={isLoading}
        onClick={submitKeyInfo}
      />

    </CardContainer>
  );
}
