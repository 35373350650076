import React, { useState } from "react";
import { userUpdate } from "../../lib/ec2-api-lib";
import { CardContainer } from "../../components/CardContainer";
import { useInputFields } from "../../lib/hooksLib";
import { ChevronL } from '../../components/Icons';
import { FormInput, Page, Button, Alert, useStyles } from "../../components";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from '../../redux/user';
import { debug } from '../../settings';

const str = {
  noField: "데이터가 비어 있습니다.",
  noChangedData: "변경된 데이터가 없습니다.",
  sucess: "성공적으로 변경되었습니다.",
  fail: "변경에 실패하였습니다."
}

export default function EditNickname(props) {

  const dispatch = useDispatch();
  const { user, isSigned } = useSelector(state => state.user);
  // if (debug) console.log('user', isSigned, user);
  const { gStyle } = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [fields, setFields] = useInputFields({
    nickName: user.nickName
  })

  const onsubmit = async () => {
    try {
      if (fields.nickName === "") throw Error(str.noField);
      if (fields.nickName === user.nickName) throw Error(str.noChangedData);

      let res = await userUpdate({
        username: user.username,
        body: { nickName: fields.nickName }
      });
      if (res.err) throw Error(str.fail);
      setIsLoading(false);

      dispatch(setUser({ user: { ...user, nickName: fields.nickName } }));
      // props.history.push('/store');
      props.history.push('/');

    } catch (e) {
      setAlertMsg(e.message);
      setIsLoading(false);
    }
  }

  const styles = {
    titleWrap: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: "10px",
      marginBottom: "15px"
    },
    title: { ...gStyle.title, ...gStyle.bold, textAlign: "center" }
  }

  return (
    <Page>
      <CardContainer variant="sm" padding="40px 24px">

        <div style={styles.titleWrap}>
          <ChevronL style={gStyle.subtitle} onClick={() => props.history.goBack()} />
          <h3 style={styles.title}>사용자 별명 변경</h3>
          <div style={{ width: "20px" }} />
        </div>

        <FormInput
          size="sm"
          name="nickName"
          label="사용자 별명"
          value={fields.nickName}
          onChange={setFields}
        />
        <Button
          title="확인" width="100%" variant="primary" size="sm"
          isLoading={isLoading}
          onClick={onsubmit}
        />
      </CardContainer>
      <Alert title={alertMsg} visible={alertMsg !== ""} onClickOk={() => setAlertMsg("")} />
    </Page>
  );
}
