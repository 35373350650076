import React from "react";
import { Page } from "../../../../components";
import { useSelector } from "react-redux";
import { NoticeOptions } from "./NoticeOptions";
import { OrderNotifyOptions } from "./OrderNotifyOpitons";
import { UserAppUiOptions } from "./UserAppUiOpitons";

export default function OthersSetting(props) {
  const { shopInfo } = useSelector(state => state.shop);

  return (
    <Page>
      <OrderNotifyOptions {...props} shopInfo={shopInfo} />

      <NoticeOptions {...props} shopInfo={shopInfo} />

      <UserAppUiOptions  {...props} shopInfo={shopInfo} />
    </Page>
  );
}