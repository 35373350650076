import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import { FormInput } from "./FormInputs";
import { Button } from "./Buttons";
import { useStyles } from "./ui";
import { useSelector } from "react-redux";
import { kiloComma } from "../lib/utils";
import {
  // ArrowU, ArrowD,
  // ChevronD, ChevronU,
  TriAngleD, TriAngleU
} from "./Icons";

/**
 * @deprecated 
 */
export function CustomTable(props) {
  const {
    /**
     * header type = 
     *  textArray 
     *  number
     *  checkbox
     *  input
     *  html
     *  sorting
     */
    tableHeader, 
    rowItems,
    onClickItem,
    onChangeChecked,  // type === checkbox
    onChangeItem,     // type === input
    sort,             // type === sorting
    variant
  } = props;

  const { isMobileDevice } = useSelector((state) => state.platform);
  const { gStyle, colors } = useStyles()

  const headerStyle = { ...gStyle.text, color: colors.light }
  const tdStyle = { ...gStyle.text }

  if (isMobileDevice) {
    headerStyle.fontSize = "0.75rem"
    tdStyle.fontSize = "0.75rem"
  }

  const styles = {
    wrap: {
      width: "100%",
      overflow: "auto",
      borderRadius: 5
    },
    head: { color: '#fff', backgroundColor: colors.danger },
    tr: { cursor: onClickItem ? "pointer" : "auto", },
    td: { paddingLeft: 15 },
    headerTxt: { ...headerStyle, padding: "2px 15px" },
    headerNum: { ...headerStyle, padding: "2px 15px 2px", textAlign: 'right' },
    text: { ...tdStyle, padding: "2px 15px" },
    num: { ...tdStyle, padding: "2px 15px 2px", textAlign: 'right' },
  }

  return (
    <div style={styles.wrap}>
      <Table striped>
        <thead style={styles.head}>
          <tr>
            {
              tableHeader.map((item) => {
                return (
                  <th
                    key={item.name}
                    style={item.type === 'number' ? styles.headerNum : styles.headerTxt}
                  >
                    {item.displayName}
                  </th>
                )
              })
            }
          </tr>
        </thead>
        <tbody>
          {
            rowItems.map((obj, i) => {
              return (
                <tr key={"key" + i}
                  onClick={() => {
                    if (onClickItem) onClickItem(obj)
                  }}
                  style={styles.tr}
                >
                  {
                    tableHeader.map((header, j) => {
                      let value = (header.name === "lastModified" || header.name === "created")
                        ? new Date(obj[header.name]).toLocaleDateString()
                        : (header.name === "time")
                          ? new Date(obj[header.name]).toLocaleString()
                          : obj[header.name]

                      if (header.type === "textArray") {
                        value = obj[header.name].join(", ")
                      }

                      if (header.type === 'number') {
                        value = value !== undefined ? kiloComma(parseInt(value)) : "-"
                      }

                      if ((typeof value === "boolean") || header.type === "boolean") {
                        value = value ? "Yes" : "-";
                      }

                      if (header.type === "checkbox") {
                        return (
                          <td key={header.name + j} style={styles.td}>
                            <FormInput
                              style={{ margin: "0px" }}
                              checkboxStyle={{ checkbox: { fontSize: "0.8rem" } }}
                              name="checked"
                              checked={obj[header.name]}
                              onChange={(e) => onChangeChecked(obj)}
                              inputType="checkbox"
                            />
                          </td>
                        )
                      }
                      else if (header.type === "input") {
                        return (
                          <td key={header.name + j} style={{ ...styles.td, padding: 0 }}>
                            <CellInput
                              placeholder=""
                              name={header.name}
                              value={obj[header.name]}
                              onChange={(e) => onChangeItem(header.name, e.target.value, obj)}
                            />
                          </td>
                        )
                      }
                      else if (header.type === "html") {
                        return (
                          <td key={header.name + j} style={styles.text}>
                            <div dangerouslySetInnerHTML={createMarkup(value)} />
                          </td>
                        )
                      } else if (header.type === "sorting") {
                        return (
                          <td key={header.name + j} style={styles.text} >
                            <TriAngleU
                              style={{ fontSize: "1rem" }}
                              onClick={() => sort(obj, "up")} />
                            <TriAngleD
                              style={{
                                fontSize: "1rem",
                                // marginLeft: 2 
                              }}
                              onClick={() => sort(obj, "down")} />
                          </td>
                        )
                      } else {
                        if (typeof value === "string" && value.length > 50) {
                          value = value.substring(0, 50) + "...";
                        }
                        return (
                          <td
                            key={header.name + j}
                            style={header.type === "number" ? styles.num : styles.text}>{value}
                          </td>
                        )
                      }
                    })
                  }
                </tr>
              )
            })
          }
        </tbody>
      </Table>
      {rowItems.length === 0 && <NoItems />}
    </div>
  )
}

function createMarkup(html) {
  return { __html: html };
}

function CellInput(props) {
  const { isMobileDevice } = useSelector((state) => state.platform);
  const { gStyle, colors } = useStyles()

  return (
    <input
      style={{
        ...gStyle.text,
        border: "none",
        padding: "0px 15px",
        margin: 0,
        backgroundColor: "transparent",
        color: colors.danger,
        fontSize: isMobileDevice ? "0.75rem" : "1.0rem"
      }}
      placeholder={props.placeholder}
      name={props.name}
      onChange={props.onChange}
      value={props.value}
    />
  )
}

function NoItems() {
  const { gStyle } = useStyles()
  const styles = {
    noItems: { ...gStyle.subtitle, padding: "2px 15px", fontWeight: "500", textAlign: "center" },
  }
  return <p style={styles.noItems}>No Items</p>  
}
