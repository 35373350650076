import { sortObjArray2 } from "../lib/utils"

export function getGroupList({ itemOptions2 }) {
  const optionName = itemOptions2.optionName
  let groupList = []
  if (optionName === "OKPOS") {
    groupList = itemOptions2.TuClsList
  } else if (optionName === "unionpos") {
    groupList = itemOptions2.GrpList
  } else if (
    optionName === "DUMMY_POS" || 
    optionName === "OASIS_POS" ||
    optionName === "EASY_POS"
  ) {
    groupList = itemOptions2.groupList
  } else return []

  return sortObjArray2({ arr: groupList, key: "sortOrder" })
}

export function getSelectedGroup({ itemOptions2, selectedGroup }) {
  const optionName = itemOptions2.optionName
  let groupList = getGroupList({ itemOptions2 })
  if (
    optionName === "DUMMY_POS" || 
    optionName === "OASIS_POS" ||
    optionName === "EASY_POS"
  ) {
    return groupList.filter(grp => grp.groupCode === selectedGroup.groupCode)[0]
  }
  return groupList.filter(grp => grp.GrpCode === selectedGroup.GrpCode)[0]
}
