import React, { useState } from "react";
import { Button, useStyles, ModalView, FormInput, ModalForm } from "../../../components";
// import { useInputFields } from "../../../lib/hooksLib";
import { Close, Plus, Minus } from '../../../components/Icons';
// import { isJson, log } from "../../../lib/utils";
import { debug } from "../../../settings";
// import { shopItemOptionUpdate } from "../../../lib/ec2-api-lib";
import { updateItemOption } from "../../../lib-pos";

// const str = {
//   noItemFound: "NO ITEM FOUND",
//   alertFail: "정보를 가져오는 데 실패했습니다!",
//   alertFailUpdate: "정보를 수정하는 데 실패했습니다!",
// }

/**
 * @info_POS_메뉴_불러오기
 */
export default function SettingTableCallModal(props) {
  const {
    visible, setVisible,
    shopInfo, itemOptions, itemOptions2,
    onload,
  } = props;
  const { gStyle, colors } = useStyles();
  
  const modalTitle = "테이블콜 텍스트 편집";

  // const tableCallList = isJson(itemOptions.tableCallList)
  // ? JSON.parse(itemOptions.tableCallList) : [];
  
  const tableCallList = itemOptions2.tableCallList || [];
  // if(debug) console.log('tableCallList', tableCallList);

  const [isLoading, setIsLoading] = useState(false);

  const [callList, setCallList] = useState(tableCallList);

  const onClick = async () => {
    try {
      if (debug) console.log(callList);
      if(JSON.stringify(tableCallList) === JSON.stringify(callList) ) throw Error("변경 사항이 없습니다.");

      setIsLoading(true);

      let res = await updateItemOption({ shopInfo, body: { tableCallList: JSON.stringify(callList) } });
      if (res.err) throw new Error(res.err.message);
      await onload();
      alert("저장에 성공 하였습니다!!");
    } catch (e) {
      alert(e.message);
    } finally {
      setIsLoading(false);
      setVisible(false);
    }
  }

  const onChangeText = (e, i) => {
    setCallList(pre => {
      const newList = [...pre];
      newList[i] = e.target.value;
      return newList;
    });
  }

  const minus = (i) => {
    setCallList(pre => {
      const newList = [...pre];
      newList.splice(i, 1);
      return newList;
    });
  }

  const plus = () => {
    setCallList(pre => {
      const newList = [...pre];
      newList.push("");
      return newList;
    });
  }

  const styles = {
    titleWrap: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: "10px",
      marginBottom: "15px"
    },
    title: { ...gStyle.subtitle, ...gStyle.bold, textAlign: "center" },
    // text: { ...gStyle.text, textAlign: "center", marginBottom: "15px" }
    iconPlus: { ...gStyle.subtitle, color: colors.primary },
    iconMinus: { ...gStyle.subtitle, color: colors.secondary },
    formLine: { display: 'flex', justifyContent: "space-between" }
  }

  return (
    <ModalForm visible={visible} setIsVisible={setVisible} title={modalTitle}>
      <div>
        {/* <div style={styles.titleWrap}>
          <div style={{ width: "20px" }} />
          <p style={styles.title}>{modalTitle}</p>
          <Close style={{ cursor: 'pointer' }} width={20} onClick={() => setVisible(false)} />
        </div> */}
        {/* <hr /> */}

        <div style={{ margin: "10px 10%" }}>
          <Plus onClick={() => plus()} style={styles.iconPlus} />
        </div>
        <hr />
        <div style={{ margin: "5px 10%" }}>
          {
            callList.map((call, i) => {
              return (
                <div key={"formline" + i} style={styles.formLine}>
                  <div style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
                    <Minus onClick={() => minus(i)} style={styles.iconMinus} />
                  </div>
                  <FormInput
                    name={"call" + i}
                    onChange={(e) => onChangeText(e, i)}
                    value={call}
                    placeholder="텍스트를 입력하세요!"
                  />
                </div>
              )
            })
          }
        </div>

        <Button
          variant="primary"
          width="100%"
          title='확인'
          onClick={onClick}
          isLoading={isLoading}
        />
      </div>
    </ModalForm>
  )
}
