import React, { useEffect, useState } from "react";
import { updateItem, updateItemOption, getGroupList, getGroupUpdateBody, getSelectedGroup } from "../../../lib-pos";
import { isJson } from "../../../lib/utils";
import { ModalForm, FormBox, FormInput, Button, useStyles } from "../../../components";
import { debug } from "../../../settings";
import { useInputFields } from "../../../lib/hooksLib";
import { getGroupName } from "../../../lib-pos";

export default function ModifyServiceTimeModal(props) {
  const {
    shopInfo,
    type,
    selectedGroup2, item,
    itemOptions2,
    visible,
    setIsVisible,
    onload,
  } = props;

  const title = type === "group"
    ? `"${selectedGroup2.name || selectedGroup2.groupName || selectedGroup2.GrpName}" 요일/시간 세팅`
    : `"${item.itemName}" 요일/시간 세팅`;

  const { gStyle } = useStyles();

  const days = [
    { name: "MON", displayName: "월" },
    { name: "TUE", displayName: "화" },
    { name: "WED", displayName: "수" },
    { name: "THU", displayName: "목" },
    { name: "FRI", displayName: "금" },
    { name: "SAT", displayName: "토" },
    { name: "SUN", displayName: "일" },
  ];

  const allDay = "All Day"; // NOT used
  const hrs2 = Array.from({ length: 24 }, (v, i) => i.toString());
  if (debug) console.log('hrs2', hrs2)

  let serviceTime = {};
  if (type === "group") {
    const selGrp = getSelectedGroup({itemOptions2, selectedGroup: selectedGroup2})
    serviceTime = selGrp.serviceTime || serviceTime
  } else {
    if (isJson(item.serviceTime)) serviceTime = JSON.parse(item.serviceTime);
  }

  if (debug) console.log("serviceTime", serviceTime);

  // 
  // 기존 서비스 시간
  // deprecated
  // 
  // const oldTimeFields = {
  //   start: serviceTime.start || hrs[0],
  //   end: serviceTime.end || hrs[0]
  // };
  // if (debug) console.log("oldTimeFields", oldTimeFields);

  let startOld = getTime24String(serviceTime.start);
  let endOld = getTime24String(serviceTime.end);
  if (debug) console.log("startOld=" + startOld, "endOld=" + endOld);

  // 
  // 신규 서비스 시간
  // 
  const startT = serviceTime.startTime || startOld || hrs2[0];
  const endT = serviceTime.endTime || endOld || hrs2[0];
  const defaultTimeFields = { startTime: startT + "시", endTime: endT + "시" };
  if (debug) console.log("defaultTimeFields", defaultTimeFields);

  // 
  // 요일 파싱
  // 
  const defaultDaysFields = {};
  days.map(day => {
    if (serviceTime.days === undefined) {
      defaultDaysFields[day.name] = true;
    } else {
      defaultDaysFields[day.name] = serviceTime.days.includes(day.name);
    }
  })
  if (debug) console.log("defaultDaysFields", defaultDaysFields);

  const [timeFields, setTimeFields] = useInputFields(defaultTimeFields)
  const [daysFields, setDaysFields] = useInputFields(defaultDaysFields);
  const [isLoading, setIsLoading] = useState(false);

  // const onSubmitForItem = async () => {
  //   try {
  //     // setIsLoading(true);

  //     // // 
  //     // // 예시 days = ["MON", "FRI"] 
  //     // // 
  //     // const dayList = []
  //     // Object.keys(daysFields).map(day => {
  //     //   if (daysFields[day]) dayList.push(day);
  //     //   return null;
  //     // });

  //     // const dayTime = {
  //     //   startTime: parseInt(timeFields.startTime).toString(),
  //     //   endTime: parseInt(timeFields.endTime).toString(),
  //     //   days: dayList
  //     // }

  //     // // 
  //     // // 기존 start end 를 포함함. 사용자 앱 수정후 삭제
  //     // // 
  //     // // if (serviceTime.start) {
  //     // dayTime.start = getTime12String(dayTime.startTime);
  //     // dayTime.end = getTime12String(dayTime.endTime);
  //     // // }
  //     // if (debug) console.log('service Days and Time', dayTime);

  //     // const body = { serviceTime: JSON.stringify(dayTime) }

  //     // // let res = await shopItemUpdate({ shopId: item.shopId, id: item.id, body });
  //     // // let res = {}
  //     // // const params = { shopId: item.shopId, id: item.id, body }
  //     // // if (shopInfo.posName === "OKPOS") res =  await okposItemUpdate(params);
  //     // // else  res =  await shopItemUpdate(params);

  //     // let res = await updateItem({ shopInfo, id: item.id, body })
  //     // if (res.err) throw new Error("저장에 실패 하였습니다.!\n" + res.err.message);
  //     // alert("성공적으로 변경되었습니다.");
  //     // onload();
  //   } catch (e) {
  //     alert(e.message);
  //   } finally {
  //     setIsLoading(false);
  //     setIsVisible(false);
  //   }
  // };

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      // 
      // 예시 days = ["MON", "FRI"] 
      // 
      const dayList = []
      Object.keys(daysFields).map(day => {
        if (daysFields[day]) dayList.push(day);
        return null;
      });

      const dayTime = {
        startTime: parseInt(timeFields.startTime).toString(),
        endTime: parseInt(timeFields.endTime).toString(),
        days: dayList
      }

      // 
      // 기존 start end 를 포함함. 사용자 앱 수정후 삭제
      // 
      // if (serviceTime.start) {
      dayTime.start = getTime12String(dayTime.startTime);
      dayTime.end = getTime12String(dayTime.endTime);
      // }
      if (debug) {
        console.log('selectedGroup2', selectedGroup2);
        console.log('service Days and Time', dayTime);
      }

      let res = {};
      if (type === "group") {
        const groupList = getGroupList({ itemOptions2 })
        const newGroupList = groupList.map(grp => {
          if (grp.GrpCode === selectedGroup2.GrpCode) {
            return {
              ...grp,
              serviceTime: dayTime
            }
          } else return grp
        })
        const updateBody = getGroupUpdateBody({ itemOptions2, groupList: newGroupList })
        res = await updateItemOption({ shopInfo, body: updateBody })
      } else {
        res = await updateItem({ shopInfo, id: item.id, body: { serviceTime: JSON.stringify(dayTime) } })
      }
      if (res.err) throw new Error("저장에 실패 하였습니다.!\n" + res.err.message);
      alert("성공적으로 변경되었습니다.");
      onload();
    } catch (e) {
      alert(e.message);
    } finally {
      setIsLoading(false);
      setIsVisible(false);
    }
  };

  const _getHrDisplayStr = (hr) => {
    if (hr === allDay) return hr;
    return hr + "시"
  }

  const styles = {
    textBold: { ...gStyle.text, fontWeight: gStyle.bold },
    formGroup: { marginLeft: "auto", marginRight: "auto" },
    formLine: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    dayFormBox: { width: "40%", margin: "5px 15px", }
  };

  return (
    <ModalForm
      visible={visible}
      setIsVisible={setIsVisible}
      title={title}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      <div style={{ margin: "15px 0" }}>
        <FormBox>
          {/* <div style={styles.formGroup}> */}
          <p style={styles.textBold}>기본 서비스 시간: 오전 12시 ~ 오후 12시</p>
          <p style={styles.textBold}>기본 서비스 요일: 주간 내내</p>
          <br />
          <div style={styles.formLine}>
            <FormInput style={{ marginBottom: 0 }}
              name="startTime"
              value={timeFields.startTime}
              onChange={setTimeFields}
              inputType="select"
              selectList={hrs2.map(hr => _getHrDisplayStr(hr))}
            />

            <p style={gStyle.title}>~</p>

            <FormInput style={{ marginBottom: 0 }}
              name="endTime"
              value={timeFields.endTime}
              onChange={setTimeFields}
              inputType="select"
              selectList={hrs2.map(hr => _getHrDisplayStr(hr))}
            />

          </div>
          {/* </div> */}
        </FormBox>
        <br />

        <FormBox>
          <div style={{ ...styles.formLine, flexWrap: "wrap", marginBottom: 15 }}>
            {
              days.map((day, i) => {
                return (
                  <div key={i} style={styles.dayFormBox}>
                    <FormInput
                      style={{ marginBottom: 0 }}
                      label={day.displayName}
                      name={day.name}
                      checked={daysFields[day.name]}
                      onChange={setDaysFields}
                      inputType="checkbox"
                    />
                  </div>
                )
              })
            }
          </div>
        </FormBox>
      </div>

      {/* <Button title="확인" width="100%" variant="primary" onClick={onSubmit} isLoading={isLoading} size="sm" /> */}
    </ModalForm >
  )
}

//
// start  end -> startTime endTime
//
function getTime24String(timeStr) {
  if (timeStr === undefined) return undefined;

  const arr = timeStr.split(" ");
  let t = parseInt(arr[1]);
  if (arr[0] === "오전") {
    if (t === 12) return "0"
    return t.toString();
  }
  else return (t + 12).toString();
}

//
// startTime  endTime -> start end
//
function getTime12String(timeStr) {
  let t = parseInt(timeStr);
  let time12;
  if (t < 12) time12 = (t === 0) ? "오전 12" : "오전 " + t
  else time12 = "오후 " + (t - 12)
  return time12 + "시";
}