import React, { useState } from "react";
import { CardContainer } from "../../components/CardContainer";
import { useInputFields } from "../../lib/hooksLib";
import {
  FormInput,
  Page,
  Button,
  Alert,
  useStyles
} from "../../components";
import { validatePhoneNo, } from "../../components/PostFormSection";
import { cognitoListUsers } from "../../lib/ec2-api-lib";
import { debug } from '../../settings';

const str = {
  failedApi: "정보를 가져오지 못했습니다.",
  noUsers: "등록된 아이디가 없습니다."
}

export default function FindUsername(props) {

  return (
    <Page>
      <CardContainer variant="sm" padding="40px 24px">
        <Forms {...props} />
      </CardContainer>
    </Page>
  );
}

function Forms(props) {
  const { gStyle } = useStyles();

  const [fields, setFields] = useInputFields(
    {
      phone_number: debug ? "01053174673" : ""
    }
  );

  const [isLoading, setIsLoading] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');

  const validate = () => {
    return validatePhoneNo(fields.phone_number);
  };

  const onsubmit = async () => {
    try{
      setIsLoading(true);

      let res = await cognitoListUsers({phone_number: fields.phone_number});
      if(res.err) throw new Error( str.failedApi + " " + res.err.message);

      // console.log('res', res);
      if(res.result.Users.length === 0) throw new Error(str.noUsers);
      setAlertMsg("사용자 아이디입니다. " + res.result.Users.join());

    } catch(e) {
      setAlertMsg(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  const ok = () => {
    setAlertMsg("");
    props.history.goBack();
  }

  const styles = {
    btnWrap: { display: "flex", justifyContent: "space-between" },
    title: { ...gStyle.title, ...gStyle.bold, textAlign: "center", marginBottom: "20px" }
  }

  return (
    <div>
      <h2 style={styles.title}>유저아이디 찾기</h2>

      <FormInput
        name="phone_number"
        label="핸드폰 번호"
        value={fields.phone_number}
        onChange={setFields}
      />

      <div style={styles.btnWrap}>
        <Button
          width="45%"
          size="sm"
          variant="outline-primary"
          onClick={() => props.history.goBack()}
        >취소</Button>

        <Button
          width="45%"
          variant="primary"
          size="sm"
          disabled={!validate()}
          isLoading={isLoading}
          onClick={onsubmit}
        >확인</Button>
      </div>

      <Alert title={alertMsg} visible={alertMsg !== ""} onClickOk={ok} />
    </div>
  );
}

