import {
  shopItemCreate,
  shopItemUpdate,
  shopItemDelete,
  shopItemOptionGet,
  shopItemOptionCreate,
  shopItemOptionUpdate,
} from "../../lib/ec2-api-lib";
import { json2obj } from "../../lib/utils";
import { items, groups, optionGroups, optionClasses, optionItems, tables } from "./dpos-menu-db";

import { debug } from "../../settings";

const Texts = {
  noItemFound: "NO ITEM FOUND",
  alertFail: "정보를 가져오는 데 실패했습니다!",
  alertFailUpdate: "정보를 수정하는 데 실패했습니다!",
}

// 
// menus는 DUMMY_POS용임
// 
export async function dummyposUpdateMenu({ shopInfo, itemList, menus }) {
  try {
    // 
    // shpItemOption 테이블에 저장할 key { shopId, optionName=unionpos }
    // 
    const optionName = "DUMMY_POS";

    let preOptions = {};
    let isNew = true;

    let res = await shopItemOptionGet({
      shopId: shopInfo.shopId,
      optionName,
    });

    // 
    // 신규이면 preOptions.isNew == true
    // 
    if (res.err) {
      if (res.err.message !== Texts.noItemFound) throw new Error(Texts.alertFail + " at itemOptionGet dummy_pos");
    } else {
      preOptions = res.result?.Item;
      isNew = false;
    }

    let resp = await updateGroupList({ shopInfo, preOptions, isNew, optionName, menus });
    if (resp.err) throw Error(resp.err.message);

    if (debug) console.log('resp newGrpList', resp.groupList);

    resp = await updateOptions({ shopInfo, preOptions, isNew, optionName, menus });
    if (resp.err) throw Error(resp.err.message);

    if (debug) console.log('resp optionGroupList', resp.optionGroupList);
    if (debug) console.log('resp optionClassList', resp.optionClassList);
    if (debug) console.log('resp optionItemList', resp.optionItemList);

    resp = await updateTableList({ shopInfo, preOptions, isNew, optionName, menus });
    if (resp.err) throw Error(resp.err.message);

    if (debug) console.log('resp tableList', resp.tableList);

    resp = await cleanUpItemList({ shopInfo, posItems: menus.itemList, itemList });
    if (resp.err) throw Error(resp.err.message);

    if (debug) {
      console.log('posItems')
      console.table(resp.posItems);
      console.log('serverItems')
      console.table(resp.serverItems);
    }

    resp = await savePosItems({ shopInfo, posItems: resp.posItems, serverItems: resp.serverItems, });
    if (resp.err) throw new Error(resp.err.message);

    if (debug) console.log("-".repeat(20), 'save end');
    return {};
  } catch (e) {
    if (debug) console.log(e)
    return { err: { message: e.message } }
  }
}

const updateGroupList = async (props) => {
  const { shopInfo, preOptions, isNew, optionName, menus, } = props
  try {
    let newGrpList = menus.groupList.map((grp, i) => {
      let newGrp = {};
      groups.inputFields.map(f => {
        newGrp[f.name] = getValueByType(grp, f)
        return null
      })
      return newGrp;
    });

    const data = {
      shopId: shopInfo.shopId,
      optionName: optionName,
      body: {
        groupList: JSON.stringify(newGrpList),
      }
    };

    let res;
    if (isNew) res = await shopItemOptionCreate(data);
    else res = await shopItemOptionUpdate(data);

    if (res.err) throw new Error(Texts.alertFailUpdate + " - groupList Update");

    return { groupList: newGrpList };
  } catch (e) {
    return { err: { message: e.message } };
  }
}

const updateOptions = async (props) => {
  const { shopInfo, preOptions, isNew, optionName, menus, } = props
  try {
    // let optionGroupList = menus.optionGroupList
    // let optionClassList = menus.optionClassList
    let optionItemList = menus.optionItemList

    let optionGroupList = menus.optionGroupList.map(o => {
      return {
        ...o,
        optionClassCodes: getArrayFromCodeList(o.optionClassCodes)
      }
    })

    let optionClassList = menus.optionClassList.map(o => {
      return {
        ...o,
        optionItemCodes: getArrayFromCodeList(o.optionItemCodes)
      }
    })


    const data = {
      shopId: shopInfo.shopId,
      optionName: optionName,
      body: {
        optionGroupList: JSON.stringify(optionGroupList),
        optionClassList: JSON.stringify(optionClassList),
        optionItemList: JSON.stringify(optionItemList),
      }
    };

    let res = await shopItemOptionUpdate(data);
    if (res.err) throw new Error(Texts.alertFailUpdate + " - groupList, MidGrpList Update");

    return { optionGroupList, optionClassList, optionItemList };
  } catch (e) {
    return { err: { message: e.message } };
  }
}

const updateTableList = async ({ shopInfo, optionName, menus }) => {
  try {
    let tableList = menus.tableList;

    const data = {
      shopId: shopInfo.shopId,
      optionName: optionName,
      body: { tableList: JSON.stringify(tableList) }
    };

    let res = await shopItemOptionUpdate(data);
    if (res.err) throw new Error(Texts.alertFailUpdate + " - tableList Update");

    return { tableList };
  } catch (e) {
    return { err: { message: e.message } };
  }
}

const cleanUpItemList = async ({ shopInfo, posItems, itemList }) => {
  try {
    let newItemList = [];
    let errMsg = ""
    for (let i = 0; i < itemList.length; i++) {
      const serverItem = itemList[i]
      const index = posItems.findIndex(posItem => posItem.itemCode === serverItem.itemCode);

      //
      // 포스에 없는 항목 오늘 메뉴DB에서 삭제
      //
      if (index < 0) {
        let res = await shopItemDelete({ shopId: shopInfo.shopId, id: serverItem.id });
        if (res.err) {
          errMsg = "필요없는 메뉴아이템 삭제 중 에러가 발생했습니다. " + res.err.message;
          break;
        }
      } else {
        newItemList.push(serverItem);
      }
    }

    if (errMsg !== "") throw Error(errMsg)

    return { posItems, serverItems: newItemList }
  } catch (e) {
    return { err: { message: e.message } };
  }
}

const savePosItems = async ({ posItems, shopInfo, serverItems }) => {
  try {

    const newServerItems = []
    let errMsg = ""

    for (let idx = 0; idx < posItems.length; idx++) {
      const posItem = posItems[idx]

      const index = serverItems.findIndex((serverItem) => serverItem.itemCode === posItem.itemCode);
      let itembody = createItem2({ posItem, idx });
      let res;

      /** itemCode가 없으면 신규 아이템이므로 create 함 */
      if (index < 0) {
        console.log("create", posItem.itemCode, posItem.itemName)
        res = await shopItemCreate({ shopId: shopInfo.shopId, body: itembody });
      }

      /** 동일한 itemCode가 있으면 update 함 */
      else {
        console.log("update", posItem.itemCode, posItem.itemName)
        res = await shopItemUpdate({ shopId: shopInfo.shopId, id: serverItems[index].id, body: itembody });
      }
      if (res.err) {
        errMsg += res.err.message
        break;
      }
      newServerItems.push(itembody);
    }
    if (errMsg !== "") throw Error(errMsg)

    return { newServerItems };
  } catch (e) {
    return { err: { message: e.message } }
  }
}

// function createItem({ posItem, idx }) {
//   let newItem = {
//     ...posItem, // groupName/groupCode | itemCode/itemName | price | optionGroupCodeList | ...
//     optionGroupCodeList: posItem.optionGroupCodeList || "",
//     sortOrder: posItem.sortOrder || idx,
//     image: posItem.image || "",
//     removed: posItem.removed === "TRUE",
//     isSoldout: posItem.isSoldout === "TRUE",
//     isNew: posItem.isNew === "TRUE",
//     isHot: posItem.isHot === "TRUE",
//     isBest: posItem.isBest === "TRUE",
//   };

//   return newItem
// }

// 
// 1차적으로 무조건 덮어쓴다.
// 
function createItem2({ posItem, idx }) {
  let newItem = {};
  items.inputFields.map(f => {
    if (f.type === "boolean") {
      newItem[f.name] = posItem[f.name] === "TRUE"
    } else if (f.type === "number") {
      let defaultNo = 0
      if (f.name === "maxQty") defaultNo = 1
      newItem[f.name] = parseInt(posItem[f.name]) || defaultNo
    } else {
      newItem[f.name] = posItem[f.name] || ""
    }

  })
  return newItem
}

/**
 * "001,002" => ["001", "002"]
 */
function getArrayFromCodeList(codeList) {
  try {
    if (codeList) {
      const codes = codeList.split(",")
      const newCodes = codes.map(code => code.trim())
      return newCodes
    } else {
      return []
    }
  } catch (e) {
    return []
  }
}

function getValueByType(item, f) {
  if (f.type === "boolean") {
    return item[f.name] === "TRUE"
  } else if (f.type === "number") {
    let defaultNo = 0
    if (f.name === "maxQty") defaultNo = 1
    return parseInt(item[f.name]) || defaultNo
  } else {
    return item[f.name] || ""
  }  
}
