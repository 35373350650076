import React, { useEffect } from "react";
import { Page, CardContainer } from "../../../components";
import { saveGeolocation } from "../../../lib/kakao-lib";
import { useSelector, useDispatch } from "react-redux";
import { setShopInfo } from "../../../redux/shop"
import { StoreInfo } from "./StoreInfo";
import { StoreMenuInfo } from "./StoreMenuInfo";
import { routePaths } from "../../../navigation/routePaths";
import { debug } from "../../../settings";

const str = {
  noItemFound: "NO ITEM FOUND",
  failedShopGet: "가게 정보를 가져오는데 실패했습니다.",
  seeDetail: "자세히 보기",
  menuSectionTitle: "메뉴 정보"
}

export default function ShopMyPage(props) {
  const { shopInfo } = useSelector(state => state.shop);
  const dispatch = useDispatch()
  if (debug) console.log(shopInfo.shopId, shopInfo);

  // 
  // save geoLocation - 기존 가맹점,
  // 주소 변경때눈 StoreRegister에서 수행함
  // 
  useEffect(() => {
    async function start() {
      try {
        if (shopInfo.storeCode && !shopInfo.geoLocation) {
          let res = await saveGeolocation({ shopInfo })
          if (res.err) throw Error(res.err.message)
          if (debug) console.log('res update geoLocation', res.result)
          const newShopInfo = {
            ...shopInfo,
            geoLocation: res.result
          }
          dispatch(setShopInfo({ shopInfo: newShopInfo }));
        }
        // console.log('geoLocation', shopInfo.geoLocation)
      } catch (e) {
        if (debug) console.log(e)
        alert("GeoLocation 저장에 문제가 있습니다. 관리자에게 문의하세요")
      }
    }
    start()
  }, [])

  return (
    <Page>
      <CardContainer
        height='auto'
        padding="0px 0px 40px 0px"
        title="가맹점"
        detail={
          (Object.keys(shopInfo).length > 0 && shopInfo?.storeCode !== undefined)
            ? str.seeDetail
            : null
        }
        onClickDetail={
          (Object.keys(shopInfo).length > 0 && shopInfo?.storeCode !== undefined)
            ? () => { props.history.push(routePaths.store.detail) }
            : null
        }
      >
        <StoreInfo {...props} shopInfo={shopInfo} />
      </CardContainer>

      <br />

      {
        // 
        // shop 등록이 되어 있는지 확인
        // 
        (shopInfo?.storeCode && shopInfo?.posName) &&
        <CardContainer
          title={str.menuSectionTitle}
        >
          <StoreMenuInfo {...props} shopInfo={shopInfo} />
        </CardContainer>
      }
    </Page>
  );
}
