
/** @TODO route, nav 모두 적용할 것 */
export const routePaths = {
  home: "/",

  // store
  // orderList: "/store/order-list",
  // dqrSetting: "/store/settings/dqr",
  // paymentSetting: "/store/settings/payment",

  store: {
    home: "/",
    detail: "/store/details",

    settingOptionMenus: "/store/option-menus",

    dqrSetting: "/store/settings/dqr",
    paymentSetting: "/store/settings/payment",
    othersSetting: "/store/settings/others",

    orderStatus: "/store/order-status",
    orderList: "/store/order-list",

    /** @TODO 검증 후 사용 */
    registerPos: "/store/register-posname",
    register: "/store/register/:action",
  },

  setPw: "/login/set-password",
  findId: "/login/find-id",
  signin: "/login",
  signup: "/signup",

  // user
  user: {
    // findId: "/user/find-id",
    setPw: "/user/set-password",
    editUser: "/user/edit",
  }
}