import axios from "axios";
import { debug, conf, ec2Debug } from "../settings";
import { payApiDebug, payEndpoint, ec2Api, payApi } from "../settings/appSetting";

const apiServerUrl = ec2Api.debug ? ec2Api.endpoint_debug : ec2Api.endpoint_public
const payServerUrl = payApi.debug ? payApi.endpoint_debug : payApi.endpoint_public

const axiosCall = async ({ method, path, body, host }) => {
  try {
    const baseUrl = host || payServerUrl

    let res = await axios({
      method,
      url: baseUrl + path,
      data: body,
      headers: { 'x-taptag-key': conf.apiKey }
    });

    if (res.status !== 200) throw new Error("Response Error");
    return res.data;
  } catch (e) {
    if(debug) console.log(e)
    return { err: { message: e.message } };
  }
}

/** @payDataRecord */
export const payDataCreate = ({ posName, shopId, ver, body }) => {
  const host = apiServerUrl
  const path = `/payment/pay-record?shopId=${shopId}&posName=${posName}&ver=${ver}`;
  return axiosCall({ method: "POST", path, body, host });
};

export const getPayRecords = ({ shopId, start_time, end_time }) => {
  const host = apiServerUrl
  const path = `/payment/pay-record/list?shopId=${shopId}&start_time=${start_time}&end_time=${end_time}`;
  return axiosCall({ method: "GET", path, host });
};

/** @IoT */
export const attachPolicy = async ({ identityId }) => {
  const body = { identityId }
  const path = `/api/iot/attach-policy`;
  return await axiosCall({ method: "POST", path, body });
};

/** @Redirect */
export const goToPayKis = ({ shopId, orderId, pgName }) => {
  if (pgName === "KISPG") {
    let payUrl = payServerUrl + `/kis/pay?shopId=${shopId}&orderId=${orderId}`
    window.location.href = payUrl
  }
};

export const getPayApiTest = async () => {
  const path = `/api/test`;
  return await axiosCall({ method: "GET", path });
};

/** @payCancel */
export const kisPayCancel = async ({ body }) => {
  // body: {shopId, ...payData}
  const path = `/api/pay-cancel/kis`;
  return await axiosCall({ method: "POST", path, body });
};

/** @testOrder_에서_사용함 */
export const notifyOrderToStore = ({ shopId, orderId, phone_number }) => {
  const body = { shopId, orderId, phone_number }
  const path = `/api/order-notify`;
  return axiosCall({ method: "POST", path, body });
}

/** @Kakao */
export const sendKakaoAlim = ({ type, orderNo, orderAmt, orderName, phone_number }) => {
  // type: A (주문 accepted) | R (메뉴 ready)
  // const orderInfo 데이터 예시
  //  {
  //   orderNo: "101",
  //   orderAmt: "12,000원",
  //   orderName: "비빔밥",
  //   phone_number: "821053174673"
  // }

  /** @TODO 국가별 구분 필요 */
  const phone = "82" + phone_number.substring(1)
  const body = {
    templateCode: (type === "A") ? "CCPSDL0000_00001" : "CCPSDL0000_00002",
    orderInfo: {
      orderNo, orderAmt, orderName,
      phone_number: phone
    }
  }

  const path = `/api/kakao/alim`;
  return axiosCall({ method: "POST", path, body });
}
