export const Texts = {
  shopMenu: {
    failItems: '메뉴를 불러오는데 실패 했습니다.',
    failShopInfo: '매장 정보를 불러오는데 실패 했습니다.',
    failOption: '옵션 정보를 불러오는데 실패 했습니다.',
    posBtnTitle: 'POS메뉴 불러오기',
    posBtnReset: "메뉴 초기화",
    
    haveItem: '',
    haveNoItem: 'SOLD OUT',

    itemDeleteSuccess: '메뉴를 삭제 했습니다.',
    itemDelteFailure: '메뉴 삭제에 실패 했습니다. 잠시후 다시 시도해주세요.',
    delete: '삭제',
    modify: '수정',
    modifyTitle: '메뉴 수정',
    menuTitle: '메뉴명',
  },

  menuItem : {
    soldout: 'SOLD OUT',
    best: '대표메뉴',
    new: '신메뉴',
    hot: '인기메뉴',
    hidden: '숨겨진 메뉴입니다.'
  }
}