import React, { useEffect, useState } from "react";
import { Page, Alert, CardContainer } from "../../components";
import { imageCompress } from "../../lib/utils";
import { enquireLicense, fileUpload, } from "../../lib/apiLib";
import { unionposProxy2, shopUpdate, shopGet } from "../../lib/ec2-api-lib";
import { getGeoLocation } from "../../lib/kakao-lib";
import { db, debug } from "../../settings";
import PostFormSection from "../../components/PostFormSection";
import { useSelector, useDispatch } from "react-redux";
import { setShopInfo } from '../../redux/shop';

const str = {
  alertSuccess: "성공적으로 저장하였습니다.",
  successLNo: "사업자번호가 확인되었습니다.",
  alertFail: "정보를 저장하는 데 실패했습니다.",
  alertFailStoreCode: "StoreCode 정보 입수에 실패하였습니다.",
  alertFailLicenseNo: "사용 불가능한 사업자 번호입니다.",
  titleCreate: "가게 정보 등록",
  titleUpdate: "가게 정보 수정",
  validLicenseNoMsg: "계속사업자",
  submitTextCreate: "등록 확인",
  submitTextUpdate: "수정 확인",
}

export default function ShopRegister(props) {
  const { shopInfo } = useSelector(state => state.shop);
  const { user } = useSelector(state => state.user);
  const isEdit = props.location?.isEdit;
  const editItems = props.location?.editItems;

  if (debug) console.log("posName", shopInfo.posName);

  let inputFields = [];
  if (shopInfo.posName === "UNIONPOS") inputFields = db.shop.inputFields;
  if (shopInfo.posName === "DUMMY_POS") inputFields = db.shop.inputFields;
  if (shopInfo.posName === "SMILEPOS") inputFields = db.shopSmilepos.inputFields;
  if (shopInfo.posName === "OKPOS") inputFields = db.shopOkpos.inputFields;
  if (shopInfo.posName === "OASIS_POS") inputFields = db.shopOasispos.inputFields;
  if (shopInfo.posName === "EASY_POS") inputFields = db.shopEasypos.inputFields

  return (
    <ShopRegisterForm
      {...props}
      shopInfo={shopInfo}
      user={user}
      inputFields={inputFields}
      isEdit={isEdit}
      editItems={editItems}
    />
  )
}

function ShopRegisterForm(props) {
  const {
    shopInfo,
    user,
    inputFields,
    isEdit, editItems,
  } = props;
  // 
  // 초기 등록시, shopInfo 가 empty 이므로 무조건 user.id 를 사용해야함.
  // 
  const shopId = user.id;
  const dispatch = useDispatch();

  // console.log("shopId", shopId);  
  // const inputFields = db.shop.inputFields;

  const _lnochecked = !(shopInfo.posName === "UNIONPOS");

  // 포베이 피에스디엘점 1028146446
  if (debug) console.log("isEdit", isEdit);
  if (debug) console.log("editItem", editItems);
  if (debug) console.log("_lnochecked", _lnochecked);

  const [licenseNoChecked, setLicenseNoChecked] = useState(_lnochecked);
  const [storeCode, setStoreCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [sucessMsg, setAlertMsg1] = useState("");
  const file = {};

  const onChangeInputFile = (e) => {
    file.current = e.target.files[0];
    if (debug) console.log("file.current", file.current);
  }

  const onCheckLicenseNo = async (lNo) => {
    try {
      let res = await _getStoreCode(lNo);
      if (debug) console.log('res getStoreCode', res)
      if (res.err) return setAlertMsg(str.alertFailStoreCode);
      setStoreCode(res.storeCode);
      setLicenseNoChecked(true);
      setAlertMsg(str.successLNo);
    } catch (e) {
      setAlertMsg(e.message);
    }
  }

  const onSubmit = async (fields) => {
    try {

      if (fields.addr1 === "") return alert("주소를 입력하세요!")

      setIsLoading(true);

      let fileRes = {};
      if (file.current) {
        let resizedImg = file.current;
        if (file.current.size > 500000) {
          resizedImg = await imageCompress({
            image: file.current,
            maxSizeMB: 0.5,
            maxWidthOrHeight: 800
          });
          if (!resizedImg) throw new Error("이미지 처리에 문제가 발생하였습니다.");
        }

        let stored = await fileUpload(resizedImg, shopId);
        if (stored.err) throw new Error(stored.err.message); // 수정 v0.3.9 : filesRes.err -> stored.err
        fileRes.key = stored.key;
      }
      // if (debug) console.log('fileuploaded', fileRes);

      let body = {};

      //
      // body 생성
      // 
      inputFields.map(item => {

        //
        // edit_모드에서는_변경된_속성값만_body로_넘겨줌
        // 
        if (isEdit) {
          if (fields[item.name] !== editItems[item.name]) body[item.name] = fields[item.name];
        }

        //
        // create 모드에서는 fields 전체를 body로 넘겨줌
        //
        else {
          if (item.name !== "image") body[item.name] = fields[item.name];
        }

        // 
        // image는 별도 fileUpload에서 리턴받은 key (image url)를 넘겨줌
        // "" 로 overwrite 되지 않도록 마지막에 삽입함
        // 
        if (item.name === "image" && fileRes.key !== undefined) {
          body[item.name] = fileRes.key;
        }

        return null;
      });

      if (Object.keys(body).length === 0)
        throw new Error((isEdit ? "수정" : "저장") + "할 데이터가 없습니다.");

      // 
      // geoLocation 추가
      // 
      let res = await getGeoLocation({ addr: fields.addr1 })
      if (debug) console.log('res loc', res)
      if (res.err) throw Error("GeoLocation 저장에 문제가 있습니다. 관리자에게 문의하세요!")
      body.geoLocation = JSON.stringify(res.result)

      if (isEdit) {
        if (debug) console.log("body update", body);
        res = await shopUpdate({ shopId, body });
      } else {

        /** 최초 생성 떄는 storeCode를 삽입한다. */
        if (shopInfo.posName === "UNIONPOS") body.storeCode = storeCode;
        if (shopInfo.posName === "DUMMY_POS") body.storeCode = storeCode;

        // 
        // 1. 가맹점 등록 시 즉시 사용 가능. 
        // 2. 이후 사용 불가 판정시  admin에서 false로 변경
        // 3. 서버 혹은 주문 앱에서 확인 후 진행하지 않도록 함.
        // 
        body.approved = true;

        // 
        // 이전에 posName을 등록하였으므로 update 임
        //         
        if (debug) console.log("body create", body);
        res = await shopUpdate({ shopId, body });
      }
      if (res.err) throw new Error(str.alertFail + "\n" + res.err.message);

      let newShopInfo = { ...shopInfo, ...res.result.Item };
      dispatch(setShopInfo({ shopInfo: newShopInfo }));

      setIsLoading(false);
      setAlertMsg1(str.alertSuccess);
    } catch (e) {
      if (debug) console.log(e)
      setAlertMsg(e.message);
      setIsLoading(false);
    }
  }

  const _getStoreCode = async (licenseNo) => {
    try {

      // 
      // test Shop용 lno 체크
      // 
      // let isTest = licenseNo.includes("(") && licenseNo.includes(")")

      let res;

      // if (!isTest) {
      //   res = await enquireLicense([licenseNo]);
      //   if (res !== str.validLicenseNoMsg) throw new Error(str.alertFailLicenseNo);
      // }

      // res = await enquireLicense([licenseNo]);
      // if (res !== str.validLicenseNoMsg) throw new Error(str.alertFailLicenseNo);

      if (shopInfo.posName === "UNIONPOS") {
        res = await unionposProxy2({ apiName: 'shopInfo', body: { "BizNo": licenseNo } });
        if (res.err) throw new Error(str.alertFailStoreCode + " " + res.err.message);

        if (res.result.CODE.includes("E")) return { err: { message: res.result.MSG } };
        return { storeCode: res.result.DATA.StoreInfo.StoreCode };
      }

      if (shopInfo.posName === "DUMMY_POS") {
        const storeCode = "P" + licenseNo + "-" + Math.floor(Date.now() / 1000)
        if(debug) console.log("storeCode", storeCode)
        return { storeCode };
      }

      return { err: { message: "지원하지 않는 포스입니다." } };

    } catch (e) {
      return { err: { message: e.message } };
    }
  }

  return (
    <Page>
      <CardContainer
        variant="md"
        padding="24px 20px"
        title={isEdit ? str.titleUpdate : str.titleCreate}
        // onClickBack={() => props.history.push("/store")}
        onClickBack={() => props.history.push("/")}
      >
        <PostFormSection
          {...props}
          isEdit={isEdit}
          editItems={editItems}
          inputFields={inputFields}
          onSubmit={onSubmit}
          licenseNoChecked={licenseNoChecked}
          onChangeInputFile={onChangeInputFile}
          onSubmitText={isEdit ? str.submitTextUpdate : str.submitTextCreate}
          onCheckLicenseNo={onCheckLicenseNo}
          isLoading={isLoading}
        />

      </CardContainer>
      <Alert
        title={alertMsg}
        visible={alertMsg !== ""}
        onClickOk={() => setAlertMsg("")} />
      <Alert
        title={sucessMsg}
        visible={sucessMsg !== ""}
        onClickOk={() => props.history.push("/")} />
    </Page>
  )
}
