import { okposItemOptionUpdate, shopItemOptionUpdate } from "../lib/ec2-api-lib";
import { posList } from "./posList";

export function updateItemOption({ shopInfo, body }) {
  if (!posList.includes(shopInfo.posName)) return { err: { message: "INVALID POSNAME" } };
  if (Object.keys(body).length === 0) return { err: { message: "INVALID BODY - Empty" } };

  const params = {
    shopId: shopInfo.shopId,
    optionName: shopInfo.posName === "UNIONPOS" ? "unionpos" : shopInfo.posName,
    body
  };
  if (shopInfo.posName === "OKPOS") return okposItemOptionUpdate(params);
  else return shopItemOptionUpdate(params);
}