import React, { useEffect, useState } from "react";
import { ModalForm, Button } from "../../../../components";
import { Recycle } from "../../../../components/Icons";
import { resyncForce } from "../../../../lib/ec2-dqr-api";

const str = {
  noItemsSelected: "선택된 항목이 없습니다!",
  noName: "명칭이 비어 있습니다!"
}

export default function DqrResyncModal(props) {
  const {    visible, setVisible,    dqr  } = props;
  const [isLoading, setIsLoding] = useState(false)

  const resyncDqr = async () => {
    try {
      setIsLoding(true)
      console.log(dqr.uid)
      let res = await resyncForce({ body: { uid: dqr.uid } })
      if (res.err) throw Error(res.err.message)
      alert("Resync OK!!")
    } catch (e) {
      alert(e.message)
    } finally {
      setIsLoding(false)
      setVisible(false)
    }
  }

  return (
    <ModalForm
      visible={visible}
      setIsVisible={setVisible}
      title={`DQR Resync - ${dqr.uid}`}
      backdrop="static"
    >
      <br />
      <Button
        variant="primary"
        size="sm"
        width="100%"
        title='Resync DQR'
        onClick={resyncDqr}
        isLoading={isLoading}
      >
        <Recycle style={{ color: "white" }} />&nbsp;
      </Button>
    </ModalForm>
  )
}
