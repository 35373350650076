import { createOrderDataUnionpos, createOrderDataOasispos } from "./sendOrder";
import { payDataCreate } from "../lib/pay-api-lib";
import { debug } from "../settings";

const pos = {
  "UNIONPOS": { saveOrderDataBeforePay: saveOrderDataBeforePayUnionpos, },
  "OASIS_POS": { saveOrderDataBeforePay: saveOrderDataBeforePayOasispos, },
}
const posList = Object.keys(pos)

export async function saveOrderDataBeforePay({ shopInfo, ...rest }) {
  if (!posList.includes(shopInfo.posName)) return { err: { message: "NOT SUPPORTED" } }
  return pos[shopInfo.posName].saveOrderDataBeforePay({ shopInfo, ...rest })
}

/** @UNIONPOS */

async function saveOrderDataBeforePayUnionpos({
  shopInfo, cartItems, itemOptions2, tableNo, token, ver
}) {
  try {
    let resOrderObj = createOrderDataUnionpos({
      shopInfo, cartItems, itemOptions2, tableNo, token
    })
    if (resOrderObj.err) throw Error(resOrderObj.err.message)

    if (debug) console.log('resOrderObj', resOrderObj)

    const orderInfo = getOrderSummary(cartItems, itemOptions2)
    if (orderInfo.err) throw Error(orderInfo.err.message)

    const body = {
      ...resOrderObj.result, // sendOrder.orderData
      orderName: orderInfo.orderName,
      orderAmt: orderInfo.orderAmt,
      orderNotifyOptions: shopInfo.orderNotifyOptions
    }
    console.table(body)

    const res = await payDataCreate({ posName: "UNIONPOS", shopId: shopInfo.shopId, body, ver });
    if (res.err) throw Error(res.err.message)
    return res
  } catch (e) {
    return { err: { message: e.message } }
  }
}

function getOrderSummary(cartItems, itemOptions2) {
  try {
    const usePosMenuFirst = itemOptions2.menuSettings?.usePosMenuFirst || false

    let orderAmt = 0
    let orderNames = []
    cartItems.map(cartItem => {
      let itemName = usePosMenuFirst ? cartItem.itemName : cartItem.itemName2

      let optNameList = []
      let optPrice = 0
      cartItem.options.map((opt) => {
        optNameList.push(opt.optionItemName)
        optPrice += opt.optPrice
      })

      const optNames = '(' + optNameList.join(',') + ')'
      if (optNameList.length > 0) itemName += optNames
      if (cartItem.count > 1) itemName += " x " + cartItem.count

      orderNames.push(itemName)
      orderAmt += (parseInt(cartItem.price) + optPrice) * cartItem.count
    })

    const orderName = orderNames.join(' , ')
    return { orderAmt: orderAmt.toString(), orderName }
  } catch (e) {
    return { err: { message: e.message } }
  }
}

/** @OASIS_POS */

async function saveOrderDataBeforePayOasispos({
  shopInfo, cartItems, itemOptions2, tableNo, token, ver
}) {
  try {
    let resOrderObj = createOrderDataOasispos({
      shopInfo, cartItems, itemOptions2, tableNo, token
    })
    if (resOrderObj.err) throw Error(resOrderObj.err.message)

    if (debug) console.log('resOrderObj', resOrderObj)

    const orderSum = getOrderSummary(cartItems, itemOptions2)
    if (orderSum.err) throw Error(orderSum.err.message)

    console.log('orderSummary', orderSum)

    const body = {
      ...resOrderObj.result, // sendOrder.orderData
      orderName: orderSum.orderName,
      orderAmt: orderSum.orderAmt,
      orderNotifyOptions: shopInfo.orderNotifyOptions
    }
    // console.table(body)

    const res = await payDataCreate({ posName: shopInfo.posName, shopId: shopInfo.shopId, body, ver });

    if (res.err) throw Error(res.err.message)
    return res
  } catch (e) {
    if (debug) console.log(e)
    return { err: { message: e.message } }
  }
}
