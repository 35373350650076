import React from "react";
import { Button, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSync,
  // faCamera, faCheckSquare, faSquare, faSpinner, faCircleNotch,  faCog, faStroopwafel,
  // faQuoteLeft, faArrowRight, faSkating, faSkiing, faSkiingNordic, faSnowboarding, faSnowplow
} from "@fortawesome/free-solid-svg-icons";

// import "./LoaderButton.css";

export function LoaderButton({
  isLoading,
  className = "",
  disabled = false,
  ...props
}) {
  return (
    <Button
      className={`LoaderButton ${className}`}
      disabled={disabled || isLoading}
      {...props}
    >
      {/* {isLoading && <Glyphicon glyph="refresh" className="spinning" />} */}
      {isLoading && <FontAwesomeIcon icon={faSync} spin />} {props.children}
    </Button>
  );
}

export const LoaderFullPage = (props) => {
  const myStyles = {
    wrap: {
      position: "absolute",
      top: "0",
      left: "0",
      width: "100vw",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: props.bgColor || "rgba(0, 0, 0, .05)",
      // backgroundColor: props.bgColor || "rgba(0, 0, 0, .3)",
    },
    // item: { width: "200px", backgroundColor: "white" },
  };
  return (
    <div style={myStyles.wrap}>
      <Spinner animation="border" variant="danger" />
    </div>
  );
};

export const Loader = (props) => {
  const myStyles = {
    wrap: {
      width: "100%",
      height: "100px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };
  return (
    <div style={myStyles.wrap}>
      <Spinner animation="border" variant="secondary" />
    </div>
  );
};
