
export function getReqTime_oasispos() {
  const now = new Date()
  const yr = now.getFullYear()
  const mon =  now.getMonth()
  const da =  now.getDate()
  const hr = now.getHours()
  const min = now.getMinutes()
  const sec = now.getSeconds()

  return yr.toString()
    + "-" + (mon+1).toString().padStart(2, "0")
    + "-" + da.toString().padStart(2, "0")
    + " " + hr.toString().padStart(2, "0")
    + ":" + min.toString().padStart(2, "0")
    + ":" + sec.toString().padStart(2, "0")
}