import axios from "axios";
import { debug, conf } from "../settings";

const axiosCall = async ({ method, path, body }) => {
  try {
    const baseUrl = conf.ec2Debug
      ? "http://localhost:5000"
      : conf.ec2ApiEndpoint;

    // if (debug) console.log('baseUrl', baseUrl);

    let res = await axios({
      method,
      url: baseUrl + path,
      data: body,
      headers: { 'x-taptag-key': conf.apiKey }
    });
    // if (debug) console.log('res - api ', res);
    if (res.status !== 200) throw new Error("Response Error");
    return res.data;
  } catch (e) {
    return { err: { message: e.message } };
  }
}

/**
 * @CMS_Category
 * 
 * @param {String} group: 카테고리 그룹 예) onl-shop, 주로 application에 해당할 듯
 * @param {String} category: 카테고리  예) docs
 */
export const categoryGet = async ({ group, category }) => {
  let path = `/cms/category?group=${group}&category=${category}`;
  return await axiosCall({ method: "GET", path });
};
export const categoryList = async ({ group }) => {
  let path = `/cms/category/list?group=${group}`;
  return await axiosCall({ method: "GET", path });
};

/**
 * @CMS_Post
 * @param {String} category: 카테고리  예) docs
 */
export const postGet = async ({ category, id }) => {
  let path = `/cms/post?category=${category}&id=${id}`;
  return await axiosCall({ method: "GET", path });
};
