import React, { useEffect, useState } from "react";
import { Page, CardContainer, } from "../../components";
import { gStyle as styles, colors } from "../../components";
import readXlsxFile from "read-excel-file";

export default function ExcelTest(props) {

  // const grpList = [
  //   ['GrpName', 'StoreCode', 'GrpCode'],
  //   ['APPETIZER', '3001', '00017'],
  //   ['NOODLE', '3001', '00018'],
  // ]

  const [group, setGroup] = useState([]) 

  const onChange = (e) => {
    readXlsxFile(e.target.files[0])
      .then((rows) => {
        console.log('rows', rows);
        setGroup(rows)
        _convert2Obj(rows)
      })
  }

  const _convert2Obj = (rows) => {
    let grpList = [];
    for (let i = 0; i < rows.length; i++) {
      let row = rows[i];

      let group = {};
      for (let j = 0; j < row.length; j++) {
        if (i > 0) {
          group[rows[0][j]] = row[j]
        }
      }
      if (i > 0) grpList.push(group)
    }
    console.log("grpList", grpList)
  }

  const myStyle = {
    padding: "40px",
    title: {
      ...styles.title,
      ...styles.bold,
      color: colors.primary,
      textAlign: "center",
      fontSize: "40px"
    },
  }

  return (
    <Page>
      <h1 style={myStyle.title}>Excel Upload Test</h1>
      <br />

      <CardContainer title="Excel Upload Form" padding={myStyle.padding}>
        <div>
          <input type="file" id="excel-input" onChange={onChange} />
        </div>
        <hr/>
        <div>
          {
            group.length > 0 &&
            group.map((row,i) => {
              const keys = Object.keys(row);
              return (
                <div key={i} style={{display: 'flex', justifyContent: "space-around"}}>
                  {
                    keys.map((key, j) => <p key={j}>{row[key]}</p>)
                  }
                </div>
              )
            })
          }

        </div>
      </CardContainer>
    </Page>
  )
}
