import React from 'react';
import { useSelector } from 'react-redux';
import noImage from "../../../assets/images/no-image.png";
import { Button, useStyles } from '../../../components';

export function StoreInfo(props) {
  const { shopInfo } = props;
  const { isMobileDevice, isMobilePhone } = useSelector(state => state.platform);
  const { gStyle } = useStyles()

  const styles = {
    container: { width: "100%" },
    image: {
      borderRadius: isMobilePhone ? "10px 10px 0px 0px " : "20px 20px 0px 0px ",
      marginBottom: '30px',
      backgroundImage: `url(${shopInfo.image ? shopInfo.image : noImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      width: "100%",
      height: isMobileDevice ? "40vh" : 450
    },
    bottomContainer: {
      width: "100%",
      height: "25%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      paddingLeft: 15, paddingRight: 15
    },
    shopName: { ...gStyle.textBold, ...gStyle.bold, marginBottom: "10px" },
    shopAddr: { ...gStyle.text }
  };

  return (
    <div style={styles.container}>
      <div style={styles.image} />

      <div style={styles.bottomContainer}>
        {
          // Object.keys(shopInfo).length > 0
          (shopInfo.storeCode !== undefined)
            ?
            <>
              <h5 style={styles.shopName}>{shopInfo.shopName}</h5>
              <p style={styles.shopAddr}>{shopInfo.addr1 + ' ' + shopInfo.addr2}</p>
            </>
            :
            <Button
              variant="primary" title="가게정보 등록하기"
              onClick={() => props.history.push({ pathname: `/store/register-posname`, isEdit: false })}
            />
        }
      </div>
    </div>
  )
}
