export function serviceTimeSet(sTimeObj) {
  // console.log('serviceTimeObj', sTimeObj)
  if (sTimeObj === undefined) return false
  let isTimeSet = true
  isTimeSet = isTimeSet && (sTimeObj.startTime !== undefined)
  isTimeSet = isTimeSet && (sTimeObj.endTime !== undefined)
  isTimeSet = isTimeSet && (sTimeObj.startTime !== sTimeObj.endTime)

  let isDaysSet = true
  isDaysSet = isDaysSet && (sTimeObj.days !== undefined)
  isDaysSet = isDaysSet && (sTimeObj.days.length < 7)

  return isDaysSet || isTimeSet
}