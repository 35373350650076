import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

export default function AuthenticatedRoute({
  component: C,  
  ...rest
}) {
  const { isSigned, user, username } = useSelector((state) => state.user);  

  return (
    <Route
      {...rest}
      render={(props) =>
        isSigned ? (
          <C {...props} />
        ) : (
          <Redirect
            to={`/login?redirect=${props.location.pathname}${props.location.search}`}
          />
        )
      }
    />
  );
}
