import React, { useEffect, useState } from "react";
import { useStyles, ModalForm } from "../../../components";
import { CustomTable2 } from "../../../components/Tables2";
import { getGroupList, updateItemOption, getSelectedGroup, getSelectedItems, updateItem } from "../../../lib-pos";
import { debug } from "../../../settings";
// import { logItemList } from "../StoreHome/StoreMenuInfo";
import { ChevronD, ChevronU } from "../../../components/Icons";

const tableHeaders = {
  okpos: [
    { name: "selected", displayName: " ", type: "checkbox", keyName: "GrpCode" },
    // { name: "sorting", displayName: "순서", type: "sorting" },
    { name: "name", displayName: "그룹명" },
    { name: "GrpCode", displayName: "그룹코드" },
  ],
  others: [
    { name: "selected", displayName: " ", type: "checkbox", keyName: "GrpCode" },
    // { name: "sorting", displayName: "순서", type: "sorting" },
    { name: "name", displayName: "그룹명" },
    { name: "GrpCode", displayName: "그룹코드" },
  ],
  easyPos: [
    { name: "selected", displayName: " ", type: "checkbox", keyName: "groupCode" },
    // { name: "sorting", displayName: "순서", type: "sorting" },
    { name: "groupName", displayName: "그룹명" },
    { name: "groupCode", displayName: "그룹코드" },
  ],
}

const tableHeadersItems = {
  okpos: [
    { name: "selected", displayName: " ", type: "checkbox", keyName: "itemCode" },
    // { name: "sorting", displayName: "순서", type: "sorting" },
    { name: "itemName", displayName: "아이템명" },
    { name: "itemCode", displayName: "아이템코드" },
    // { name: "itemName2", displayName: "표시명" },
  ],
  others: [
    { name: "selected", displayName: " ", type: "checkbox", keyName: "itemCode" },
    // { name: "sorting", displayName: "순서", type: "sorting" },
    { name: "itemName", displayName: "아이템명" },
    { name: "itemCode", displayName: "아이템코드" },
    // { name: "itemName2", displayName: "표시명" },
  ]
}

export default function SortingModal(props) {
  const {
    visible, setVisible,
    shopInfo,
    itemList, itemOptions2,
    selectedGroup2,
    onload,
    type
  } = props;

  const modalTitle = type === "group" ? "그룹 순서 편집" : "아이템 순서 편집";
  // const [modalTitle, setModalTitle] = useState(_modalTitle)
  const [isLoading, setIsLoading] = useState(false);
  const [tHeader, setTHeader] = useState([])
  const [rowItems, setRowItems] = useState([])
  const [selGroup, setSelGroup] = useState({})

  useEffect(() => {
    if (type === "group") _setGroup()
    else _setSeletedItems()

  }, [selectedGroup2, type])

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      let res = {}
      const newList = rowItems.map((o, i) => {
        return { ...o, sortOrder: i }
      })

      if (type === "group") res = await _updateGroup(newList)
      else res = await _updateItems(newList)

      if (res.err) throw Error(res.err.message)
      await onload();
    } catch (e) {
      alert(e.message);
    } finally {
      setIsLoading(false);
      setVisible(false);
    }
  }

  const _setGroup = () => {
    const posName = shopInfo.posName
    if (posName === "OKPOS") {
      setTHeader(tableHeaders.okpos)
    } if (posName === "EASY_POS") {
      setTHeader(tableHeaders.easyPos)
    } else {
      setTHeader(tableHeaders.others)
    }
    const sortedGroups = getGroupList({ itemOptions2 })
    setRowItems(sortedGroups)
  }

  const _setSeletedItems = () => {
    const posName = shopInfo.posName
    const _selGroup = getSelectedGroup({ itemOptions2, selectedGroup: selectedGroup2 })

    let selItems = getSelectedItems({ posName, group: _selGroup, itemList, showRemoved: true })

    if (debug) {
      // console.log(_selGroup)
      // logItemList({ shopInfo, itemList: selItems })
    }

    if (posName === "OKPOS") {
      setTHeader(tableHeadersItems.okpos)
    } else {
      setTHeader(tableHeadersItems.others)
    }
    setRowItems(selItems)
    setSelGroup(_selGroup)
  }

  const _updateGroup = async (_newList) => {

    const newList = _newList.map(obj => {
      const obj2 = { ...obj }
      delete obj2.selected
      return obj2
    })

    let body = {}
    if (shopInfo.posName === "OKPOS") {
      body = { TuClsList: JSON.stringify(newList) }
    } else if (shopInfo.posName === "DUMMY_POS" || shopInfo.posName === "OASIS_POS") {
      body = { groupList: JSON.stringify(newList) }
    } else if (shopInfo.posName === "EASY_POS") {
      body = { touchClassList: JSON.stringify(newList) }
    } else { // UNIONPOS
      body = { GrpList: JSON.stringify(newList) }
    }
    return updateItemOption({ shopInfo, body })
  }

  const _updateItems = async (newList) => {
    try {
      let res = {}
      let errMsg = ""
      for (let i = 0; i < newList.length; i++) {
        const item = newList[i]
        res = await updateItem({ shopInfo, id: item.id, body: { sortOrder: item.sortOrder } });
        if (res.err) {
          errMsg = res.err.message
          break;
        }
      }
      if (errMsg !== "") throw Error(errMsg)

      if (shopInfo.posName === "OKPOS") {
        // 
        // TuClsList > itemList 추가
        //
        const itemCodeList = newList.map(item => item.itemCode)
        const newGroup = { ...selGroup, itemList: itemCodeList }

        if (debug) console.table([newGroup])
        if (debug) console.log(newGroup.itemList)

        const key = "TU_CLS_CD"
        const newGroupList = itemOptions2.TuClsList.map(cls => {
          if (cls[key] === newGroup[key]) return newGroup
          else return cls
        })

        res = await updateItemOption({
          shopInfo,
          body: { TuClsList: JSON.stringify(newGroupList) }
        })
        if (res.err) throw Error(res.err.message)
      }
      return {}
    } catch (e) {
      if (debug) console.log(e)
      return { err: { message: e.message } }
    }
  }

  const _onMove = (direction) => {
    const newItems = [...rowItems]

    let idx = newItems.findIndex(item => item.selected)
    if (idx < 0) return null
    const obj = newItems[idx]

    newItems.splice(idx, 1)
    if (direction === "up") {
      idx = idx === 0 ? idx : --idx
    } else {
      idx = idx === newItems.length ? idx : ++idx
    }
    newItems.splice(idx, 0, obj)
    setRowItems(newItems)
  }

  const styles = {
    formGroup: { margin: "15px 5px" }
  }

  return (
    <ModalForm
      visible={visible}
      setIsVisible={setVisible}
      title={modalTitle}
      onSubmit={onSubmit}
      isLoading={isLoading}
      size="lg"
    >
      <div style={styles.formGroup}>
        <Header {...props} onMove={_onMove} />

        <CustomTable2
          tableHeader={tHeader}
          rowItems={rowItems}
          setRowItems={setRowItems}
        />

      </div>
    </ModalForm>
  )
}

function Header(props) {

  const { gStyle } = useStyles()

  return (
    <div style={{
      display: "flex",
      justifyContent: "space-between", alignItems: "center",
      margin: 5
    }}>
      <p style={{ ...gStyle.text }}>순서를 변경 후 확인을 누르세요.</p>
      <div style={{
        border: "1px solid #ddd",
        borderRadius: 5,
        padding: "5px 10px",
        display: "flex",
        justifyContent: "space-between", alignItems: "center"
      }}>
        <p style={{ ...gStyle.text, marginRight: 5 }}><ChevronU onClick={() => props.onMove("up")} /></p>
        <p style={{ ...gStyle.text }}><ChevronD onClick={() => props.onMove("down")} /></p>
      </div>
    </div>
  )
}
