import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setPlatform } from './redux/platform';
import { setUser, setUsername, resetUsername } from './redux/user';
import { setShopInfo, resetShopInfo } from './redux/shop';
import { userGet, shopGet } from './lib/ec2-api-lib';
// import { attachPolicy } from './lib/pay-api-lib';
import Routes from './navigation/Routes2';
import NavigationBar from './navigation/NavigationBar';
import Footer from './pages/Footer';
import { CustomModal, LoaderFullPage, Alert } from './components';
import { parseShopInfo } from './lib-pos';
import { Auth } from 'aws-amplify';
import { debug } from './settings';

const str = {
  failedUserGet: "가입자 정보를 가져오는데 실패했습니다!",
  failedShopGet: "가게 정보를 가져오는데 실패했습니다!",
  failedAttachPolicy: "IOT 정책설정에 문제가 있습니다."
}

function App(props) {
  const { isMobileDevice } = useSelector((state) => state.platform);
  const dispatch = useDispatch();

  const [isLoading, setIsloading] = useState(true);
  const [isLogoutModalShow, setIsLogoutModalShow] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");

  useEffect(() => {
    const onLoad = async () => {
      try {
        dispatch(setPlatform());

        const cred = await Auth.currentCredentials();
        const identityId = cred.identityId

        const cognitoUser = await Auth.currentUserPoolUser();
        dispatch(
          setUsername({ username: cognitoUser.username })
        );
        let res = await userGet({
          username: cognitoUser.username,
        });
        if (res.err) throw new Error(str.failedUserGet + "\n" + res.err.message);
        if (debug) console.log("user", res.result.Item);

        const shopId = res.result.Item.id;
        dispatch(setUser({ user: { ...res.result.Item, shopId, identityId } }));

        res = await shopGet({ shopId });
        setIsloading(false);
        // console.log("shop res", res);

        // 
        // 가맹점 정보가 등록되지 않은 경우 추가
        // 
        if (res.err) {
          setAlertMsg("가맹점 정보가 아직 등록되어 있지 않습니다.");
        } else {
          const sInfo = parseShopInfo({ shopInfo: res.result.Item })
          dispatch(setShopInfo({ shopInfo: sInfo }));
        }

        props.history.push("/");
      } catch (e) {
        if (debug) console.log('Session Error:', e);
        setIsloading(false);
        props.history.push('/login');
      }
    }

    onLoad();
  }, []);

  const handleLogout = async () => {
    setIsLogoutModalShow(false);
    // await logOut();
    await Auth.signOut();

    dispatch(resetUsername());
    dispatch(resetShopInfo());

    props.history.push('/login');
  }

  return (
    <div className="App">
      <NavigationBar {...props} handleLogout={() => setIsLogoutModalShow(true)} />
      {!isLoading && <Routes />}
      {!isLoading && !isMobileDevice && <Footer {...props} />}
      {isLoading && <LoaderFullPage />}

      <CustomModal
        visible={isLogoutModalShow}
        title='로그아웃 하시겠습니까?'
        marginTop="20%"
        onClickOk={handleLogout}
        onClickCancel={() => setIsLogoutModalShow(false)}
      />
      <Alert title={alertMsg} visible={alertMsg !== ""} onClickOk={() => setAlertMsg("")} />
    </div>
  );
}

export default withRouter(App);
