
/**
 * @info_createItemByInputFields_에서_사용
 */
export const shopItem = {
  // 
  // tableName = shopItems
  // key = shopId, id
  // 
  name: "shopItem",
  inputFields: [

    /*************************************************************************
     * @POS메뉴에서_가져온_오리지널_속성_편집불가
     ************************************************************************/

    // 
    // from itemList
    // 
    { name: "groupCode", posName: "GrpCode" },  // 그룹코드

    // v0.4.4 부터 추가함
    { name: "groupName", posName: "GrpName" },  // 그룹명
    { name: "itemGroup", posName: "GrpName" },  // 그룹명
    { name: "itemCode", posName: "ItemCode" },
    { name: "itemName", posName: "ItemName" },
    { name: "lineup", posName: "Lineup" },
    { name: "price", posName: "Price" },
    { name: "vatType", posName: "VatType" },
    { name: "vatType2", posName: "VatType2" },
    { name: "menuCode", posName: "MenuCode" },
    { name: "menuType", posName: "MenuType" },  // 고정세트, 변동세트 | 선택
    { name: "menuType2", posName: "MenuType2" },//   FIXED, DYNAMIC | CHOICE

    // v0.3.9 에서 추가
    { name: "subCol1", posName: "SubCol1" },  // ESSENTIAL|QTYMGR

    // 
    // from imageList
    // 
    { name: "oriImage", posName: "ImagePath" },

    // 
    // from descriptionList
    // 
    { name: "itemDescription", posName: "ItemDescription" },

    // 
    // v0.4.3에서 삭제, 필요시 descriptionLang 으로 분기
    // 
    // { name: "itemDescription_CN", posName: "ItemDescription_CN" },
    // { name: "itemDescription_EN", posName: "ItemDescription_EN" },
    // { name: "itemDescription_JP", posName: "ItemDescription_JP" },
    { name: "itemTag", posName: "ItemTag" },
    { name: "itemTagNew", posName: "ItemTagNew" },        // Y or N
    { name: "itemTagHot", posName: "ItemTagHot" },        // Y or N
    { name: "itemTagBest", posName: "ItemTagBest" },      // Y or N
    { name: "itemTagEvent", posName: "ItemTagEvent" },    // Y or N
    { name: "itemTagDc", posName: "ItemTagDc" },          // Y or N
    { name: "itemTagPick", posName: "ItemTagPick" },      // Y or N

    // 
    // from soldOutList
    // 
    // { name: "SoldOut", posName: "SoldOut" },                 // Y or N
    { name: "itemSoldOut", posName: "SoldOut" },              // Y or N

    // 
    // POS 언어 저장
    // 
    { name: "posNameLang", posName: "posNameLang" },          // {KR, EN, CN, JP}
    { name: "posDescriptionLang", posName: "posDescriptionLang" },   // {KR, EN, CN, JP}

    /*************************************************************************
     * @ONL메뉴에서_편집_가능한_속성들
     ************************************************************************/

    // 
    // @그룹변경은_useGroupChange_옵션으로_선택_가능하도록_함    
    // deprecated v0.4.0 부터 삭제함.
    // 
    // { name: "groupCode2", defaultValue: { posName: "GrpCode" } },     // 그릅 코드 GrpList.GrpCode 중 하나를 선택하여 교체만 함
    // { name: "itemGroup2", defaultValue: { posName: "GrpName" } },

    // 
    // itemName 관련 정보
    // 
    { name: "itemName2", defaultValue: { posName: "ItemName" } },

    // 
    // 아이템 언어지원: 기본값은 undefined?
    // 
    // { name: "nameLang", defaultValue: JSON.stringify({}) },

    // 
    // description
    // 
    { name: "description", defaultValue: { posName: "ItemDescription" } },

    { name: "sortOrder", defaultValue: 0 },
    { name: "image", defaultValue: "" },
    { name: "removed", defaultValue: false },                                   // ONL 메뉴에서 제거하고 싶을 때
    { name: "isSoldout", defaultValue: false },
    { name: "isNew", defaultValue: false },
    { name: "isHot", defaultValue: false },
    { name: "isBest", defaultValue: false },

    /**
     * @deprecated start, end : 오전 12시 ~ 오후 11시 (외국어이면 Display만 AM 12, PM 11 )
     *  startTime: "0" ~ "23"
     *  endTime: "0" ~ "23"
     *  days: [MON,TUE,WED,THU,FRI,SAT,SUN]
     * 기본값은 undefined
     */
    // { name: "serviceTime", defaultValue: JSON.stringify({startTime, endTime, days}) },

  ],
};

/**
 * @deprecated
 * @info_unionpos_menu아이템_수정에_필요
 */
// export const unionposShopItemUpdate = {
//   inputFields: [
//     {
//       name: "itemName2",
//       displayName: "메뉴 별칭",
//       required: false,
//       placeholder: "메뉴명과 별도로 부여 가능",
//     },
//     {
//       name: "description",
//       displayName: "상품 설명",
//       required: false,
//       comment: "간단한 상품정보 설명...",
//       // readOnly: true
//     },
//     {
//       name: "image",
//       displayName: "아이템 사진",
//       inputType: "file",
//       required: false,
//     },
//   ],
// };

/**
 * @Unionpos_itemOptions_속성값_참조용
 */
// const itemOptions = {
//   GrpList: [
//     {
//       GrpLineup: 0,
//       StoreCode: "3000000028306",

//       /** 이하는 모든 포스들의 공통 속성 */
//       GrpName: "세트메뉴",
//       name: "한식",
//       GrpCode: "00001",

//       /** 편집 가능한 속성 */
//       nameLang: {},
//       displayName: "한식 - 맛있는",
//       sortOrder: 0,
//       hidden: false                    // 필요없는 그룹을 숨김 v0.3.4에서 추가함.
//     }
//   ],
//   SetMenuList: [],
//   ChoiceMenuList: [],
//   MidGrpList: [],
//   TableList: [],
//   menuSettings: { usePosMenuFirst, supportedLangs: [] }
// }

