/**
 * App의 settings index
 */
import config from "./conf";
export { config as conf }
export const debug = config.debug;

export { default as db } from "./db";
export { default as strings } from "./strings";
// export { routePaths } from "../navigation/routePaths"
