import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Auth } from "aws-amplify";
import { setUser } from "../redux/user";
import { setShopInfo, resetSeletectedLang } from '../redux/shop';
import { userGet, shopGet } from "../lib/ec2-api-lib";
import { debug } from "../settings";
import { useInputFields } from "../lib/hooksLib";
import {
  FormInput,
  Page,
  Button,
  UnderlineButton,
  CustomModal,
  CardContainer,
  useStyles
} from "../components";
import { parseShopInfo } from "../lib-pos";

const str = {
  failedLogin: "로그인에 실패했습니다. ",
  failedGetUser: "정보를 가져오는데 실패했습니다. "
}

export default function Login(props) {
  // const { isMobileDevice } = useSelector((state) => state.platform);
  const [isVisibleModal, setVisibleModal] = useState(false);
  const [strModalTitle, setModalTitle] = useState('');

  const otherProps = { setVisibleModal, setModalTitle };

  return (
    <Page>
      <CardContainer
        variant="sm"
        padding="40px 24px">
        <LoginForm {...props} {...otherProps} />
        <FindIdPw  {...props} {...otherProps} />
        <GoSignup  {...props} />
      </CardContainer>
      <CustomModal title={strModalTitle} visible={isVisibleModal} onClickOk={() => setVisibleModal(false)} />
    </Page>
  );
}

function LoginForm(props) {
  const { setModalTitle, setVisibleModal } = props;
  const { gStyle } = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useInputFields({
    username: "",
    password: "",
  });
  const dispatch = useDispatch();

  const onsubmit = async (e) => {
    try {
      setIsLoading(true);
      let user = await Auth.signIn(
        fields.username,
        fields.password
      );
      if (debug) console.log("user: ", user);

      let res = await userGet({ username: fields.username });
      if (res.err) throw new Error(str.failedGetUser + res.err.message);
      if (debug) console.log("res UserGet:", res);
      dispatch(setUser({ user: res.result.Item }));

      // 
      // store home 으로 이동할 때 App 초기화가 이루어지지 않기 때문에 추가함. shop redux 정보 추가
      // 
      res = await shopGet({ shopId: res.result.Item.id });
      if (debug) console.log("shop res", res);
      if (res.err) {
        alert("가맹점 정보가 아직 등록되어 있지 않습니다.");
      } else {
        const sInfo = parseShopInfo({ shopInfo: res.result.Item })
        if (debug) console.log("sInfo", sInfo)
        dispatch(resetSeletectedLang())
        dispatch(setShopInfo({ shopInfo: sInfo }));
      }

      setIsLoading(false);
      // props.history.push(`/store`);
      props.history.push(`/`);
    } catch (e) {
      if (debug) console.log("Error", e.message);
      setIsLoading(false)
      setModalTitle(str.failedLogin);
      setVisibleModal(true);
    }
  };

  const myStyle = {
    title: { ...gStyle.title, ...gStyle.bold, textAlign: "center", marginBottom: "20px" },
    inputsWrap: { marginBottom: "20px" }
  }

  return (
    <div>
      <h3 style={myStyle.title}>"오늘" 사장님 앱 로그인</h3>
      <div style={myStyle.inputsWrap}>
        <FormInput
          name="username"
          placeholder="사용자 아이디"
          validated={fields.password.length > 7}
          value={fields.username}
          onChange={setFields}
          type="text"
        />
        <FormInput
          name="password"
          placeholder="비밀번호"
          validated={fields.password.length > 7}
          value={fields.password}
          onChange={setFields}
          inputType="password"
          type="password"
        />
        <Button
          size="sm"
          variant="primary"
          width="100%"
          title="로그인" onClick={onsubmit} isLoading={isLoading} />
      </div>
    </div>
  );
}

function FindIdPw(props) {
  return (
    <div style={styles.btnWrap}>
      <UnderlineButton title="아이디 찾기" onClick={() => props.history.push('/login/find-id')} />
      <UnderlineButton title="비밀번호 찾기" onClick={() => props.history.push('/login/set-password')} />
    </div>
  );
}

function GoSignup(props) {
  return (
    <div style={styles.btnWrap}>
      <p style={{ fontFamily: "NanumSquareR", fontSize: 14, margin: 0 }}>오늘 입점하러 가기.</p>
      <Button
        variant="outline-primary" title="입점하기"
        style={{ fontSize: "14px", borderRadius: 15, height: 25 }}
        onClick={() => props.history.push('/signup')}
      />
    </div>
  );
}

const styles = {
  btnWrap: {
    marginBottom: 15,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
}