import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { FormInput } from "./FormInputs";
// import { Button } from "./Buttons";
import { useStyles } from "./ui";
import { useSelector } from "react-redux";
import { kiloComma, getTnUrl, sortObjArray2 } from "../lib/utils";
import { TriAngleD, TriAngleU } from "./Icons";
import { debug } from "../settings";

/**
 * 
 * @param {*} prop.tableHeader
 * @param {*} prop.rowItems
 * @param {*} prop.itemList
 * @param {*} prop.onClickItem
 * @param {*} prop.onChangeChecked
 * @param {*} prop.onChageItem
 * @param {*} prop.sort
 * @param {*} prop.variant
 * @returns 
 */
export function CustomTable2(props) {
  const {

    /** @header_type      text(default) textArray, number, checkbox, input, html */
    /** @header_sorting   bolean - 해당 항목의 sorting 여부, sort 함수가 존재해야 함. */
    /** @header_selectKey bolean - 해당 항목을 클릭하면 onClickItem 실행 */
    /** @header_selected  bolean - 해당 항목을 클릭하면 internal onChangeSelected 실행 */
    tableHeader,
    rowItems, setRowItems,
    onClickItem,
    onChangeChecked,  // type === checkbox
    onChangeItem,     // type === input
    sort,             // deprecated type === sorting
    variant           // ui theme
  } = props;

  const { isMobileDevice } = useSelector((state) => state.platform);
  const { gStyle, colors } = useStyles()
  const [tHeader, setTHeader] = useState([])

  useEffect(() => {
    const hasSelectedAttr = _headerHasSelectedAttr(tableHeader)
    const _items = rowItems.map(item => {
      if (hasSelectedAttr) return { ...item, selected: false }
      else return item
    })
    setRowItems(_items)

    const _tHeader = tableHeader.map(h => {
      return { ...h, sortDown: true }
    })

    // console.log(_tHeader)
    setTHeader(_tHeader)
  }, [tableHeader])

  const onClickSort = (headerItem) => {
    const updateHeader = tHeader.map(h => {
      if (h.name === headerItem.name) {
        onSort(headerItem.name, headerItem.sortDown ? "down" : "up")
        return { ...h, sortDown: !headerItem.sortDown }
      } else {
        return h
      }
    })
    setTHeader(updateHeader)
  }

  const onSort = (name, direction) => {
    const sortedItems = sortObjArray2({ arr: rowItems, key: name, type: direction })
    setRowItems(sortedItems)
  }

  const onChangeSelected = (selItem) => {

    const cbIdx = tHeader.findIndex(h => h.name === "selected")
    const keyName = tHeader[cbIdx].keyName

    const idx = rowItems.findIndex(preItem => preItem[keyName] === selItem[keyName])
    const newItems = rowItems.map((item, i) => {
      if (i === idx) return { ...selItem, selected: !selItem.selected }
      return { ...item, selected: false }
    })
    setRowItems(newItems)
  }

  const _headerHasSelectedAttr = (th) => {
    const selectedAttrs = th.map(t => t.name === "selected")
    if (selectedAttrs.length > 0) return true
    else return false
  }

  const thStyle = { ...gStyle.text, color: colors.light, backgroundColor: "transparent" }
  const tdStyle = { ...gStyle.text }

  let headBg = colors.dark
  if (variant) headBg = colors[variant]

  if (isMobileDevice) {
    thStyle.fontSize = "0.75rem"
    tdStyle.fontSize = "0.75rem"
  }

  const styles = {
    wrap: {
      width: "100%",
      overflow: "auto",
      borderRadius: 5
    },
    head: {
      color: '#fff',
      // backgroundColor: colors.warning
      backgroundColor: headBg
    },
    td: { paddingLeft: 15 },
    thTxt: { ...thStyle, padding: "2px 15px" },
    thNum: { ...thStyle, padding: "2px 15px 2px", textAlign: 'right' },
    text: { ...tdStyle, padding: "2px 15px" },
    num: { ...tdStyle, padding: "2px 15px 2px", textAlign: 'right' },
    sortIcon: { color: "#fff", marginLeft: 5 }
  }

  return (
    <div style={styles.wrap}>
      <Table striped>
        <thead style={styles.head}>
          <tr>
            {
              tHeader.map((h) => {
                return (
                  <th
                    key={h.name}
                    style={h.type === 'number' ? styles.thNum : styles.thTxt}
                  >
                    <div style={{
                      display: "flex", alignItems: "center",
                      justifyContent: h.type === 'number' ? "flex-end" : "flex-start"
                    }}>
                      {h.displayName}
                      {(h.sorting && h.sortDown) ? <TriAngleD style={styles.sortIcon} onClick={() => onClickSort(h)} /> : null}
                      {(h.sorting && !h.sortDown) ? <TriAngleU style={styles.sortIcon} onClick={() => onClickSort(h)} /> : null}
                    </div>
                  </th>
                )
              })
            }
          </tr>
        </thead>
        {rowItems.length > 0 &&
          <tbody>
            {
              rowItems.map((obj, i) => {
                return (
                  <tr key={"key" + i} style={styles.tr}>
                    {
                      tableHeader.map((header, j) => {

                        /** timestamp 이면 타입 스트링으로 변경 */
                        let value = (header.name === "lastModified" || header.name === "created")
                          ? new Date(obj[header.name]).toLocaleDateString()
                          : (header.name === "time")
                            ? new Date(obj[header.name]).toLocaleString()
                            : obj[header.name]

                        /** textArray 이면 합치기 */
                        if (header.type === "textArray") {
                          value = obj[header.name].join(", ")
                        }

                        /** number 이면 콤마 추가 */
                        if (header.type === 'number') {
                          value = value !== undefined ? kiloComma(parseInt(value)) : "-"
                        }

                        if ((typeof value === "boolean") || header.type === "boolean") {
                          value = value ? "Yes" : "-";
                        }

                        if (header.type === "checkbox") {
                          // const checkboxProps = { styles, checked: obj[header.name] }
                          return (
                            <TdCellCheckbox
                              key={header.name + j}
                              onChange={(e) => (header.name === "selected") ? onChangeSelected(obj) : onChangeChecked(obj)}
                              styles={styles}
                              checked={obj[header.name]}
                            // onChange={(e) => onChangeChecked(obj)}
                            // {...checkboxProps}
                            />
                          )
                        } else if (header.type === "input") {
                          const inputProps = { styles, name: header.name, value: obj[header.name] }
                          return (
                            <TdCellInput
                              key={header.name + j}
                              onChange={(e) => onChangeItem(header.name, e.target.value, obj)}
                              {...inputProps}
                            />
                          )
                        } else if (header.type === "html") {
                          return <TdCellHtml key={header.name + j} styles={styles} value={value} />
                        } else if (header.type === "image") {
                          return <TdCellImage key={header.name + j} styles={styles} value={value} />
                        } else {
                          if (typeof value === "string" && value.length > 50) {
                            value = value.substring(0, 50) + "...";
                          }
                          const newProps = { header, styles, colors, onClickItem, obj, value }
                          return <TdCell key={header.name + j} {...newProps} />
                        }
                      })
                    }
                  </tr>
                )
              })
            }
          </tbody>
        }
      </Table>
      {rowItems.length === 0 && <p style={{ ...gStyle.subtitle, textAlign: "center" }}>No Item Found</p>}
    </div>
  )
}

function TdCellCheckbox({ styles, onChange, checked }) {
  return (
    <td style={styles.td}>
      <FormInput
        style={{ margin: 1 }}
        // checkboxStyle={{ checkbox: { fontSize: "1.0rem" } }}
        checkboxStyle={{ checkbox: { fontSize: "1.2rem" } }}
        name="checked"
        checked={checked}
        onChange={onChange}
        inputType="checkbox"
      />
    </td>
  )
}

function TdCellInput({ styles, name, value, onChange }) {
  const { isMobileDevice } = useSelector((state) => state.platform);
  const { gStyle, colors } = useStyles()

  const [mouseDown, setMouseDown] = useState(false)

  return (
    <td style={{ ...styles.td, padding: 0, width: "150px" }}>
      <input
        style={{
          ...gStyle.text,
          width: "100%",
          border: "none",
          border: "1px solid #ddd",
          padding: "0px 5px",
          margin: 0, marginTop: 2,
          backgroundColor: "transparent",
          fontSize: isMobileDevice ? "0.75rem" : "1.0rem"
        }}
        name={name}
        onChange={onChange}
        value={value}
        onMouseDown={() => setMouseDown(true)}
        onMouseLeave={() => setMouseDown(false)}
      />
      {/* {value} */}
    </td>
  )
}

function TdCellHtml({ styles, value }) {

  const createMarkup = (html) => { return { __html: html }; }

  return (
    <td style={styles.text}>
      <div dangerouslySetInnerHTML={createMarkup(value)} />
    </td>
  )
}

function TdCell({ header, styles, colors, onClickItem, obj, value }) {
  const [mouseOverKey, setMouseOverKey] = useState(false)
  let valueStyle = header.type === "number" ? styles.num : styles.text

  // 
  // tableHeader 항목에 key가 있을 경우에만 해당
  // 
  if (header.selectKey) {
    valueStyle = {
      ...valueStyle,
      textDecoration: "underline",
      cursor: onClickItem ? "pointer" : "auto",
      color: mouseOverKey ? colors.dark + "55" : colors.dark
    }
  }
  return (
    <td
      style={valueStyle}
      onClick={() => {
        if (header.selectKey && onClickItem) onClickItem(obj)
      }}
      onMouseOver={() => setMouseOverKey(true)}
      onMouseLeave={() => setMouseOverKey(false)}
    >{value}</td>
  )
}

function TdCellImage({ styles, value }) {
  const imgStyle = {
    // width: 32, 
    height: 32,
    borderRadius: 5
  }
  return (
    <td style={{ ...styles.td, padding: 0, width: "100px" }}>
      {value &&
        <div style={{ padding: "2px 15px" }}>
          <img src={getTnUrl(value)} style={imgStyle} alt="item pic." />
        </div>
      }
    </td>
  )
}
