import { isJson } from "../lib/utils"

export function getGroupName({ shopInfo, group, usePosMenuFirst, selectedLanguage }) {
  if(shopInfo.posName==="DUMMY_POS") return group.groupName

  let groupName = group.displayName

  if(usePosMenuFirst) {
    if(group.posNameLang) groupName = group.posNameLang[selectedLanguage] || group.name || group.groupName
    else groupName = group.name || group.groupName
  } else {
    if(group.nameLang) groupName = group.nameLang[selectedLanguage] || group.displayName
  }
  
  return groupName
}

export function getItemName({ shopInfo, item, usePosMenuFirst, selectedLanguage }) {

  if(shopInfo.posName==="DUMMY_POS") return item.itemName

  const nameLang = isJson(item.nameLang) ? JSON.parse(item.nameLang) : {}
  const posNameLang = isJson(item.posNameLang) ? JSON.parse(item.posNameLang) : {}
  
  let name = item.itemName2

  if(usePosMenuFirst) {
    // 
    // 언어별 pos 명칭이 없으면 name 사용
    // 
    if(posNameLang) name = posNameLang[selectedLanguage] || item.itemName
  } else {
    // 
    // 언어별 명칭이 없으면 displayName 사용
    // 
    if(nameLang) name = nameLang[selectedLanguage] || item.itemName2
  }

  return name
}

export function getItemDescription({ item, usePosMenuFirst, selectedLanguage }) {

  const descLang = isJson(item.descriptionLang) ? JSON.parse(item.descriptionLang) : {}
  const posDescLang = isJson(item.posDescriptionLang) ? JSON.parse(item.posDescriptionLang) : {}
  
  let desc = item.description

  if(usePosMenuFirst) {
    if(posDescLang) desc = posDescLang[selectedLanguage] || item.description
  } else {
    if(descLang) desc = descLang[selectedLanguage] || item.description
  }

  return desc
}