import React, { useEffect, useState } from "react";
import { CardContainer } from "../../components";
import lzString from "lz-string"

export default function LzStringTest(props) {

  const [comp, setComp] = useState("")
  const [decomp, setDecomp] = useState("")
  const [obj, setObj] = useState({})

  useEffect(() => {
    const str = JSON.stringify({name: "kebin"})
    const _comp = lzString.compressToUTF16(str)
    setComp(_comp)

    const _decomp = lzString.decompressFromUTF16(_comp)
    setDecomp(_decomp)

    setObj(JSON.parse(_decomp))
  }, [])

  return (
    <CardContainer title="String Compression" padding="40px" variant="lg">
      <p>Original String: {decomp}</p>
      <p>Compressed Data: {comp}</p>
      <p>Decompressed str: {decomp}</p>

      {Object.keys(obj).length > 0 &&
        Object.keys(obj).map((n, i)=>{
          return(
            <div style={{display: "flex", justifyContent: "space-between", width: "300px"}}>
              <p>{n}</p>
              <p>{obj[n]}</p>
            </div>
          )
        })
      }
      
    </CardContainer>
  )
}

