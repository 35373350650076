import React, { useState, useEffect } from 'react'
import { RadioGroup, Radio, Checkbox } from './compos'
import { getPriceStr } from '../../../../lib/utils'

export function ItemOptionInfo(props) {
  const { selectedOptions, setSelectedOptions } = props

  // console.table(selectedOptions)

  const onOptionChange = ({ optionClassCode, optionItems }) => {
    // console.log('optionItems Selected')
    // console.table(optionItems)

    setSelectedOptions((preSelOptions) => {
      const newList = preSelOptions.map((cls) => {
        if (cls.optionClassCode === optionClassCode)
          return { ...cls, optionItems }
        else return cls
      })
      return newList
    })
  }

  const styles = {
    wrap: {
      padding: 10,
      // backgroundColor: "yellow",
      // minHeight: "100px"
      // flex: 1,
      // overflowY: 'auto',
    },
    itemWrap: { marginBottom: 10 },
    text: { margin: '0px 0px 5px' },
  }

  return (
    <div style={styles.wrap}>
      {selectedOptions.map((cls, i) => {
        return (
          <div key={i} style={styles.itemWrap}>
            <p style={styles.text}>
              {cls.optionClassName} {cls.required ? ' - 필수' : ''}
            </p>
            {cls.optionItemList && cls.required && (
              <RadioGroupComponent
                itemCode={cls.itemCode} /** @deprecated */
                choiceMenuList={cls.optionItemList} /** @deprecated */
                optionClassCode={cls.optionClassCode}
                optionItemList={cls.optionItemList}
                onChange={onOptionChange}
              />
            )}
            {cls.optionItemList && !cls.required && (
              <CheckboxComponent
                itemCode={cls.itemCode} /** @deprecated */
                choiceMenuList={cls.optionItemList} /** @deprecated */
                optionClassCode={cls.optionClassCode}
                optionItemList={cls.optionItemList}
                onChange={onOptionChange}
              />
            )}
          </div>
        )
      })}
    </div>
  )
}

/**
 * @Unionpos Options select Radio Group
 *
 */
function RadioGroupComponent(props) {
  const { optionClassCode, onChange, optionItemList } = props
  const [value, setValue] = useState('')

  const onChangeValue = (val) => {
    setValue(val)
    const idx = optionItemList.findIndex((o) => o.optionItemCode === val)
    const optionItems = []
    // optionItems.push(getChoiceItemObj(optionItemList[idx]))
    optionItems.push(optionItemList[idx])

    onChange({ optionClassCode, optionItems })
  }

  const styles = {
    text: { margin: '0px 0px 5px' },
  }

  return (
    <RadioGroup value={value} onChange={onChangeValue}>
      {optionItemList.map((optItem, i) => {
        return (
          <Radio key={i} value={optItem.optionItemCode}>
            <p style={styles.text}>
              {`${optItem.optionItemName} +${getPriceStr(optItem.optPrice)}원`}
            </p>
          </Radio>
        )
      })}
    </RadioGroup>
  )
}

function CheckboxComponent(props) {
  const { optionClassCode, optionItemList, onChange } = props

  const [optItemList, setOptItemList] = useState([])

  useEffect(() => {
    const optList = optionItemList.map((c) => {
      return { ...c, checked: false }
    })
    // console.table(optList)
    setOptItemList(optList)
  }, [])

  const onChangeChecked = (optItem) => {
    const newOptItemList = optItemList.map((o) => {
      if (o.optionItemCode !== optItem.optionItemCode) return o
      return { ...o, checked: !o.checked }
    })
    setOptItemList(newOptItemList)
    const optionItems = []
    newOptItemList.map((optItem) => {
      if (optItem.checked) {
        // console.table([optItem])
        // optionItems.push(getChoiceItemObj(optItem))
        optionItems.push(optItem)
      }
      return null
    })
    onChange({ optionClassCode, optionItems })
  }

  const styles = {
    text: { margin: '0px 0px 5px' },
  }
  
  return (
    <div>
      {optItemList.map((optItem, i) => {
        return (
          <Checkbox
            key={i}
            checked={optItem.checked}
            onChange={() => onChangeChecked(optItem)}
          >
            {/* <p style={styles.text}>{optItem.optionItemName}</p>
            <p style={styles.text}>{`+${getPriceStr(optItem.optPrice)}원`}</p> */}
            <p style={styles.text}>
              {`${optItem.optionItemName} +${getPriceStr(optItem.optPrice)}원`}
            </p>
          </Checkbox>
        )
      })}
    </div>
  )
}

