import React from "react";
import { Page, useStyles } from "../../../components";
import { useSelector } from "react-redux";
import { OrderOnlySection } from "./OrderOnlySection";
import { OrderPrepaidSection } from "./OrderPrepaidSection";
import { prePayPosList } from "../../../settings/appSetting";

export default function OrderHistory(props) {
  const { shopInfo } = useSelector(state => state.shop);
  const { itemOptions3, itemList3 } = useSelector(state => state.items)
  const { gStyle, colors } = useStyles()

  const pageProps = { ...props, shopInfo, itemList3, itemOptions3, gStyle, colors }

  return (
    <Page>
      {
        prePayPosList.includes(shopInfo.posName) && (shopInfo.payOptions.isPayable) &&
        <OrderPrepaidSection {...pageProps} />
      }
      <br />
      <OrderOnlySection {...pageProps} />
    </Page>
  );
}
