import React, { useEffect, useState } from "react";
import { Page, Section, CardContainer } from "../../components";
// import qs from "qs";
import { postGet } from "../../lib/ec2-api-cms-lib";
import { LoaderFullPage, colors } from "../../components";
import { debug } from "../../settings";
import "./PostView.css"

const _post = {
  cat: "ONL Guide",
  id: "8c1fc8144827538aa865" // "오늘" 사장님앱 사용 설명서
}

export default function PostView(props) {
  // const [title, setTitle] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const [post, setPost] = useState({});
  const [published, setPublished] = useState(false)

  useEffect(() => {
    onload();
  }, [])

  const onload = async () => {
    try {
      setIsLoading(true);
      let res = await postGet({ category: _post.cat, id: _post.id });
      if(debug) console.log('res', res)
      if (res.err) throw Error(res.err.message)
      const p = res.result.Item
      setPost(p)
      setPublished(p.publish)
    } catch (e) {
      alert(e.message);
    } finally {
      setIsLoading(false)
    }
  }

  const styles = {
    lines: { marginBottom: 5, color: '#fff' },
    header: {
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      color: post.image ? "white" : colors.dark,
      padding: "30px 25px",
      textAalign: "center",
    },
  }

  if (post.image) styles.header["backgroundImage"] = `url(${post.image})`
  else styles.header["backgroundColor"] = colors.secondary

  return (
    <Page title={post.title || "..."}>

      {(!isLoading && published) &&
        <CardContainer>
          <div style={styles.header}>
            <p style={styles.lines}>작성 일자 : {new Date(post.lastModified).toLocaleDateString()}</p>
            <p style={styles.lines}>게시 중 &nbsp;&nbsp;&nbsp;: {post.publish ? "Yes" : "No"}</p>
          </div>

          <div dangerouslySetInnerHTML={createMarkup(post.htmlContent)} className="onl-post" />
        </CardContainer>
      }

      {(!isLoading && !published) &&
        <div className="onl-post">
          <p style={{fontSize: "32px", textAlign: "center", paddingTop: "20%"}}>준비 중입니다...</p>
        </div>
      }

      {isLoading && <LoaderFullPage />}
    </Page>
  )
}

function createMarkup(html) {
  return { __html: html };
}
