import React, { useContext, createContext } from "react";
import { getPriceStr } from '../../../../lib/utils'
import Hot from "../../../../assets/images/hot.png"
import New from "../../../../assets/images/new.png"
import Best from "../../../../assets/images/best2.png"
import { useStyles } from '../../../../components'
import { Plus, Minus } from '../../../../components/Icons'

export function Text(props) {
  const { value, isBold, isHighLight, isTitle, isSubtitle, style } = props
  const { gStyle } = useStyles()

  let myStyle = { ...gStyle.text }
  if (isTitle) myStyle = { ...myStyle, ...gStyle.title }
  if (isSubtitle) myStyle = { ...myStyle, ...gStyle.subtitle }
  if (isBold) myStyle = { ...myStyle, ...gStyle.bold }
  if (isHighLight) myStyle = { ...myStyle, ...gStyle.highLight }
  if (style) myStyle = { ...myStyle, ...style }

  return <p style={myStyle}>{value}</p>
}

export function Price({ price, lang = { langName: "한" }, size, width, style }) {
  const { gStyle } = useStyles()
  const textStyle = size === "lg" ? gStyle.subtitle : gStyle.text

  const value = lang.langName === '한'
    ? `${getPriceStr(price)} ${"원"}`
    : `${lang.won}${getPriceStr(price)}`

  const _style = {
    ...textStyle,
    ...gStyle.bold,
    width: width || "100%"
  }

  const myStyle = { ..._style, ...style }

  return <p style={myStyle}>{value}</p>
}

export function BestNewHot({ item }) {
  const isSoldout = item.isSoldout
  const styles = {
    item: { marginRight: 5, width: '30px' },
  }

  return (
    <div>
      {!isSoldout && (
        <>
          {item.isBest && <img src={Best} style={styles.item} alt="best" />}
          {item.isNew && <img src={New} style={styles.item} alt="new" />}
          {item.isHot && <img src={Hot} style={styles.item} alt="hot" />}
        </>
      )}
    </div>
  )
}

export function ChangeQty({ itemCount, setItemCount, size = "sm" }) {
  const height = size === 'sm' ? 25 : 35
  const width = 35

  const onDecerease = () => {
    if (itemCount < 2) return
    // setItemCount((prev) => prev - 1)
    setItemCount(itemCount-1)
  }

  const onIncrease = () => {
    // setItemCount((prev) => (prev + 1))
    setItemCount(itemCount+1)
  }

  const styles = {
    wrap: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      // width: '100%',
      borderRadius: '8px',
    },
    text: { textAlign: 'center', width, margin: 0 },
    btnBox: {
      width, height,
      border: "1px solid #777",
      borderRadius: 5,
      backgroundColor: "#eee",
      display: "flex", justifyContent: "center", alignItems: "center"
    }
  }

  return (
    <div style={styles.wrap}>
      <div style={styles.btnBox} onClick={onDecerease}>
        <Minus onClick={onDecerease} />
      </div>
      {/* <OutlineButton
        title="-"
        color="white"
        width={width}
        height={height}
        onClick={onDecerease}
        disabled={itemCount < 2}
        /> */}
      {/* <div style={{ padding: '0px 15px' }}>{itemCount}</div> */}
      <p style={styles.text}>{itemCount}</p>
      <div style={styles.btnBox} onClick={onIncrease}>
        <Plus onClick={onIncrease} />
      </div>

      {/* <OutlineButton
        title="+"
        color="white"
        width={width}
        height={height}
        onClick={onIncrease}
        /> */}
    </div>
  )
}

export function Divider({ height, color, margin }) {
  return (
    <div
      style={{
        width: '100%',
        height: height || 2,
        backgroundColor: color || '#eee',
        marginTop: margin || 15,
        marginBottom: margin || 15,
      }}
    />
  )
}

export function BgImage(props) {
  const { image, width, height, children, style } = props;
  return (
    <div
      style={{
        padding: style?.padding,
        margin: style?.margin,
        border: style?.border,
        borderColor: style?.borderColor,
        borderRadius: style?.borderRadius,
        opacity: style?.opacity,
        backgroundImage: `url(${image.split(' ').join('%20')})`,   // 이미지 url에 띄어쓰기가 있을경우 해결.
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: width || '100%',
        height: height,
        top: 0,
      }}
    >{children}</div>
  )
}

export function Checkbox({
  children,
  checked,
  value,
  name,
  defaultChecked,
  disabled,
  onChange
}) {
  const styles = {
    wrap: { display: "flex", justifyContent: "space-between", marginLeft: 5 }
  }
  return (
    <label style={styles.wrap}>
      <input
        type="checkbox"
        name={name}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
      {children}
    </label>
  );
}

const RadioContext = createContext({})

export function Radio({ children, value, name, defaultChecked, disabled }) {
  const context = useContext(RadioContext)
  // console.log('radio context', context)
  const styles = {
    wrap: { 
      display: "flex", 
      justifyContent: "space-between", 
      marginLeft: 5,
      // marginBottom: 15
    }
  }
  return (
    <label style={styles.wrap}>
      <input
        type="radio"
        value={value}
        name={name}
        defaultChecked={defaultChecked}
        disabled={disabled || context.disabled}
        // checked={context.value !== "" ? value === context.value : ""}
        checked={value === context.value}
        onChange={(e) => context.onChange && context.onChange(e.target.value)}
      />
      {children}
    </label>
  );
}

export function RadioGroup({ label, children, ...rest }) {
  return (
    <RadioContext.Provider 
      value={rest}
      // style={{marginBottom: 15}}
    >
      {children}
    </RadioContext.Provider>
  );
}