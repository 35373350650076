import { useSelector } from "react-redux";

const _colors = {
  primary: "#05b8a0",
  info: "#4ec6e1",
  secondary: "#6c757d",
  warning: '#ff4400',
  danger: "#c62127",
  light: "#efefef",
  dark: "#2b2b2b",
}


export const colors = {
  ..._colors,
  readOnly: _colors.light
}

/**
 * @v0_4_8부터_삭제 중국어 지원때문에 포기함
 */
// const fontFamily = {
//   normal: "nanumSquare-light",
//   // normal: "nanumSquare-regular",
//   regular: "nanumSquare-regular",
//   bold: "nanumSquare-bold",
//   extraBold: "nanumSquare-extra-bold",
// }

const textBasic = {
  lineHeight: "1.44",
  fontWeight: "300",
  fontStretch: "normal",
  letterSpacing: "normal",
  margin: "0px",
  // fontFamily: fontFamily.normal,
  color: colors.dark,
}

export const gStyle = {
  title: {
    fontSize: "1.6rem",
    fontWeight: "600"
  },
  subtitle: {
    ...textBasic,
    fontSize: "1.2rem",
    fontWeight: "500"
  },
  text: {
    ...textBasic,
    fontSize: "1rem",
  },
  textBold: {
    ...textBasic,
    fontSize: "1rem",
    fontWeight: "500"
  },
  bold: {
    fontWeight: "600",
  },
  highLight: {
    fontWeight: "600",
    color: colors.primary
  }
}

/**
 * 일단 rem 기준으로 gStyle과 동일하게 함.
 * 나중에 필요시 수정
 */
// export const gStyleMobile = {
//   title: { ...gStyle.title, fontSize: "1.6rem" },
//   subtitle: { ...gStyle.subtitle, fontSize: "1.2rem" },
//   text: { ...gStyle.text, fontSize: "1rem" },
//   textBold: { ...gStyle.textBold, fontSize: "1rem", },
//   bold: { ...gStyle.bold },
//   highLight: { ...gStyle.highLight }
// }

export const gStyleMobile = {
  title: { ...gStyle.title, fontSize: "1.4rem" },
  subtitle: { ...gStyle.subtitle, fontSize: "1.0rem" },
  text: { ...gStyle.text, fontSize: "0.8rem" },
  textBold: { ...gStyle.textBold, fontSize: "0.8rem", },
  bold: { ...gStyle.bold },
  highLight: { ...gStyle.highLight }
}

export function useStyles() {
  const { isMobileDevice } = useSelector((state) => state.platform);
  if (isMobileDevice)  return { gStyle: gStyleMobile, colors};
  // const { isMobilePhone } = useSelector((state) => state.platform);
  // if (isMobilePhone)  return { gStyle: gStyleMobile, colors};
  else return { gStyle, colors};
}