import { unionposProxy2 } from "../lib/ec2-api-lib";
import { posList, notSupported } from "./posList";

export async function getPosNoList({ shopInfo, ...rest }) {
  if (!posList.includes(shopInfo.posName)) return { err: { message: "INVALID POSNAME" } };
  if (shopInfo.posName !== "UNIONPOS") return notSupported()
  return getPosNoListUnionpos({ ...rest })
}

async function getPosNoListUnionpos({ shopInfo, apiName, body }) {
  try {
    const res = await unionposProxy2({ shopInfo, apiName, body })
    if (res.err) throw Error(res.err.message)
    if (res.result.CODE.includes("E")) throw Error(res.result.MSG)
    return res
  } catch (e) {
    console.log(e)
    return { err: { message: e.message } }
  }
}
