import React, { useEffect, useState } from "react";
import { Page, Alert, CardContainer } from "../../components";
import { shopCreate, shopUpdate } from "../../lib/ec2-api-lib";
import { db } from "../../settings";
import PostFormSection from "../../components/PostFormSection";
import { useSelector, useDispatch } from "react-redux";
import { setShopInfo } from '../../redux/shop';
import { debug } from "../../settings";

const str = {
  alertSuccess: "성공적으로 저장하였습니다.",
  alertFail: "정보를 저장하는 데 실패했습니다.",
  titleCreate: "포스 정보 등록",
}

export default function StoreRegisterPosname(props) {
  const { shopInfo } = useSelector(state => state.shop);
  const { user } = useSelector(state => state.user);

  const isEdit = props.location?.isEdit;
  if (debug) console.log("isEdit", isEdit);

  const dispatch = useDispatch();

  const _inputFields = db.shopPosname.inputFields;

  const inputFields = []

  _inputFields.map(f=>{
    if(f.name==="posName"){
      inputFields.push({
        ...f,
        defaultValue: shopInfo.posName || f.selectList[0]
      })
    }
    return null
  })

  /** 
   * @POS_NAME_변경을_하려면 
   *  자세히 보기 > 포스소프트웨어 텍스트를 클릭 할 것
   */
  // 
  // 초기 등록시, shopInfo 가 empty 이므로 user.id 를 사용해야함.
  // 
  const shopId = user.id;
  const [isLoading, setIsLoading] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertMsgFail, setAlertMsgFail] = useState("");

  const [prePosName, setPrePosName] = useState("")
  const [changePos, setChangePos] = useState(false)

  useEffect(() => {
    if (shopInfo.posName !== undefined) {
      // props.history.push({ pathname: `/store/register/create`, isEdit: false });
      setPrePosName(shopInfo.posName)
      setChangePos(true)
    }
  }, []);

  const onSubmit = async (fields) => {
    try {
      setIsLoading(true);
      let res = {}
      if (changePos) {
        if (prePosName === fields.posName) {
          dispatch(setShopInfo({ shopInfo: { ...shopInfo, posName: fields.posName } }));
          props.history.push({
            pathname: `/store/register/create`,
            isEdit: false,
            changePos
          })
        }
        res = await shopUpdate({ shopId, body: { posName: fields.posName } });
      }
      else res = await shopCreate({ shopId, body: { posName: fields.posName } });
      if (res.err) throw new Error(str.alertFail + "\n" + res.err.message);

      let newShopInfo = { ...res.result.Item };
      dispatch(setShopInfo({ shopInfo: { ...shopInfo, posName: newShopInfo.posName } }));
      setIsLoading(false);
      setAlertMsg(str.alertSuccess);
    } catch (e) {
      setIsLoading(false);
      setAlertMsgFail(e.message);
    }
  }

  return (
    <Page>
      <CardContainer
        variant="md"
        padding="24px 20px"
        title={str.titleCreate}
        onClickBack={() => props.history.goBack()}
      >

        <PostFormSection
          {...props}
          // isEdit={false}
          isEdit={isEdit}
          editItems={shopInfo}
          inputFields={inputFields}
          onSubmit={onSubmit}
          onSubmitText="Next"
          isLoading={isLoading}
        />

      </CardContainer>

      <Alert title={alertMsg} visible={alertMsg !== ""}
        onClickOk={() => props.history.push({
          pathname: `/store/register/create`,
          isEdit: false,  // licenseNo check 및 storeCode 변경을 위해 항상 isEdit = false (신규 처럼 보이게 함)
          changePos       // changePos = true 기존 정보를 활용하기 위함 -> StoreRegister 추가 구현 필요
        })}
      />
      <Alert title={alertMsgFail} visible={alertMsgFail !== ""}
        onClickOk={() => setAlertMsgFail("")} />
    </Page>
  )
}
