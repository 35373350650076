import {
  okposItemOptionGet,
  shopItemOptionGet,
  onlItemOptionGet,
} from "../lib/ec2-api-lib";

const pos = {
  "UNIONPOS": { getItemOptions: getItemOptionsUnionpos, },
  "OKPOS": { getItemOptions: getItemOptionsOkpos, },
  "DUMMY_POS": { getItemOptions: getItemOptionsUnionpos, },
  "OASIS_POS": { getItemOptions: getItemOptionsUnionpos, },
  "EASY_POS": { getItemOptions: getItemOptionsUnionpos, },
}

const posList = Object.keys(pos)

export async function getItemOptions({ shopInfo }) {
  if (!posList.includes(shopInfo.posName)) return { err: { message: "INVALID POSNAME" } };
  return pos[shopInfo.posName].getItemOptions({ shopInfo })
}

async function getItemOptionsUnionpos({ shopInfo }) {
  const optionName = shopInfo.posName === "UNIONPOS" ? "unionpos" : shopInfo.posName
  const shopId = shopInfo.shopId
  return shopItemOptionGet({ shopId, optionName })
}

async function getItemOptionsOkpos({ shopInfo }) {
  try {
    let res = await okposItemOptionGet({ shopId: shopInfo.shopId })
    if (res.err) throw Error(res.err.message)
    const itemOptions = res.result.Item;

    res = await onlItemOptionGet({
      shopId: shopInfo.shopId,
      posName: shopInfo.posName
    })

    if (res.err) {
      if (res.err.message === "NO ITEM FOUND") {
        itemOptions.optionGroupList = JSON.stringify([])
        itemOptions.optionClassList = JSON.stringify([])
        itemOptions.optionItemList = JSON.stringify([])
      } else {
        throw Error(res.err.message)
      }
    } else {
      itemOptions.optionGroupList = res.result.Item.optionGroupList
      itemOptions.optionClassList = res.result.Item.optionClassList
      itemOptions.optionItemList = res.result.Item.optionItemList
    }

    return { result: { Item: itemOptions } }
  } catch (e) {
    return { err: { message: e.message } }
  }
}
