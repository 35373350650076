import React, { useEffect, useState } from "react";
import { 
  changePassword, 
  sendSms, 
  isExistUsername 
} from "../../lib/ec2-api-lib";
import { CardContainer } from "../../components/CardContainer";
import { useInputFields } from "../../lib/hooksLib";
import {
  FormInput,
  InputWithBtn,
  Page,
  Button,
  Alert,
  useStyles
} from "../../components";
import { createFields, validateInputValue, createInputProps} from "../../components/PostFormSection";
import { useSelector } from "react-redux";
import { db, debug } from '../../settings';

const str = {
  failedUser: "존재하지 않는 아이디입니다. ",
  failedCode: "인증코드 발송에 실패했습니다. ",
  noCode: "인증코드를 받지 않았습니다.",
  noCodeField: "인증코드를 입력하지 않았습니다.",
  failedCheckCode: "인증코드가 다릅니다.",
  sentCode: "인증코드가 전송되었습니다.",
  sucess: "성공적으로 변경되었습니다.",
  failedSetPw: "패스워드 설정에 실패했습니다. 다시 시도해 주세요."
}

export default function SetPassword(props) {

  const { user, isSigned } = useSelector(state => state.user);
  if (debug) console.log('user', isSigned, user);

  const [isLoading, setIsLoading] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [username, setUsername] = useState('');
  const [editItems, setEditItems] = useState({});
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [code, setCode] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (isSigned) {
      setEditItems({
        username: user.username,
        phone_number: user.phone_number
      });
    }
    setIsLoaded(true);
  }, [])

  // 
  // onSubmit of formname verify-phoneno
  // fields = { username, phone_number, code} 
  const onsubmitCheckCode = (fields) => {

    if(code==="") return setAlertMsg(str.noCode);
    if(fields.code==="") return setAlertMsg(str.noCodeField);

    if (debug) console.log("fields", fields, code);
    if (fields.code !== code) {
      return setAlertMsg(str.failedCheckCode);
    } else {
      setUsername(fields.username);
      setPhoneVerified(fields.code === code);
    }
  }

  // 
  // onSubmit of formname set-newpw
  // 
  const onsubmitSetNewPw = async (fields) => {
    try {
      setIsLoading(true);
      let res = await changePassword({ username, password: fields.password });
      if (res.err) throw new Error(str.failedSetPw);
      if (debug) console.log('res', res);

      setAlertMsg(str.sucess);
    } catch (e) {
      setAlertMsg(e.message);
    } finally { setIsLoading(false) }
  }

  return (
    <Page>
      <CardContainer variant="sm" padding="40px 24px">
        { (!phoneVerified && isLoaded)
          ?
          <Forms
            key="verify-phoneno"
            {...props}
            formname="verify-phoneno"
            inputFields={db.findPassword.inputFields}
            editItems={editItems}
            setPhoneVerified={setPhoneVerified}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setCode={setCode}
            onSubmit={onsubmitCheckCode}
          />
          :
          <Forms
            key="set-newpw"
            {...props}
            formname="set-newpw"
            inputFields={db.findPassword.inputFieldsPw}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            onSubmit={onsubmitSetNewPw}
          />
        }
      </CardContainer>
      <Alert title={alertMsg} visible={alertMsg !== ""} onClickOk={() => props.history.goBack()} />
    </Page>
  );
}

function Forms(props) {
  const { inputFields, onSubmit, formname, setCode, isLoading, setIsLoading, editItems } = props;
  const { gStyle } = useStyles();

  const [fields, setFields] = useInputFields(
    createFields({ inputFields, editItems: editItems })
  );
  const [alertMsg, setAlertMsg] = useState('');
  const [disableCodeSend, setDisableCodeSend] = useState(false);

  const onClickSendCode = async () => {
    try {
      setIsLoading(true);
      setDisableCodeSend(true);
      let res;
      res = await isExistUsername({ username: fields.username });
      if (debug) console.log('res', res)
      if (res.err) throw new Error(str.failedUser + res.err.message);
      if (!res.result.exist) throw new Error(str.failedUser);

      // res = await codeGet({ phone_number: fields.phone_number });
      res = await sendSms({ phone_number: fields.phone_number });
      if (res.err) throw new Error(str.failedCode + res.err.message);
      if (debug) console.log('res', res);

      setCode(res.result.code);
      setAlertMsg(str.sentCode);
    } catch (e) {
      setAlertMsg(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  const validate = () => {
    let val = true;
    inputFields.map((item) => (val = val && validateInputValue(item, fields[item.name])));
    if (formname === "set-newpw") val = val && (fields.password === fields.confirmPassword);
    return val;
  };

  const styles = {
    title: { ...gStyle.title, ...gStyle.bold, textAlign: "center" }
  }

  return (
    <div>
      <h2 style={styles.title}>비밀번호 변경</h2>
      {inputFields.map((item, index) => {
        const myProps = createInputProps({item, index, fields, setFields});

        if (item.name === "phone_number") {
          myProps.btnText = "인증번호 전송";
          myProps.onClick = onClickSendCode;
          myProps.disabled = disableCodeSend;
          return <InputWithBtn key={index} {...myProps} />
        }
        return <FormInput key={index} {...myProps} />
      })}

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          width="45%"
          size="sm"
          variant="outline-primary"
          onClick={() => props.history.goBack()}
        >취소</Button>

        <Button
          width="45%"
          variant="primary"
          size="sm"
          disabled={!validate()}
          isLoading={isLoading}
          onClick={() => onSubmit(fields)}
        >확인</Button>
      </div>

      <Alert title={alertMsg} visible={alertMsg !== ""} onClickOk={() => setAlertMsg("")} />
    </div>
  );
}

