import { getShortArray, sortObjArray2 } from "../../lib/utils"
import { shopItem_easypos } from "./easypos-menu-db"

export function getTableGroupList(arr) {
  const names = [
    "TABLE_GROUP_CODE",
    "TABLE_GROUP_NAME",
  ]

  const newArr = getShortArray(arr, names)
  return newArr
}

export function getTableList(arr) {
  const names = [
    "TABLE_GROUP_CODE",
    "TABLE_CODE",
    "TABLE_NAME",
    "TABLE_DIS_NO",
  ]

  const newArr = getShortArray(arr, names)
  const newArr2 = newArr.map(obj => {
    return {
      ...obj,
      tableCode: obj["TABLE_CODE"],
      tableNo: parseInt(obj["TABLE_CODE"]),
      tableName: obj["TABLE_NAME"],
    }
  })

  return newArr2
}

export function getTouchClassList(arr) {
  const names = [
    "TOUCH_CLASS_CODE",
    "TOUCH_CLASS_NAME",
    "SEQ",
  ]

  const newArr = getShortArray(arr, names)
  const newArr2 = newArr.map(obj => {
    return {
      ...obj,
      groupCode: obj["TOUCH_CLASS_CODE"],
      groupName: obj["TOUCH_CLASS_NAME"],
    }
  })

  return newArr2
}

export function getShortPosItems(arr) {
  const names = [
    "TOUCH_CLASS_CODE",
    "SEQ",
    "ITEM_CODE",
    "ITEM_NAME",

    "ITEM_PRICE",
    // "VAT_RATE",
    "USE_FLAG",
    // "TAX_FLAG",
    // "PRICE_FLAG",
    "SUB_MENU_TYPE",
    "SERVICE_FLAG",
    "ITEM_TYPE",
    // "CUST_CNT",
    // "ORDER_GROUP_FG",
    // "CATEGORY",
    // "COOK_TIME",
    // "COOK_FIRST_YN",
    // "ATTRIBUTE_YN",
    // "ORIGIN_CODE",
    // "ERP_ITEM_CODE",
    // "WEIGHT_FLAG",
    // "WEIGHT_SCALE",
    // "DELIVERY_YN",
    // "PICKUP_YN",
    // "ADD_CHARGE_YN",
    // "ADD_CHARGE_AMT",
    // "LOCATION_INFO",
    "ITEM_IMG_URL",
    // "ITEM_DESCRIPTION",
    // "ITEM_DESCRIPTION_ENG",
    // "SALE_START_TIME",
    // "SALE_END_TIME",
    // "DAILY_SALE_QTY",
    "KIOSK_SUB_MENU_TYPE",
    "KIOSK_DISPLAY_ICON",
    // "SALE_DAYS",
    // "SOLD_OUT",
    // "PICKUP_DC_AMT",
    // "ETC_ITEM_CODE",
    // "DEPOSIT_YN",
    // "DEPOSIT_AMT",
    // "DEPOSIT_ITEM_YN",
    // "ITEM_DESCRIPTION2",
    // "ITEM_DESCRIPTION_ENG2",
    // "UNABLE_HIDE_YN",
    // "LABEL_PRINT_CNT",
    // "LABEL_PRINT_ITEM_NAME",
    // "TOAST_LIMIT_QTY",
    // "TOAST_MESSAGE",
    // "PRICE_SUPPORT_YN",
    // "ADULT_AUTH_ITEM_YN",
  ]

  const newArr = getShortArray(arr, names)
  return newArr
}

export function mergeTouchKeysAndProducts(touchKeyList, productList) {
  const sortedTouchKeyList = sortObjArray2({ arr: touchKeyList, key: "SEQ" })
  const newTouchKeyList = []
  sortedTouchKeyList.map(t => {
    const idx = productList.findIndex(p => p["ITEM_CODE"] === t["ITEM_CODE"])
    if (idx > -1) {
      newTouchKeyList.push({ ...t, ...productList[idx] })
    }
    return null
  })
  return newTouchKeyList
}

export function createMenuItem({ posItem, idx }) {

  const inputFields = shopItem_easypos.inputFields
  const newItem = {}

  for (let i = 0; i < inputFields.length; i++) {
    let field = inputFields[i]

    // 
    // pos의 기본항목
    // 
    if (field.posName !== undefined) {
      if (posItem[field.posName] !== undefined) {
        newItem[field.name] = posItem[field.posName];
      }
    }

    // 
    // ONL 편집 가능 항목
    // 
    else {
      // itemName2 case
      if (field.defaultValue?.posName !== undefined) {
        newItem[field.name] = posItem[field.defaultValue.posName];
      }

      // sortOrder | image | removed | isSoldout | isNew | isHot | isBest
      else {
        newItem[field.name] = field.defaultValue;
      }
    }
  }

  newItem.sortOrder = idx

  return newItem
}

export function updateMenuItem({ posItem, idx, serverItem }) {
  const inputFields = shopItem_easypos.inputFields
  const newItem = {}

  for (let i = 0; i < inputFields.length; i++) {
    let field = inputFields[i]

    // 
    // pos의 기본항목
    // 
    if (field.posName !== undefined) {
      if (posItem[field.posName] !== undefined) {
        newItem[field.name] = posItem[field.posName];
      }
    }

    // 
    // ONL 편집 가능 항목
    // 
    else {
      newItem[field.name] = serverItem[field.name];
    }
  }

  /** ItemName 변경되면 displayName도 변경됨. */
  if (serverItem["itemName"] !== posItem["ITEM_NAME"]) {
    newItem["itemName2"] = posItem["ITEM_NAME"]
  }
  return newItem
}
