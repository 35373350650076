import React, { useEffect, useState } from "react";
import { CardContainer, useStyles, Button, CustomTable } from "../../../components";
import {
  onlItemOptionGet, onlItemOptionDelete,
  shopItemOptionGet,
  shopItemOptionUpdate
} from "../../../lib/ec2-api-lib";
import { Plus } from "../../../components/Icons";
// import { useLocation } from "react-router";
import { sortObjArray } from "../../../lib/utils";
import { debug } from "../../../settings";

import OnlOptionGroupModal from "./OnlOptionGroupModal2";
import OnlOptionClassModal from "./OnlOptionClassModal2";
import OnlOptionItemModal from "./OnlOptionItemModal";

const onlOptionDeleteDebug = false;

const tableHeaders = {
  groups: [
    { name: "groupCode", displayName: "코드" },
    { name: "name", displayName: "이름" },
    { name: "infoHtml", displayName: "옵션 리스트", type: "html" },
  ],
  classes: [
    { name: "classCode", displayName: "코드" },
    { name: "name", displayName: "이름" },
    { name: "required", displayName: "필수여부" },
    { name: "infoHtml", displayName: "아이템 리스트", type: "html" },
  ],
  items: [
    { name: "itemCode", displayName: "코드" },
    { name: "name", displayName: "이름" },
    { name: "price", displayName: "가격", type: "number" },
    { name: "maxQty", displayName: "제한수량" },
  ],
  checkObj: { name: "checked", displayName: "선택", type: "checkbox" },
  sorting: { name: "sorting", displayName: "순서", type: "sorting" },
}

const tableHeadersDpos = {
  groups: [
    { name: "optionGroupCode", displayName: "코드" },
    { name: "optionGroupName", displayName: "그룹명" },
    { name: "infoHtml", displayName: "옵션클래스 리스트", type: "html" },
  ],
  classes: [
    { name: "optionClassCode", displayName: "코드" },
    { name: "optionClassName", displayName: "클래스명" },
    { name: "required", displayName: "필수여부" },
    { name: "infoHtml", displayName: "아이템 리스트", type: "html" },
  ],
  items: [
    { name: "optionItemCode", displayName: "코드" },
    { name: "optionItemName", displayName: "아이템명" },
    { name: "price", displayName: "가격", type: "number" },
    { name: "maxQty", displayName: "제한수량" },
  ],
  checkObj: { name: "checked", displayName: "선택", type: "checkbox" },
  sorting: { name: "sorting", displayName: "순서", type: "sorting" },
}

const str = {
  noItemFound: "NO ITEM FOUND",
  noItems: "No Items"
}

/** @TODO dpos item 수정 */
/** @TODO dpos item 삭제 */
/** @TODO dpos item 추가 */

export default function OnlOptionMenus(props) {
  const { shopInfo, itemOptions3, onload, setIsLoading } = props

  const { gStyle, colors } = useStyles();
  const title = '"오늘" 옵션메뉴 편집 - ' + shopInfo.posName;

  const [optGroups, setOptGroups] = useState([])
  const [optClsList, setOptClsList] = useState([])
  const [optItemList, setOptItemList] = useState([])

  const [showGrpAdd, setShowGrpAdd] = useState(false)
  const [showGrpEdit, setShowGrpEdit] = useState(false)

  const [showClassAdd, setShowClassAdd] = useState(false)
  const [showClassEdit, setShowClassEdit] = useState(false)

  const [showItemAdd, setShowItemAdd] = useState(false)
  const [showItemEdit, setShowItemEdit] = useState(false)

  const [isNewOption, setIsNewOption] = useState(false)

  // 
  // item, class, group 모두 해당
  // 
  const [selectedItem, setSelectedItem] = useState({})

  const [tHeaders, setTHeaders] = useState({})

  useEffect(() => {
    if (shopInfo.posName === "OKPOS") {
      setTHeaders(tableHeaders)
      setOKpos()
    }
    if (shopInfo.posName === "DUMMY_POS") {
      setTHeaders(tableHeadersDpos)
      setDummypos()
    }
  }, [itemOptions3])

  const setDummypos = async () => {
    try {
      setIsLoading(true)
      let isNew = false;
      let optionGroupList = itemOptions3.optionGroupList || []
      let optionClassList = itemOptions3.optionClassList || []
      let optionItemList = itemOptions3.optionItemList || []

      const grpList = optionGroupList.map(grp => {
        return {
          ...grp,
          infoHtml: _getHtmlDpos(grp.optionClassCodes, optionClassList, "optionClassCode")
        }
      })

      const clsList = optionClassList.map(cls => {
        return {
          ...cls,
          infoHtml: _getHtmlDpos(cls.optionItemCodes, optionItemList, "optionItemCode")
        }
      })

      setOptGroups(grpList)
      setOptClsList(clsList)
      const sorted = sortObjArray({arr: optionItemList, key: "optionItemCode", type:"up"})
      setOptItemList(sorted)
    } catch (e) {
      if (debug) console.log(e)
      alert(e.message)
    } finally {
      setIsLoading(false)
    }
  }

  const setOKpos = async () => {
    try {
      setIsLoading(true)
      let isNew = false;
      let optionGroupList = itemOptions3.optionGroupList || []
      let optionClassList = itemOptions3.optionClassList || []
      let optionItemList = itemOptions3.optionItemList || []

      let res = await onlItemOptionGet({ shopId: shopInfo.shopId, posName: shopInfo.posName })
      if (res.err) {
        if (res.err.message === str.noItemFound) isNew = true
        else throw Error(res.err.message)
      }

      const grpList = optionGroupList.map(grp => {
        return {
          ...grp,
          infoHtml: _getHtml(grp.classList, optionClassList, "classCode")
        }
      })

      const clsList = optionClassList.map(cls => {
        return {
          ...cls,
          infoHtml: _getHtml(cls.itemList, optionItemList, "itemCode")
        }
      })

      setOptGroups(grpList)
      setOptClsList(clsList)
      setOptItemList(sortObjArray({ arr: optionItemList, key: "itemCode" }))
      setIsNewOption(isNew)
    } catch (e) {
      if (debug) console.log(e)
      alert(e.message)
    } finally {
      setIsLoading(false)
    }
  }

  const _getHtml = (codeList, refList, key) => {
    const arr = []
    codeList.map(code => {
      const items = refList.filter(o => o[key] === code)
      if (items.length === 1) arr.push(items[0])
      return null
    })

    let htmlTxt = "<div>"
    arr.map(d => {
      htmlTxt += `<p style="margin: 0px">${d.itemCode || d.classCode} - ${d.name}</p>`
    })

    htmlTxt += "</div>"
    return htmlTxt
  }

  const _getHtmlDpos = (codeList, refList, key) => {
    const arr = []
    codeList.map(code => {
      const items = refList.filter(o => o[key] === code)
      if (items.length === 1) arr.push(items[0])
      return null
    })

    let htmlTxt = "<div>"
    arr.map(d => {
      const names = `${d.optionClassCode || d.optionItemCode} - ${d.optionClassName || d.optionItemName}`
      htmlTxt += `<p style="margin: 0px">${names}</p>`
    })
    htmlTxt += "</div>"
    return htmlTxt
  }

  const modalProps = {
    ...props,
    optGroups, optClsList, optItemList,
    isNewOption,
    onload,
    tableHeaders: tHeaders
  }

  const onClickGrpEdit = (obj) => {
    setSelectedItem(obj)
    setShowGrpEdit(true)
  }

  const onClickClassEdit = (obj) => {
    setSelectedItem(obj)
    setShowClassEdit(true)
  }

  const onClickItemEdit = (obj) => {
    setSelectedItem(obj)
    setShowItemEdit(true)
  }

  const onClickDelete = async () => {
    if (debug) {
      let res = await onlItemOptionDelete({ shopId: shopInfo.shopId, posName: shopInfo.posName })
      console.log('res delete onlOption', res)
      onload()
    } else {
      alert("INVALID ACCESS")
    }
  }

  const styles = {
    subTitleBar: {
      margin: 5,
      display: "flex",
      // justifyContent: "space-between",
      alignItems: "center"
    },
    subTitle: {
      ...gStyle.subtitle
    },
    iconPlus: { ...gStyle.subtitle, color: colors.primary, marginLeft: "20px" },
  }

  return (
    <div>
      {(onlOptionDeleteDebug && debug) &&
        <Button variant="secondary" onClick={onClickDelete} >삭제 - 디버그용</Button>}

      <CardContainer
        title={title}
        onClickBack={() => props.history.goBack()}
      >

        {/* {debug && <><Button variant="secondary" onClick={onClickDelete}>DEBUG only - 옵션 삭제</Button><br /><br /></>} */}

        <div style={styles.subTitleBar}>
          <p style={styles.subTitle}>옵션 그룹 리스트</p>
          <Plus onClick={() => setShowGrpAdd(true)} style={styles.iconPlus} />
        </div>

        {Object.keys(tHeaders).length > 0 &&
          <>
            <CustomTable tableHeader={tHeaders.groups} rowItems={optGroups} onClickItem={onClickGrpEdit} />
            <br />

            <div style={styles.subTitleBar}>
              <p style={styles.subTitle}>옵션 클래스 리스트</p>
              <Plus onClick={() => setShowClassAdd(true)} style={styles.iconPlus} />
            </div>
            <CustomTable tableHeader={tHeaders.classes} rowItems={optClsList} onClickItem={onClickClassEdit} />
            <br />

            <div style={styles.subTitleBar}>
              <p style={styles.subTitle}>옵션 아이템 리스트</p>
              <Plus onClick={() => setShowItemAdd(true)} style={styles.iconPlus} />
            </div>
            <CustomTable tableHeader={tHeaders.items} rowItems={optItemList} onClickItem={onClickItemEdit} />
          </>
        }

      </CardContainer>

      {/** @Modals */}
      <>
        {showGrpAdd &&
          <OnlOptionGroupModal
            {...modalProps}
            visible={showGrpAdd}
            setVisible={setShowGrpAdd}
          />
        }

        {showGrpEdit &&
          <OnlOptionGroupModal
            {...modalProps}
            isEdit={true}
            item={selectedItem}
            visible={showGrpEdit}
            setVisible={setShowGrpEdit}
          />
        }

        {showClassAdd &&
          <OnlOptionClassModal
            {...modalProps}
            visible={showClassAdd}
            setVisible={setShowClassAdd}
          />
        }

        {showClassEdit &&
          <OnlOptionClassModal
            {...modalProps}
            isEdit={true}
            item={selectedItem}
            visible={showClassEdit}
            setVisible={setShowClassEdit}
          />
        }

        {showItemAdd &&
          <OnlOptionItemModal
            {...modalProps}
            visible={showItemAdd}
            setVisible={setShowItemAdd}
          />
        }

        {showItemEdit &&
          <OnlOptionItemModal
            {...modalProps}
            isEdit={true}
            item={selectedItem}
            visible={showItemEdit}
            setVisible={setShowItemEdit}
          />
        }
      </>
    </div>
  )
}
