import React, { useState } from "react";
import { Page, CardContainer, FormInput, FormBox, Button } from "../../../../components";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setShopInfo } from "../../../../redux/shop";
import { shopUpdate } from "../../../../lib/ec2-api-lib";
import { routePaths } from "../../../../navigation/routePaths";
import { debug } from "../../../../settings";

const str = {
  keyExistComment: "보안정보가 이미 존재합니다.",
  failure: "저장에 실패했습니다.",
  success: "성공적으로 저장하였습니다.",
  valCommentNotice: "공지사항 텍스트가 비었습니다.",
  valCommentOrigin: "원산지표시 텍스트가 비었습니다.",
}

export function NoticeOptions(props) {
  const { shopInfo } = useSelector(state => state.shop);

  const dispatch = useDispatch()
  let noticeOptions = shopInfo.noticeOptions || {}

  const [isLoading, setIsLoading] = useState(false);
  const [notice, setNotice] = useState(noticeOptions.notice || "")
  const [useNotice, setUseNotice] = useState(noticeOptions.useNotice || false)

  const [origin, setOrigin] = useState(noticeOptions.origin || "")
  const [useOrigin, setUseOrigin] = useState(noticeOptions.useOrigin || false)

  const onSubmit = async () => {
    try {
      setIsLoading(true)
      if (debug) {
        console.log('='.repeat(50), 'Notice')
        console.log(useNotice, notice)
        console.log('='.repeat(50), 'Origin')
        console.log(useOrigin, origin)
      }

      if (useNotice && notice === "") throw Error(str.valCommentNotice )
      if (useOrigin && origin === "") throw Error(str.valCommentOrigin)

      const body = { noticeOptions: JSON.stringify({ notice, useNotice, origin, useOrigin }) }

      const res = await shopUpdate({ shopId: shopInfo.shopId, body })
      if (res.err) throw Error(str.failure + " - " + res.err.message)
      if (debug) console.log('res.result.Item', res.result.Item)

      const newShopInfo = {
        ...shopInfo,
        noticeOptions: JSON.parse(res.result.Item.noticeOptions)
      }
      dispatch(setShopInfo({ shopInfo: newShopInfo }));

      alert(str.success)

    } catch (e) {
      alert(e.message)
    } finally {
      setIsLoading(false)
      props.history.push(routePaths.store.home)
    }
  }

  return (
    <Page>
      <CardContainer
        title="공지사항 | 원산지표시 설정"
        onClickBack={() => props.history.push(routePaths.home)}
        variant="md"
      >

        <FormBox>
          <FormInput
            label="공지 사항"
            name="notice"
            placeholder="주문앱 사용자에게 공지할 텍스트..."
            value={notice}
            onChange={(e) => setNotice(e.target.value)}
            inputType="textarea"
          />

          {/* <FormBox > */}
          <FormInput
            label="공지사항 사용 여부"
            name="useNotice"
            checked={useNotice}
            onChange={() => setUseNotice(!useNotice)}
            inputType="checkbox"
          />
          {/* </FormBox> */}
        </FormBox>

        <br />

        <FormBox>
          <FormInput
            label="원산지 설명"
            name="origin"
            placeholder="원산지 표시 내용"
            value={origin}
            onChange={(e) => setOrigin(e.target.value)}
            inputType="textarea"
          />

          {/* <FormBox > */}
          <FormInput
            label="원산지표시 사용 여부"
            name="useOrigin"
            checked={useOrigin}
            onChange={() => setUseOrigin(!useOrigin)}
            inputType="checkbox"
          />
          {/* </FormBox> */}
        </FormBox>


        <br />
        <Button
          size="sm"
          width="100%"
          variant="primary"
          title="확인"
          isLoading={isLoading}
          onClick={onSubmit}
        />

      </CardContainer>
    </Page>
  );
}
