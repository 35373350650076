import React, { useState } from "react";
import { Button, useStyles, Input, ModalForm } from "../../../components";
import { useInputFields } from "../../../lib/hooksLib";
import { downloadMenu } from "../../../lib-pos";
import { debug } from "../../../settings";

const confirmWord = "0000";
const str = {
  comment: `"${confirmWord}"를 입력 후 확인을 누르면 진행됩니다`,
  noItemFound: "NO ITEM FOUND",
  alertFail: "정보를 가져오는 데 실패했습니다!",
  alertFailUpdate: "정보를 수정하는 데 실패했습니다!",
}

/**
 * @info_POS_메뉴_불러오기
 */
export default function MenuUpdateModal(props) {
  const {
    visible,
    setVisible,
    isLoading, setIsLoading,
    shopInfo,
    itemOptions,
    itemList,
    onload,
    setVisibleUploadExcel
  } = props;
  const { gStyle } = useStyles();

  const [fields, setFields] = useInputFields({ word: debug ? "0000" : "" });
  const [readonly, setReadonly] = useState(false)

  const [logComment, setLogComment] = useState(str.comment)

  const onClickGetPosMenuData = async () => {
    try {
      setIsLoading(true);
      if (shopInfo.posName === "DUMMY_POS") {
        throw Error("Not supported yet - DUMMY_POS ")
        // setVisibleUploadExcel(true);
      } else {
        setReadonly(true)
        let resp = await downloadMenu({ shopInfo, itemList, itemOptions, setComment });
        if (resp.err) throw new Error(resp.err.message);
        await onload();
        setReadonly(false)
        setIsLoading(false);
        alert("메뉴업데이트가 완료되었습니다!!");
      }
    } catch (e) {
      alert(e.message);
    } finally {
      setComment('')
      setIsLoading(false);
      setReadonly(false)
      setVisible(false);
    }
  }

  const setComment = (txt) => {
    // if (debug) console.log(txt)
    // setFields({ target: { name: "word", value: txt } })
    setLogComment(txt)
  }

  const styles = {
    titleWrap: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: "10px",
      marginBottom: "15px"
    },
    title: { ...gStyle.subtitle, ...gStyle.bold, textAlign: "center" },
    text: { ...gStyle.text, textAlign: "center", marginBottom: "15px" }
  }

  return (
    <ModalForm
      visible={visible}
      setIsVisible={setVisible}
      title={"포스의 메뉴데이터를 불러옵니다."}
      backdrop="static"
    >
      <div>
        {/* {!logComment && <p style={styles.text}>{str.comment}</p>}
        {logComment && <p style={styles.text}>{logComment}</p>} */}
        <p style={styles.text}>{logComment || str.comment}</p>
        <Input name="word" value={fields.word} onChange={setFields} readOnly={readonly} />
        <Button
          disabled={confirmWord !== fields.word}
          variant="primary"
          width="100%"
          title='확인'
          onClick={onClickGetPosMenuData}
          isLoading={isLoading}
        />
      </div>
    </ModalForm>
  )
}
