import React, { useState } from "react";
import { CardContainer, FormInput, Button, FormBox } from "../../../../components";
import { useDispatch } from "react-redux";
import { setShopInfo } from "../../../../redux/shop";
import { shopUpdate } from "../../../../lib/ec2-api-lib";
import { routePaths } from "../../../../navigation/routePaths";
import { debug } from "../../../../settings";

const str = {
  keyExistComment: "보안정보가 이미 존재합니다.",
  failure: "저장에 실패했습니다.",
  success: "성공적으로 저장하였습니다.",
  valComment: "'사용자에게 통보' 하기 위해서는 먼저 `스토어앱에 주문 통보` 가 선행 되어야 합니다."
}

export function OrderNotifyOptions(props) {
  const { shopInfo } = props  

  const dispatch = useDispatch()
  let orderNotifyOptions = shopInfo.orderNotifyOptions || {}

  if(debug) console.log(orderNotifyOptions)

  const [isLoading, setIsLoading] = useState(false);
  const [toShop, setToShop] = useState(orderNotifyOptions.toShop || false)
  const [toUser, setToUser] = useState(orderNotifyOptions.toUser || false)

  const submitOptions = async () => {
    try {
      setIsLoading(true)

      if(toUser && !toShop) throw Error(str.valComment)

      const body = { orderNotifyOptions: JSON.stringify({toShop, toUser}) }
      const res = await shopUpdate({shopId: shopInfo.shopId, body})
      if (res.err) throw Error(res.err.message)
      if (debug) console.log('res.result.Item', res.result.Item)

      const newShopInfo = {
        ...shopInfo,
        orderNotifyOptions: JSON.parse(res.result.Item.orderNotifyOptions)
      }
      dispatch(setShopInfo({ shopInfo: newShopInfo }));

      alert(str.success)
    } catch (e) {
      alert(str.failure + " - " + e.message)
    } finally {
      setIsLoading(false)
      props.history.push(routePaths.home)
    }
  }

  return (
    <CardContainer
      title="주문 통보 설정"
      onClickBack={() => props.history.push(routePaths.home)}
      variant="md"
    >
      <FormBox >
        <FormInput
          label="스토어앱에 주문 통보 여부"
          name="toShop"
          checked={toShop}
          onChange={() => setToShop(!toShop)}
          inputType="checkbox"
        />
      </FormBox> <br />

      <FormBox >
        <FormInput
          label="사용자에게 주문 통보 여부"
          name="isPayable"
          checked={toUser}
          onChange={() => setToUser(!toUser)}
          inputType="checkbox"
        />
      </FormBox> 

      <br />
      <Button
        size="sm"
        width="100%"
        variant="primary"
        title="확인"
        isLoading={isLoading}
        onClick={submitOptions}
      />

    </CardContainer>
  );
}
