import React, { useEffect, useState } from "react";
import { ModalForm, useStyles, CustomTable } from "../../../components";
import { updateItemOption } from "../../../lib-pos";
import { debug } from "../../../settings";

// const str = {
//   noItemsSelected: "선택된 항목이 없습니다!",
//   noName: "명칭이 비어 있습니다!"
// }

export default function OKPosSdsModal(props) {
  const {
    visible,
    setVisible,
    shopInfo,
    sdsClsList, 
    onload,
    tableHeaders,
    isLoading, setIsLoading
  } = props;

  const modalTitle = "분류항목 필수 여부 편집"
  const { gStyle } = useStyles()
  const [tHeader, setTHeader] = useState([])
  const [sdsClasses, setSdsClasses] = useState([])

  useEffect(() => {
    function start() {
      const sdsClsList2 = sdsClsList.map(o => {
        return { ...o, required: o.required || false }
      })
      setSdsClasses(sdsClsList2)

      const tableHeader = [...tableHeaders.SdsClsList]
      setTHeader(tableHeader)
    }
    start()
  }, [])

  const onSubmit = async () => {
    try {
      setIsLoading(true)
      
      const body = {
        SdsClsList: JSON.stringify(sdsClasses)
      }
      let res = await updateItemOption({shopInfo, body})
      if(res.err) throw Error(res.err.message)
      await onload()
    } catch (e) {
      if (debug) console.log(e)
      alert(e.message);
    } finally {
      setIsLoading(false);
      setVisible(false);
    }
  }

  const onChangeChecked = (obj) => {
    const _sdsClasses = [...sdsClasses]
    const classes = _sdsClasses.map(cls => {
      const key = "SDS_CLS_CD"
      if (cls[key] === obj[key]) {
        return {
          ...cls,
          required: !obj.required
        }
      } else return cls
    })
    setSdsClasses(classes)
  }

  const styles = {
    formGroup: { margin: "15px 5px" }
  }

  return (
    <ModalForm
      visible={visible}
      setIsVisible={setVisible}
      title={modalTitle}
      isLoading={isLoading}
      onSubmit={onSubmit}
      size={"lg"}
      backdrop="static"
    >
      <div style={styles.formGroup}>
        <p style={gStyle.text}>아이템을 클릭하면 필수여부가 변경됩니다.</p>
        <CustomTable tableHeader={tHeader} rowItems={sdsClasses} onClickItem={onChangeChecked} />
      </div>
    </ModalForm>
  )
}
