import React from "react";
import { useSelector } from "react-redux";
import { ArrowL, ArrowR } from "./Icons";
import { colors, useStyles } from "./ui";

export function CardContainer({
  variant, // lg=100% | md=518 | sm=474
  size, // lg=100% | md=518 | sm=474
  width,
  height,
  children,
  title, onClickBack,
  detail, onClickDetail,
  title2,
  className,
  padding,
}) {

  const { isMobileDevice, isMobilePhone } = useSelector((state) => state.platform);  
  const { gStyle } = useStyles()

  const borderRadius = isMobilePhone ? 10 : 20

  let defaultWidth = "100%"; // variant===lg or undefined
  if (variant === "md") defaultWidth = isMobileDevice ? "100%" : "518px";
  if (variant === "sm") defaultWidth = isMobileDevice ? "90%" : "418px";
  // if (width) defaultWidth = width;

  const defaultInnderBoxPadding = isMobileDevice ? "20px" : "40px";

  const color = colors.dark;
  const styles = {
    /**
     * wrap은 container  width가 백프로가 아닌경우에 센터로 위치하게함
     */
    wrap: {
      width: width || defaultWidth,
      marginBottom: "20px",
      marginLeft: "auto", marginRight: "auto"
    },
    container: {
      width: "100%",
    },
    topContainer: {
      width: "100%",
      marginBottom: "5px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    innerContainer: {
      width: "100%",
      height: height || "",
      // padding: padding || "0px",
      padding: padding || defaultInnderBoxPadding,
      backgroundColor: "white",
      // borderRadius: "20px",
      borderRadius,
      // boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.08)",
      boxShadow: "0 0 10px 0px rgba(0, 0, 0, 0.08)",
    },
    title1: {
      ...gStyle.title, color,
      cursor: onClickBack ? "pointer" : "auto"
    },
    detailText: {
      ...gStyle.subtitle,
      color,
      cursor: "pointer",
    },
  };
  return (
    <div style={styles.wrap}>
      <div style={styles.container}>
        {title &&
          <div style={styles.topContainer}>
            <h2 style={styles.title1} onClick={onClickBack}>
              {onClickBack && <ArrowL />}
              &nbsp;
              {title}
            </h2>
            <h3 style={styles.detailText} onClick={onClickDetail} >
              {detail}&nbsp;
              {onClickDetail && <ArrowR />}
            </h3>
          </div>
        }
        <div style={styles.innerContainer} className={className} >
          {children}
        </div>
      </div>
    </div>
  );
}
