import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  itemList3: [],
  itemOptions3: {}
};

export const items = createSlice({
  name: "itemsInfo",
  initialState,
  reducers: {

    setItemList3: (state, action) => {
      state.itemList3 = action.payload.itemList3;
    },
    resetItemList3: (state) => {
      state.itemList3 = initialState.itemList3;
    },

    setItemOptions3: (state, action) => {
      state.itemOptions3 = action.payload.itemOptions3;
    },
    resetItemOptions3: (state) => {
      state.itemOptions3 = initialState.itemOptions3;
    },
  },
});

export const {
  setItemList3, resetItemList3,
  setItemOptions3, resetItemOptions3
} = items.actions;

export default items.reducer;
