import { useState, useRef, useCallback, useEffect } from "react";
import { debug } from "../settings";
// import { colors } from "../components";

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function (event) {
      setValues({
        ...fields,
        [event.target.id]: event.target.value,
      });
    },
  ];
}

/**
 *
 * @param {*} initialState
 * @param {*} event.target.name input 항목 지정자, input props에 반드시 name이 들어가야 함.
 * @param {*} event.target.value input 항목의 값
 *
 * @returns
 */
export function useInputFields(initialState) {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function (event) {
      //
      // 하나의 함수내에서 속성값을 연속으로 변경할 수 있도록 함.
      // useState 함수는 기본적으로 Closure 이므로 콜백으로 처리함.
      //
      const name = event.target.name;
      let value = event.target.value;
      const type = event.target.type;

      if (type === "number" && typeof value === "string") {
        // if (debug) console.log(type, typeof value, value);
        value = parseInt(value);
      }  
      // if (debug) console.log(name, type, value, !fields[name]);

      /**
       * @info_checkbox_수정
       */
      setValues((flds) => ({
        ...flds,
        // [name]: value
        [name]: type !== "checkbox" ? value : !fields[name]
      }));
    },
  ];
}

/**
 * 
 * @param {*} initialState = false
 * @param {*} ms half of clock cycle
 * @returns 
 */
export function useOnOff(initialState = false, ms = 500) {
  const [isOn, setIsOn] = useState(initialState)
  const intervalRef = useRef(null);

  const startOnOff = useCallback(() => {
    if (intervalRef.current) return;
    intervalRef.current = setInterval(() => {
      setIsOn(pre => !pre);
    }, ms);
  }, [ms]);

  const stopOnOff = useCallback(() => {
    if (!intervalRef.current) return;
    clearInterval(intervalRef.current);
  }, []);

  return { isOn, startOnOff, stopOnOff };
}

/**
 * 
 * @param {Function} callback 
 * @param {Number} delay 
 */
export function useInterval(callback, delay) {
  const savedCallback = useRef();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

/**
 * 
 * @param {*} props.originalColor
 * @param {*} props.draggedColor
 * @param {*} props.dropToColor
 * @returns 
 */
export function useDraggable({
  originalColor,
  draggedColor,
  dropToColor,
  onEnd
}) {

  const [dragged, setDragged] = useState(null);
  const [dropTo, setDropTo] = useState(null);

  const dragStart = (e, i) => {
    setDragged(i);
    e.target.id = "dragged";
    if (e.target.className === "draggable-item") {
      // e.target.style.backgroundColor = draggedColor;
      e.target.style.borderTopColor = draggedColor;
    }
  }

  const dragOver = (e, i) => {
    e.preventDefault();
  }

  const dragEnter = (e, i) => {
    // 
    // target이 child 이면 skip
    // 
    if (e.target.className !== "draggable-item") {
      // console.log("target이 child 이면 skip");
      return;
    }

    // 
    // target이 자신이거나 바로 아래이면 skip
    // 
    if ((i === dragged) || ((i - dragged) === 1)) {
      // console.log("target이 자신이거나 바로 아래이면 skip");
      return;
    }

    e.target.style.borderTopColor = dropToColor;
  }

  const dragLeave = (e, i) => {
    if (e.target.className === "draggable-item") {
      e.target.style.borderTopColor = originalColor;
    }
  }

  const drop = (e, i) => {
    setDropTo(i);
    if (e.target.className === "draggable-item") {
      e.target.style.borderTopColor = originalColor;
    }
    let dragged = document.getElementById("dragged");
    dragged.id = "";
    dragged.style.borderTopColor = originalColor;
  };

  // 
  // 여기서 i 는 dragged 이다.
  // 
  const dragEnd = (e, i) => {
    // console.log("from", dragged, "to", dropTo, "i", i);
    if (dragged === dropTo) return;
    if ((dropTo - dragged) === 1) return;
    if (dragged === null || dragged === undefined ) return;
    if (dropTo === null || dropTo === undefined ) return;
    onEnd();
  };

  return {
    dragStart,
    dragOver,
    dragEnter,
    dragLeave,
    drop,
    dragEnd,
    dragged, dropTo
  }
}
