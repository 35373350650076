import React, { useEffect, useState } from "react";
import { Button, useStyles, ModalView, FileInput, UnderlineButton } from "../../../components";
import { useInputFields } from "../../../lib/hooksLib";
import { Close } from '../../../components/Icons';
import { downloadMenu } from "../../../lib-pos";
import { debug } from "../../../settings";
import readXlsxFile from "read-excel-file";
import { useSelector } from "react-redux";

const confirmWord = "0000";
const str = {
  comment: `"${confirmWord}"를 입력 후 확인을 누르면 진행됩니다`,
  noItemFound: "NO ITEM FOUND",
  alertFail: "정보를 가져오는 데 실패했습니다!",
  alertFailUpdate: "정보를 수정하는 데 실패했습니다!",
}

const excelSheets = [
  "groupList",
  "itemList",
  "optionGroupList",
  "optionClassList",
  "optionItemList",
  "tableList",
];

/**
 * @info_POS_메뉴_불러오기
 */
export default function MenuUploadExcelModal(props) {
  const {
    visible,
    setVisible,
    isLoading,
    setIsLoading,
    shopInfo,
    // itemOptions,
    // itemList,
    onload
  } = props;
  const { gStyle } = useStyles();

  const {itemList3, itemOptions3} = useSelector(state=>state.items)

  const [title, setTitle] = useState("메뉴데이터 엑셀파일을 업로드하세요.");
  const [disabled, setDisabled] = useState(true);
  const [menus, setMenus] = useState({})

  // console.table(itemList3)
  // console.log(itemOptions3)

  const onClickGetPosMenuData = async () => {
    try {
      setIsLoading(true);

      let resp = await downloadMenu({ shopInfo, itemList: itemList3, menus });
      if (resp.err) throw new Error(resp.err.message);

      onload();
    } catch (e) {
      alert(e.message);
    } finally {
      setIsLoading(false);
      setMenus({})
      setDisabled(true)
      setVisible(false);
    }
  }

  const onChange = async (e) => {
    const sheets = excelSheets
    const menuObj = {}

    try {
      await Promise.all(sheets.map(async listName => {
        const rows = await readXlsxFile(e.target.files[0], { sheet: listName })
        const menuList = _convert2Obj(rows)
        if (debug) console.log(listName, menuList)
        menuObj[listName] = menuList
      }));
      setTitle("확인을 클릭하면 메뉴가 업로됩니다.")
      setMenus(menuObj);
      setDisabled(false);
    } catch (e) {
      alert(e.message);
      setVisible(false);
    }
  }

  const _convert2Obj = (rows) => {
    let menuList = [];
    for (let i = 0; i < rows.length; i++) {
      let row = rows[i];

      let group = {};
      for (let j = 0; j < row.length; j++) {
        if (i > 0) {
          group[rows[0][j]] = row[j]
        }
      }
      if (i > 0) menuList.push(group)
    }
    return menuList
  }

  const styles = {
    titleWrap: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: "10px",
      marginBottom: "15px"
    },
    title: { ...gStyle.subtitle, ...gStyle.bold, textAlign: "center" },
    text: { ...gStyle.text, textAlign: "center", marginBottom: "15px" }
  }

  return (
    <ModalView visible={visible} setIsVisible={setVisible}>
      <div>
        <div style={styles.titleWrap}>
          <div style={{ width: "20px" }} />
          <p style={styles.title}>{title}</p>
          <Close style={{ cursor: 'pointer' }} width={20} onClick={() => setVisible(false)} />
        </div>
        <hr />

        <FileInput
          type="file"
          title="메뉴파일 업로드"
          accept=".xlsx"
          onChangeInputFile={onChange}
        />


        <Button
          disabled={disabled}
          size="sm"
          variant="primary"
          width="100%"
          title='확인'
          onClick={onClickGetPosMenuData}
          isLoading={isLoading}
        />

        <UnderlineButton
          size="sm"
          variant="outline-danger"
          title='현재의 메뉴를 엑셀로 저장'
          onClick={onClickGetPosMenuData}
          isLoading={isLoading}
        />

      </div>
    </ModalView>
  )
}
