import React, { useEffect, useState } from "react";
import { CardContainer, CalendarDropdown, LoaderFullPage, useStyles } from "../../../components";
import { CustomTable2 } from "../../../components/Tables2";
import { getPayRecords } from "../../../lib/ec2-api-lib";
import { transformOrders } from "../../../lib-pos";
import { sortObjArray2, getTimestampFromNow } from "../../../lib/utils";
import { PayDataViewModal, CancelConfirmModal } from "./PayDataViewModal";
import { getStartEndTimes, _getHrs } from "./OrderOnlySection";
// import { debug } from "../../../settings";

export const tableHeader = [
  { name: "time", displayName: "주문일시", sorting: true, selectKey: true },
  { name: "TableName", displayName: "테이블", sorting: true },
  { name: "orderInfoHtml", displayName: "주문정보", type: "html" },
  { name: "payInfo", displayName: "결제정보" },
  // { name: "pgName", displayName: "이용 PG(사)" },
];

export function OrderPrepaidSection(props) {
  const { shopInfo } = props;
  const { itemOptions3, itemList3 } = props
  const { gStyle } = useStyles()

  ////////////////////////////////////////////////////////////////////////////////////
  // Calendar parameters
  ////////////////////////////////////////////////////////////////////////////////////
  const _today = new Date(getTimestampFromNow({ now: new Date(), days: 0 }))
  const [targetDate, setTargetDate] = useState(_today)
  ////////////////////////////////////////////////////////////////////////////////////

  const [startTime, setStartTime] = useState(0)
  const [endTime, setEndTime] = useState(0)

  const [isLoading, setIsLoading] = useState(false);
  const [payItems, setPayItems] = useState([]);
  // const [payHeader, setPayHeader] = useState([])

  const [payRecord, setPayRecord] = useState({})
  const [visiblePayData, setVisiblePayData] = useState(false)
  const [visibleCC, setVisibleCC] = useState(false)

  useEffect(() => {
    const { start_time, end_time } = getStartEndTimes(targetDate)
    setStartTime(start_time)
    setEndTime(end_time)
  }, [targetDate])

  useEffect(() => {
    if (startTime > 0) onload()
  }, [startTime])

  const onload = async () => {
    try {
      setIsLoading(true)

      const start_time = startTime
      const end_time = endTime

      let resPayRecords = await getPayRecords({ shopId: shopInfo.shopId, start_time, end_time });

      if (resPayRecords.err) throw new Error(resPayRecords.err.message);

      const orders = sortObjArray2({ arr: resPayRecords.result.Items, key: "created", type: "down" });

      let tableList = []
      if(shopInfo.posName==="UNIONPOS") tableList = itemOptions3.TableList
      if(shopInfo.posName==="OASIS_POS") tableList = itemOptions3.tableList

      let newOrders = transformOrders({ 
        shopInfo, 
        orders,
        tableList, 
        itemList: itemList3, 
        itemOptions: itemOptions3,
        prePay: true 
      })

      setPayItems(newOrders)
    } catch (e) {
      alert(e.message)
    } finally {
      setIsLoading(false)
    }
  }

  const onClickPayItem = (item) => {
    setPayRecord(item)
    setVisiblePayData(true)
  }

  const onSort = (name, direction) => {
    const sortedItems = sortObjArray2({ arr: payItems, key: name, type: direction })
    setPayItems(sortedItems)
  }

  return (
    <>
      <CardContainer
        title="선결제 주문 내역"
        onClickBack={() => props.history.push("/")}
      >
        <CalendarDropdown
          variant="outline-danger"
          label="날짜 선택"
          date={targetDate}
          setDate={setTargetDate}
        /><hr />

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h3 style={gStyle.subtitle}>{_getHrs(startTime, endTime)} 시간 동안의 선결제 주문 내역</h3>
          <h3 style={gStyle.subtitle}>총 {payItems.length} 건</h3>
        </div>
        <CustomTable2
          tableHeader={tableHeader}
          rowItems={payItems}
          setRowItems={setPayItems}
          onClickItem={onClickPayItem}
          variant="danger"
          sort={onSort}
        />
      </CardContainer>

      {isLoading && <LoaderFullPage />}

      <PayDataViewModal 
        visible={visiblePayData} setVisible={setVisiblePayData} 
        payItem={payRecord}
        next={() => {
          setVisiblePayData(false)
          setVisibleCC(true)
        }}
      />

      <CancelConfirmModal 
        visible={visibleCC} 
        setVisible={setVisibleCC} 
        payItem={payRecord} 
        onload={onload}
      />
    </>
  );
}
