import { configureStore } from "@reduxjs/toolkit";
import loggerMiddleware from "../lib/loggerMiddleware";
import userReducer from "./user";
import shopReducer from "./shop";
import itemsReducer from "./items"
import platformReducer from "./platform";
import cartReducer from './cart';
// import dpCartReducer from './dpCart'

export const store = configureStore({
  reducer: {
    user: userReducer,
    shop: shopReducer,
    items: itemsReducer,
    platform: platformReducer,
    cart: cartReducer,
    // dpCart: dpCartReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(loggerMiddleware),
});
