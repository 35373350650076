/**
 * @OKpos
 */
export const shopItem = {
  // 
  // tableName = shopItems
  // key = shopId, id
  // 
  name: "okPosShopItem",
  inputFields: [

    /** ***********************************************************************
     * @POS_메뉴에서_가져온_오리지널_속성_편집불가
     ** **********************************************************************/
    // 공통 속성명칭
    { name: "groupCode", posName: "LCLS_CD" },                // 대분류 코드
    // { name: "GrpCode", posName: "LCLS_CD" },                  // 대분류 코드 사용하지 않음.
    { name: "MidGrpCode", posName: "MCLS_CD" },               // 중분류코드
    { name: "SmGrpCode", posName: "SCLS_CD" },                // 소분류코드

    { name: "itemCode", posName: "PROD_CD" },                 // 메뉴 아이템 코드
    { name: "itemName", posName: "PROD_NM" },                 // 메뉴 아이템 명    
    { name: "price", posName: "SALE_UPRC" },                  // 가격 

    // OKPos 전용 속성명칭
    { name: "TAX_YN", posName: "TAX_YN" },                    // 과세여무 Y or N
    { name: "SIDE_MENU_YN", posName: "SIDE_MENU_YN" },        //   사이드 메뉴 Y or N
    { name: "SDA_CLS_CD", posName: "SDA_CLS_CD" },            //   사이드 메뉴 속성분류코드
    { name: "SDS_GRP_CD", posName: "SDS_GRP_CD" },            //   사이드 메뉴 선택그룹코드
    { name: "ORD_MIN_QTY", posName: "ORD_MIN_QTY" },          //   최소 주문 수량
    { name: "USE_YN", posName: "USE_YN" },                    //   메뉴 사용여부 Y or N
    { name: "SOLD_OUT_YN", posName: "SOLD_OUT_YN" },          //   Sold out Y or N

    /** TU_CLS 정보 추가 속성 - TU_CLS_CD는 아이템에 중복 가능하므로 Array로 저장함.*/
    { name: "tuClassList", posName: "tuClassList" },              // 

    /** ***********************************************************************
     * @ONL_메뉴에서_편집_가능_속성
     ** **********************************************************************/
    { name: "itemName2", defaultValue: { posName: "PROD_NM" } },  // 메뉴명 편집 가능

    // 
    // 아이템 언어지원: 기
    // 기본값은 undefined 
    // 
    // { name: "nameLang", defaultValue: JSON.stringify({}) },

    { name: "sortOrder", defaultValue: 0 },
    { name: "image", defaultValue: "" },
    { name: "description", defaultValue: "" },
    { name: "removed", defaultValue: false },                    // ONL 메뉴에서 제거하고 싶을 때    
    { name: "isSoldout", defaultValue: false },                   // SoldOut === Y 
    { name: "isNew", defaultValue: false },
    { name: "isHot", defaultValue: false },
    { name: "isBest", defaultValue: false },

    /**
     * startTime: "0" ~ "23"
     * endTime: "0" ~ "23"
     * days: [MON,TUE,WED,THU,FRI,SAT,SUN]
     * 기본값은 undefined
     */
    // { name: "serviceTime", defaultValue: JSON.stringify({startTime, endTime, days}) },
  ],
};

/**
 * @OKPos_itemOptions_속성값_참조용
 */
const itemOptions = {

  // 
  // 대분류 대그룹리스트  - CLSLMCODE
  // 
  GrpList: [
    {
      /**
       * OKPos Menu
       */
      "LCLS_CD": "001",           //대분류코드 
      "LCLS_NM": "음료",           //대분류명 
      "USE_YN": "Y",              //사용여부

      /**
       * ONL Menu에서 사용
       */
      GrpCode: "",                // LCLS_CD - UnionPos와 호환
      name: "",                   // LCLS_NM - UnionPos와 호환
      GrpName: "",
      displayName: "",
      sortOrder: 0,
      hidden: false
    }
  ],

  // 
  // 중그룹리스트       - CLSMMCODE 가맹점들이 사용하는지 의문
  // 
  MidGrpList: [
    {
      /**
       * OKPos Menu
       */
      "LCLS_CD": "001",          // 대분류코드 
      "MCLS_CD": "001",          // 중분류코드 
      "MCLS_NM": "커피",         // 중분류명 
      "USE_YN": "Y",             // 사용여부 

      /**
       * ONL Menu에서 사용
       */
      GrpCode: "",              // LCLS_CD - UnionPos와 호환
      MidGrpCode: "",           // MCLS_CD
      name: "",                 // MCLS_NM - UnionPos와 호환
      displayName: "",
      sortOrder: 0,
      hidden: false             // USE_YN === Y ????
    }
  ],

  // 
  // 소그룹리스트       - CLSSMCODE 가맹점들이 사용하는지 의문
  // 
  SmGrpList: [
    {
      /**
       * OKPos Menu
       */
      "LCLS_CD": "001",          //대분류코드 
      "MCLS_CD": "001",          //중분류코드 
      "SCLS_CD": "001",          //소분류코드 
      "SCLS_NM": "커피",         //소분류명 
      "USE_YN": "Y",             //사용여부 

      /**
       * ONL Menu에서 사용
       */
      GrpCode: "",                // LCLS_CD - UnionPos와 호환
      MidGrpCode: "",             // MCLS_CD
      SmGrpCode: "",              // SCLS_CD
      name: "",                   // SCLS_NM - UnionPos와 호환
      displayName: "",
      sortOrder: 0,
      hidden: false             // USE_YN === Y ????
    }
  ],

  // 
  //  속성
  // 

  // 
  // 사이드메뉴 속성분류코드리스트    - SDACLCODE
  // 
  SdaClsList: [
    {
      "SDA_CLS_CD": "01",           // 사이드메뉴-속성분류코드 
      "SDA_CLS_NM": "Coffee",       // 사이드메뉴-속성분류명 
      "SDA_ORDER_SEQ": "",          // 사이드메뉴-선택순서
      "USE_YN": "Y",                // 사용여부 
    }
  ],
  // 사이드메뉴 속성코드리스트      - SDACDCODE
  SdaCdList: [
    {
      "SDA_CLS_CD": "01",           // 사이드메뉴-속성분류코드 
      "SDA_CD": "001",              // 사이드메뉴-속성코드 
      "SDA_NM": "미지근하게",         // 사이드메뉴-속성명 
      "SDA_ORDER_SEQ": "",          // 사이드메뉴-선택순서
      "USE_YN": "Y",                // 사용여부 
    }

  ],

  // 
  // 사이드메뉴 선택그룹코드 리스트 - SDSGRCODE
  // 
  SdsGrList: [
    {
      "SDS_GRP_CD": "000001",       //사이드메뉴-선택그룹코드 
      "SDS_GRP_NM": "커피",         //사이드메뉴-선택그룹명 
      "USE_YN": "Y",
    },
  ],

  // 
  // 사이드메뉴 선택분류코드 리스트 - SDSCLCODE
  // 
  SdsClsList: [
    {
      "SDS_GRP_CD": "000001",     //사이드메뉴-선택그룹코드 
      "SDS_CLS_CD": "000001",     //사이드메뉴-선택분류코드 
      "SDS_CLS_NM": "커피",       //사이드메뉴-선택분류명 
      "SDS_ORDER_SEQ": "1",       //사이드메뉴-선택순서 
      "SDS_MAX_QTY": "0",         //사이드메뉴-선택수량 
      "USE_YN": "Y",              //사용여부 
      "KIOSK_USE_FG": "Y"         //사이드메뉴-KIOSK 필수여부
    },
  ],

  // 
  // 사이드메뉴 선택코드 리스트     - SDSCDCODE
  // 
  SdsCdList: [
    {
      "SDS_CLS_CD": "000001",     //사이드메뉴-선택분류코드 
      "SDS_CD": "001",            //사이드메뉴-선택코드 
      "SDS_NM": "샷추가",          //사이드메뉴-선택명 
      "PROD_CD": "0008",          //상품코드 
      "SDS_PROD_UPRC": "500.00",  //선택메뉴-단가
      "SDS_ORDER_SEQ": "1",       //사이드메뉴-선택순서 
      "USE_YN": "Y",
    },
  ],

  // 
  // TableList                    - TABLE
  // 
  TableList: [
    {
      "TABLE_CD": "009",         //테이블코드 
      "TABLE_NM": "9",           //테이블명 
      "TABLE_GR_CD": "01",      //테이블그룹코드 
      "TABLE_GR_NM": "01",      //테이블그룹이름 
      "USE_YN": "Y",             //사용여부 
    },
  ],

  // 
  // 메뉴 세팅 - ONL
  // 기본값 undefined
  // 
  menuSettings: JSON.stringify({
    usePosMenuFirst: false,
    supportedLangs: ["KR", "EN", "JP", "CN"]
  })
}
