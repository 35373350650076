// import { debug } from "../settings";

const debug = false;
// const debug = true;
const loggerMiddleware = (store) => (next) => (action) => {
  if (debug) {
    console.group(action && action.type);
    console.log("curr state", store.getState());
    console.log("Action:", action);
  }
    next(action);
  if (debug) {
    console.log("next state", store.getState());
    console.groupEnd();
  }
};

export default loggerMiddleware;
