import { shopUpdate } from "./ec2-api-lib"
import { debug } from "../settings"

const appkey = "86e55f9710205ab6b1b1bd0b4fd46dc6" // rest api key

// 
// https://devtalk.kakao.com/t/cors/115909
// 
export async function getGeoLocation({ addr }) {
  try {
    const baseUrl = "https://dapi.kakao.com/v2/local/search/address.json?query="
    const url = baseUrl + addr
    const res = await fetch(
      url,
      {
        method: "GET",
        mode: "cors",
        headers: {
          'Authorization': `KakaoAK ${appkey}`
        }
      }
    )
    const data = await res.json();
    if (debug) console.log('geo data', data)
    if (data.errorType) throw Error(data.message)
    if (!data.documents) throw Error("NO DOCUMENTS FOUND")
    if (data.documents.length === 0) throw Error("NO DOCUMENTS FOUND")
    const result = {
      x: data.documents[0].x,
      y: data.documents[0].y,
    }
    return { result }
  } catch (e) {
    return { err: { message: e.message } }
  }
}

export async function saveGeolocation({ shopInfo }) {
  try {
    const addr = shopInfo.addr1
    if(debug) console.log('addr1', addr)
    let res = await getGeoLocation({ addr })
    if (res.err) throw Error(res.err.message)
    const geoLocation = JSON.stringify(res.result)

    res = await shopUpdate({
      shopId: shopInfo.shopId,
      body: { geoLocation }
    })
    if (res.err) throw Error(res.err.message)
    return { result: res.result?.Item?.geoLocation }
  } catch (e) {
    return { err: { message: e.message } }
  }
}