import React from "react";
import {
  useStyles,
  FormInput,
  ModalForm,
  CustomTable,
  Button,
  FormBox
} from "../../../components";
import { ArrowL, ArrowR } from "../../../components/Icons";
// import { debug } from "../../../settings";

// const str = {
//   noItemsSelected: "선택된 항목이 없습니다!",
//   noName: "명칭이 비어 있습니다!"
// }

export default function OnlOptionModalUi(props) {
  const {
    shopInfo,
    visible, setVisible,
    modalTitle,
    isEdit,
    type,
    tHeader, tHeaderIncluded,
    isLoading,
    fields, setFields,
    optItems, // class | group
    includedItems,
    showFullItems, setShowFullItems,
    onSubmit, onSubmitDel, onChangeChecked, sorting
  } = props;
  const { gStyle, colors } = useStyles();

  const styles = {
    formGroup: { margin: "15px 5px" },
    title: { ...gStyle.subtitle, ...gStyle.bold, textAlign: "center" },
    text: { ...gStyle.text, color: colors.dark },
    textBtnWrap: {
      display: "flex",
      flexDirection: showFullItems ? "row-reverse" : "row",
      justifyContent: showFullItems ? "flex-start" : "flex-end",
      paddingTop: 15
    },
    btnWrap: {
      margin: "15px 5px",
      display: "flex",
      justifyContent: "space-between"
    }
  }

  return (
    <ModalForm
      visible={visible}
      setIsVisible={setVisible}
      title={modalTitle}
      isLoading={isLoading}
      // size={(shopInfo.posName==="DUMMY_POS") ? "md" : "lg"}
      size="lg"
      backdrop="static"
    >
      {type === "group" &&
        <>
          <div style={styles.formGroup}>
            <FormInput
              label="그룹 코드"
              value={fields.groupCode}
              name="groupCode"
              onChange={setFields}
              readOnly={true}
              // readOnly={isEdit}
            />
            <FormInput
              label="그룹 명"
              value={fields.name}
              name="name"
              onChange={setFields}
            />
          </div>
        </>}

      {type === "class" &&
        <>
          <div style={styles.formGroup}>
            <FormInput
              label="옵션 코드"
              name="classCode"
              value={fields.classCode}
              onChange={setFields}
              readOnly={true}
              // readOnly={isEdit}
            />
            <FormInput
              label="옵션 명"
              name="name"
              value={fields.name}
              onChange={setFields}
            />

            <FormBox>
              <FormInput
                label="필수 여부"
                name="required"
                checked={fields.required}
                onChange={setFields}
                inputType="checkbox"
              />
            </FormBox>
          </div>
        </>}

      {isEdit &&
        <div style={styles.textBtnWrap}>
          <p style={{ margin: 0 }}>{showFullItems ? "아이템 순서 편집" : "아이템 추가/삭제"}</p>
          {
            showFullItems
              ? <ArrowL onClick={() => setShowFullItems(!showFullItems)} />
              : <ArrowR onClick={() => setShowFullItems(!showFullItems)} />
          }
        </div>
      }

      {
        (isEdit && !showFullItems) &&
        <div style={{ ...styles.formGroup, marginTop: 0 }}>
          <p style={gStyle.text}>서브 아이템 리스트 - 순서 편집</p>
          <CustomTable
            tableHeader={tHeaderIncluded}
            rowItems={includedItems}
            onChangeChecked={onChangeChecked}
            sort={sorting}
          />
        </div>
      }

      {(!isEdit || showFullItems) &&
        <div style={styles.formGroup}>
          <p style={gStyle.text}>체크 혹은 해제 후 확인을 누르면 추가 혹은 삭제 됩니다.</p>
          <CustomTable tableHeader={tHeader} rowItems={optItems} onChangeChecked={onChangeChecked} />
        </div>
      }

      {isEdit
        ?
        <div style={styles.btnWrap}>
          <Button
            variant="secondary"
            onClick={onSubmitDel}
            size="sm"
            style={{ width: "40%" }}
            isLoading={isLoading}
          >
            삭제
          </Button>
          <Button
            variant="primary"
            onClick={onSubmit}
            size="sm"
            style={{ width: "40%" }}
            isLoading={isLoading}
          >
            수정
          </Button>
        </div>
        :
        <div style={styles.btnWrap}>
          <Button
            variant="primary"
            onClick={onSubmit}
            size="sm"
            style={{ width: "100%" }}
            isLoading={isLoading}
          >
            확인
          </Button>
        </div>
      }

    </ModalForm>
  )
}

