
export const sheets = [
  "groupList",
  "itemList",
  "optionGroupList",
  "optionClassList",
  "optionItemList",
  "tableList"
]

export const items = {
  name: "itemList",
  inputFields: [
    { name: "groupCode" },
    { name: "groupName" },
    { name: "itemCode" },
    { name: "itemName" },
    { name: "displayName" }, // itemName2
    { name: "price", type: "number" },
    { name: "image" },
    { name: "description" },
    { name: "optionGroupCode" },
    { name: "isHidden", type: "boolean" }, // removed
    { name: "isSoldout", type: "boolean" },
    { name: "isNew", type: "boolean" },
    { name: "isHot", type: "boolean" },
    { name: "isBest", type: "boolean" },
    { name: "sortOrder", type: "number" }, // text (default) | number | boolean
  ],
};

export const groups = {
  name: "groupList",
  inputFields: [
    { name: "groupCode" }, // GrpCode
    { name: "groupName" }, // unionpos.name | GrpName
    { name: "displayName" },
    { name: "isHidden", type: "boolean" },
    { name: "sortOrder", type: "number" },
  ],
};

export const optionGroups = {
  name: "optionGroupList",
  inputFields: [
    { name: "optionGroupCode" },
    { name: "optionGroupName" },
    { name: "optionClassCodes" },
  ],
};

export const optionClasses = {
  name: "optionClassList",
  inputFields: [
    { name: "optionClassCode" },
    { name: "optionClassName" },
    { name: "optionItemCodes" },
    { name: "required", type: "boolean" },
  ],
};

export const optionItems = {
  name: "optionItemList",
  inputFields: [
    { name: "optionItemCode" },
    { name: "optionItemName" },
    { name: "price", type: "number" },
    { name: "maxQty", type: "number" },
  ],
};

export const tables = {
  name: "optionTableList",
  inputFields: [
    { name: "tableCode", type: "number" },
    { name: "tableNo", type: "number" },
    { name: "tableName" },
  ],
};
