import React, { useEffect, useState } from "react";
import { useStyles, FormInput, ModalForm, FormGroup, FormBox } from "../../../components";
import { updateItem } from "../../../lib-pos";

export default function SoldoutModal(props) {
  const {
    visible, setVisible,
    shopInfo,
    itemList,
    onload,
  } = props;
  const { gStyle, colors } = useStyles();

  const modalTitle = "품절상품 관리";
  const [isLoading, setIsLoading] = useState(false);

  const [soldoutList, setSoldoutList] = useState([])
  useEffect(() => {
    const sItems = itemList.filter(item => item.isSoldout)
    const _sItems = sItems.map(sitem => {
      return {
        id: sitem.id,
        itemCode: sitem.itemCode,
        itemName: sitem.itemName,
        itemName2: sitem.itemName2,
        isSoldout: sitem.isSoldout,
      }
    })
    setSoldoutList(_sItems)

  }, [itemList])

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      let errMsg = ""
      await Promise.all(soldoutList.map(async item => {
        if (item.isSoldout) return null
        let res = await updateItem({
          shopInfo,
          id: item.id,
          body: { isSoldout: item.isSoldout }
        })
        if (res.err) errMsg += res.err.message + " "
      }))
      if (errMsg !== "") throw Error(errMsg)

      await onload();
    } catch (e) {
      alert(e.message);
    } finally {
      setIsLoading(false);
      setVisible(false);
    }
  }

  const onChange = (e) => {
    const itemCode = e.target.name;
    setSoldoutList(preList => {
      const index = preList.findIndex(_item => _item.itemCode === itemCode)
      const newItems = [...preList]
      newItems[index].isSoldout = !newItems[index].isSoldout
      return newItems
    })
  }

  const styles = {
    text: { ...gStyle.text, textAlign: "center" },
  }

  return (
    <ModalForm
      visible={visible}
      setIsVisible={setVisible}
      title={modalTitle}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      <FormGroup>
        {soldoutList.length > 0 &&
          <p style={styles.text}>체크해제 후 확인 클릭을 누르면 주문이 재개 됩니다.</p>}
        <FormBox>
          {
            soldoutList.length > 0 &&
            soldoutList.map((item, i) => {
              return (
                <FormInput
                  key={i}
                  label={item.itemName}
                  name={item.itemCode}
                  checked={item.isSoldout}
                  onChange={onChange}
                  inputType="checkbox"
                />
              )
            })
          }
          {soldoutList.length === 0 &&
            <p style={{ ...styles.text, marginBottom: "15px" }}>품절상품이 없습니다.</p>}
        </FormBox>
      </FormGroup>
    </ModalForm>
  )
}
