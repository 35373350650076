import React from "react";
import { Page } from "../../components";
import { CardContainer, useStyles } from "../../components";
import noImage from "../../assets/images/no-image.png";
import { useSelector } from "react-redux";

const viewFields = [
  { name: "shopName", displayName: "가게명" },
  { name: "licenseNo", displayName: "사업자등록번호" },
  { name: "tel", displayName: "유선전화번호" },
  { name: "shopType", displayName: "가게분류" },
  { name: "addr1", displayName: "주소" },
  { name: "addr2", displayName: " " },
  // { name: "country", displayName: " " },
  { name: "posName", displayName: "포스소프트웨어" },
  { name: "ownername", displayName: "대표자명" },
  // { name: "phone_number", displayName: "대표자 휴대전화" },
];

export default function ShopInfoDetail(props) {
  const { shopInfo } = useSelector(state => state.shop);
  const { user } = useSelector(state => state.user);
  const { isMobileDevice } = useSelector((state) => state.platform);
  const { gStyle } = useStyles();

  const onClick = (name) => {
    if(name==="posName") props.history.push({ pathname: `/store/register-posname`, isEdit: true })
  }

  const styles = {
    innerContainer: {
      width: "100%",
      display: "flex",
      flexDirection: isMobileDevice ? "column" : "row",
    },
    imageWrap: {
      width: isMobileDevice ? "100%" : "50%",
      height: isMobileDevice ? "50%" : "50%",
    },
    image: {
      backgroundImage: `url(${shopInfo.image ? shopInfo.image : noImage})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: 'center',
      width: '100%',
      // height: isMobileDevice ? dim.width * 0.7 : 445,
      height: isMobileDevice ? "40vh" : 445,
      borderRadius: isMobileDevice
        ? "20px 20px 0px 0px"
        : "20px 0px 0px 20px ",
    },

    detailWrap: {
      width: isMobileDevice ? "100%" : "50%",
      padding: isMobileDevice ? "20px" : "40px",
    },
    detailRow: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: "15px",
    },
    detailTitle: { ...gStyle.text, ...gStyle.bold },
    detailText: { ...gStyle.text },
  };

  return (
    <Page>
      <CardContainer
        title="가게 정보"
        detail="수정하기"
        onClickBack={() => props.history.goBack()}
        padding="0px"
        onClickDetail={() =>
          props.history.push({
            pathname: `/store/register/edit`,
            editItems: shopInfo,
            isEdit: true
          })
        }
      >
        <div style={styles.innerContainer}>
          <div style={styles.imageWrap}>
            <div style={styles.image} />
          </div>
          <div style={styles.detailWrap}>
            {
              viewFields.map((item, i) => {
                return (
                  <div key={item.name + i} >
                    {item.name === "ownername" && <hr />}
                    <div style={styles.detailRow}>
                      <p 
                        style={styles.detailTitle} 
                        onClick={()=>onClick(item.name)}
                      >{item.displayName}</p> 

                      <p style={styles.detailText}>
                        { item.name === "phone_number"
                          ? user[item.name]
                          : shopInfo[item.name]
                        }
                      </p>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </CardContainer>
    </Page>
  );
}
