export function getOnlOptionItems({ item, itemOptions2 }) {
  if (!item.optionGroupCode) return []

  const optionGroupList = itemOptions2.optionGroupList || []
  const optionClassList = itemOptions2.optionClassList || []
  const optionItemList = itemOptions2.optionItemList || []

  // console.log('optionGroupList', optionGroupList)
  // console.log('optionClassList', optionClassList)

  const groups = optionGroupList.filter(o => o.groupCode === item.optionGroupCode)
  if (groups.length < 1) return []

  const classes = []
  groups[0].classList.map(clsCode => {
    const _classes = optionClassList.filter(o => o.classCode === clsCode)
    if (_classes.length === 1) classes.push(_classes[0])
    return null;
  })

  if (classes.length < 1) return []

  const classes2 = classes.map(cls => {
    const items = []
    cls.itemList.map(itemCode => {
      const _items = optionItemList.filter(itemObj => itemObj.itemCode === itemCode)
      if (_items.length === 1) items.push(_items[0])
      return null
    })
    return {
      ...cls,
      itemList2: items   // testOrder에서 필요
    }
  })

  return classes2
}

export function getOptionItemsDummypos({ item, itemOptions2 }) {
  if (!item.optionGroupCode) return []

  const optionGroupList = itemOptions2.optionGroupList || []
  const optionClassList = itemOptions2.optionClassList || []
  const optionItemList = itemOptions2.optionItemList || []

  // 
  // optionGroup
  // 
  const optGroups = optionGroupList.filter(o => o.optionGroupCode === item.optionGroupCode)
  if (optGroups.length < 1) return []
  
  // console.log(item.itemName, "optGroup")
  // console.table(optGroups)  
  
  // 
  // optionClassList 얻기
  //
  const optClassList = []
  optGroups[0].optionClassCodes.map(clsCode => {
    const _classes = optionClassList.filter(o => o.optionClassCode === clsCode)
    if (_classes.length === 1) optClassList.push(_classes[0])
    return null;
  })
  if (optClassList.length < 1) return []

  // console.table(optClassList)

  // 
  // optionClass 에 itemList 포함
  //
  const optClassList2 = optClassList.map(cls => {
    const items = []
    cls.optionItemCodes.map(c => {
      const _items = optionItemList.filter(itemObj => itemObj.itemCode === c)
      if (_items.length === 1) items.push(_items[0])
      return null
    })
    return {
      ...cls,
      itemList: items   // testOrder에서 필요
    }
  })
  return optClassList2
}