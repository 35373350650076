import { json2obj } from "../../lib/utils";
import { saveAs } from "file-saver";
import { utils, write } from "xlsx";
import { sheets, items, groups, optionGroups, optionClasses, optionItems, tables } from "./dpos-menu-db";

export function export2ExcelFile({ itemList, itemOptions, fileName = "test" }) {
  const _itemList = convertData(itemList, items)
  // console.table(_itemList)

  const groupList = convertData(itemOptions.groupList, groups)
  // console.table(_groupList)


  const optionGroupList = convertData(itemOptions.optionGroupList, optionGroups)
  // console.table(optGroupList)

  const optionClassList = convertData(itemOptions.optionClassList, optionClasses)
  // console.table(optClassList)

  const optionItemList = convertData(itemOptions.optionItemList, optionItems)
  // console.table(optItemList)

  const tableList = convertData(itemOptions.tableList, tables)
  // console.table(tableList)

  const bookData = {
    itemList: _itemList,
    groupList,
    optionGroupList,
    optionClassList,
    optionItemList,
    tableList,
  }

  // 
  // 엑셀 데이터 생성
  // 
  const workbook = utils.book_new();

  Object.keys(bookData).map(sheetName => {
    const sheetData = bookData[sheetName]
    const worksheet = utils.json_to_sheet(sheetData);
    utils.book_append_sheet(workbook, worksheet, sheetName);
  })

  // 
  // buffer 생성 
  // 
  const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

  // 
  // save as file
  // 
  saveAs(blob, `${fileName}.xlsx`);
}

function convertData(arr, d) {
  const newArr = arr.map(obj => {
    const newObj = {}
    d.inputFields.map(f => {
      newObj[f.name] = getValueByType(obj, f)
      return null
    })

    if (d.name === "optionGroupList") newObj["optionClassCodes"] = obj.optionClassCodes?.join(",")
    if (d.name === "optionClassList") newObj["optionItemCodes"] = obj.optionItemCodes?.join(",")
    if (d.name === "itemList") {
      newObj["displayName"] = newObj["displayName"] || newObj["itemName2"] || newObj["itemName"]
    }

    return newObj
  })
  return newArr
}


function getValueByType(item, f) {
  if (f.type === "boolean") {
    return item[f.name] ? "TRUE" : "FALSE"
  } else if (f.type === "number") {
    let defaultNo = 0
    if (f.name === "maxQty") defaultNo = 1
    return parseInt(item[f.name]) || defaultNo
  } else {
    return item[f.name] || ""
  }  
}
