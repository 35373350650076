import React from "react";
import { ReactComponent as Logo2 } from "../assets/icons/logo2.svg";
import { debug } from "../settings";
import { colors, gStyle } from "../components";

export default function Footer(props) {
  const color = colors.dark;
  const myStyle = {
    container: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    textWrap: {
      padding: "36px 0",
    },
    copy: { color: colors.danger },
    text: { ...gStyle.text, marginBottom: "15px" },
    a: {
      ...gStyle.text,
      color,
      textDecoration: "none",
      borderBottom: "1px solid",
      fontWeight: "bold",
    },
  };

  return (
    <div className="container" style={myStyle.container}>
      {/* <hr style={{ padding: 0, margin: 0 }} /> */}
      <div style={myStyle.textWrap}>
        <div style={{ textAlign: 'left' }}>
          <p style={myStyle.text}>
            대표이사 : 천성우 &nbsp;&nbsp;|
            &nbsp;&nbsp;상호명 : (주)PSDL&nbsp;&nbsp; |
            &nbsp;&nbsp;사업자등록번호 : 528-87-01357
          </p>
          <p style={myStyle.text}>
            Copyright
            <span style={myStyle.copy}> &copy;</span>2021 (주)PSDL. All rights
            reserved. Font by Malssami & Naver Nanum Font.
          </p>
          <a
            style={myStyle.a}
            href="https://www.psdl.co.kr/"
          >
            (주)PSDL 바로가기
          </a>
        </div>
      </div>
      <div>
        <Logo2 width={60} />
      </div>
    </div>
  );
}
