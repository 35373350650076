import React, { useEffect, useState } from "react";
import {
  CardContainer,
  CalendarDropdown,
  LoaderFullPage,
  useStyles,
} from "../../../components";
import { CustomTable2 } from "../../../components/Tables2";
import { getOrderRecords } from "../../../lib/ec2-api-lib";
import { transformOrders } from "../../../lib-pos";
import { getTimestampFromNow, sortObjArray2 } from "../../../lib/utils";
// import { exampleOrderList } from "./example-orderList-oasis";

export const tableHeader = [
  { name: "time", displayName: "주문일시", sorting: true },
  { name: "TableName", displayName: "테이블이름", sorting: true },
  // { name: "tableName", displayName: "테이블이름", sorting: true },
  { name: "orderInfoHtml", displayName: "주문정보", type: "html" },
  // { name: "payInfo", displayName: "결제정보" },
];

export function OrderOnlySection(props) {
  const { shopInfo } = props;
  const { itemOptions3, itemList3 } = props
  const { gStyle } = useStyles()

  ////////////////////////////////////////////////////////////////////////////////////
  // Calendar parameters
  ////////////////////////////////////////////////////////////////////////////////////
  const _today = new Date(getTimestampFromNow({ now: new Date(), days: 0 }))
  const [targetDate, setTargetDate] = useState(_today)
  ////////////////////////////////////////////////////////////////////////////////////

  const [startTime, setStartTime] = useState(0)
  const [endTime, setEndTime] = useState(0)
  const [isLoading, setIsLoading] = useState(false);
  const [orderItems, setOrderItems] = useState([]);

  const [comments, setComments] = useState("")

  useEffect(() => {
    async function start() {
      try {
        const { start_time, end_time } = getStartEndTimes(targetDate)
        setStartTime(start_time)
        setEndTime(end_time)
      } catch (e) {
        alert(e.message)
      }
    }
    start()
  }, [targetDate])

  useEffect(() => {
    if (startTime > 0) {
      _setOrderData()
    }
  }, [startTime])

  const _setOrderData = async () => {
    try {
      setIsLoading(true)
      const start_time = startTime
      const end_time = endTime

      // let res = {}
      let tableList = []

      if (shopInfo.posName === "UNIONPOS") tableList = itemOptions3.TableList
      else if (shopInfo.posName === "OASIS_POS") tableList = itemOptions3.tableList
      else if (shopInfo.posName === "EASY_POS") tableList = itemOptions3.tableList
      else throw Error("NOT SUPPORTED - " + shopInfo.posName)

      if (shopInfo.posName === "EASY_POS") setComments("EASY_POS는 옵션메뉴 처리를 추가해야 함.")

      let res = await getOrderRecords({ shopId: shopInfo.shopId, start_time, end_time });
      if (res.err) throw new Error(res.err.message);

      const orderArr = [...res.result.Items]
      const orders = sortObjArray2({ arr: orderArr, key: "created", type: "down" });

      let newOrders = transformOrders({
        shopInfo, orders, tableList, itemList: itemList3, itemOptions: itemOptions3,
      })
      setOrderItems(newOrders)
    } catch (e) {
      // console.log(e)
      alert(e.message)
    } finally {
      setIsLoading(false)
    }
  }

  const onSort = (name, direction) => {
    const sortedItems = sortObjArray2({ arr: orderItems, key: name, type: direction })
    setOrderItems(sortedItems)
  }

  return (
    <CardContainer
      title="주문 내역"
      onClickBack={() => props.history.push("/")}
    >
      <CalendarDropdown
        variant="outline-danger"
        label="날짜 선택"
        date={targetDate}
        setDate={setTargetDate}
      /><hr />

      {comments !== "" && <h3 style={{...gStyle.subtitle, color: "blue", marginBottom: 15}}>{comments}</h3>}

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3 style={gStyle.subtitle}>{_getHrs(startTime, endTime)} 시간 동안의 주문 내역</h3>
        <h3 style={gStyle.subtitle}>총 {orderItems.length} 건</h3>
      </div>
      <CustomTable2
        tableHeader={tableHeader}
        rowItems={orderItems}
        setRowItems={setOrderItems}
        variant="danger"
        sort={onSort}
      />

      {isLoading && <LoaderFullPage />}
    </CardContainer>
  );
}

/**
 * 
 * @param {Object} targetDate calendar date object
 */
export function getStartEndTimes(targetDate) {
  // const targetLocaleDateString = new Date(targetDate).toLocaleDateString()
  const targetLocaleDateString = targetDate.toLocaleDateString()
  const today = new Date(getTimestampFromNow({ now: new Date(), days: 0 }))
  const todayLocalDateString = new Date(today).toLocaleDateString()

  let start_time
  let end_time

  // 오늘 12시간 
  if (targetLocaleDateString === todayLocalDateString) {
    const dayHrs = 12
    const now = Date.now()
    start_time = _getTimestamp({ now, hr: -(dayHrs) })
    end_time = now
  }

  // 당일 24시간
  else {
    const dayHrs = 24
    start_time = new Date(targetDate).getTime()
    end_time = start_time + (dayHrs * 60 * 60 * 1000)
  }

  return { start_time, end_time }
}

export function _getTimestamp({ now, hr }) {
  const msec = hr * 60 * 60 * 1000
  const _now = now + msec
  return _now
}

export function _getHrs(startTime, endTime) {
  const hrs = Math.floor((endTime - startTime) / (1000 * 60 * 60))
  return hrs
}
