import React, { useEffect, useState } from "react";
import { updateItemOption, getGroupList, getGroupUpdateBody, getSelectedItems } from "../../../lib-pos";
import { getGroupName, getItemName } from "../../../lib-pos/parseNames";
import { useStyles, LoaderFullPage, Alert } from "../../../components";
import { ChevronR } from "../../../components/Icons";
// import { sortObjArray, sortObjArray2 } from "../../../lib/utils";
import { useDraggable } from "../../../lib/hooksLib";
import { useSelector } from "react-redux";
import { debug } from "../../../settings";

export default function GroupBar(props) {
  const {
    shopInfo,
    // itemOptions, 
    itemOptions2,
    usePosMenuFirst,
    itemList,
    setSelectedGroup2,
    setMenuClicked,
    showRemoved,
    onload
  } = props;

  const { selectedLanguage } = useSelector(state => state.shop)
  const { gStyle, colors } = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [itemGroups, setItemGroups] = useState([]);

  useEffect(() => {
    if (Object.keys(itemOptions2).length === 0) return

    let groups = getGroupList({ itemOptions2 }) // sorted groupList 를 리턴함
    if (groups === undefined) return
    setItemGroups(groups)
  }, [itemOptions2])

  const onGroupClick = (group) => {
    setSelectedGroup2(group);
    setMenuClicked(true);
  }

  /** *************************************************************************
   * @메뉴보기_순서편집_시작
   * **************************************************************************/
  const [alertMsg, setAlertMsg] = useState("");

  const originalColor = colors.light;
  const draggedColor = colors.light + "80";
  const dropToColor = colors.danger + "80";

  const {
    dragStart,
    dragOver,
    dragEnter,
    dragLeave,
    drop,
    dragEnd,
    dragged,
    dropTo
  } = useDraggable({
    originalColor,
    draggedColor,
    dropToColor,
    onEnd: () => setAlertMsg(`메뉴보기 순서에 변경이 있습니다.`),
  });

  const udpateSortOrder = async () => {
    try {
      if (debug) console.log("from", dragged, "to", dropTo);
      let valid = Number.isInteger(dragged) && Number.isInteger(dropTo);
      if (!valid) throw new Error("드래그에 문제가 았습니다!");

      setIsLoading(true);

      let newItems = [...itemGroups];
      let draggedItem = newItems[dragged];
      newItems.splice(dragged, 1);

      let dropPosition;
      if (dragged < dropTo) dropPosition = dropTo - 1;
      else dropPosition = dropTo;
      newItems.splice(dropPosition, 0, draggedItem);

      const newItems2 = newItems.map((item, i) => {
        return { ...item, sortOrder: i };
      });

      const bodyObj = getGroupUpdateBody({ itemOptions2, groupList: newItems2 })

      let res = await updateItemOption({ shopInfo, body: bodyObj });
      if (res.err) throw new Error("그룹 순서 변경에 실패했습니다. " + res.err.message);
      await onload();
    } catch (e) {
      alert(e.message);
    } finally { setIsLoading(false); }
  }

  /** *************************************************************************
   * @메뉴보기_순서편집_끝
   * **************************************************************************/

  const styles = {
    container: {
      margin: "0px",
      border: "1px solid " + colors.light,
      borderRadius: 10,
      boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.08)",
    },
    menuWrap: {
      padding: "15px",
      borderTop: "1px solid " + originalColor,
    },
    menuWrapNoBorder: {
      padding: "15px",
      borderTop: "1px solid #ffffff00",
    },
    menuInnerWrap: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: "space-between",
    },
    text: { ...gStyle.text },
    btnWrap: { cursor: "pointer" },
    subtitle: { ...gStyle.textBold, marginBottom: "5px" },
  }

  return (
    <div style={styles.container}>
      {
        itemGroups.map((group, i) => {
          return (
            <div
              style={(i === 0) ? styles.menuWrapNoBorder : styles.menuWrap}
              key={i}
              className="draggable-item"
              draggable={true}
              onDragStart={(e) => dragStart(e, i)}
              onDragOver={(e) => dragOver(e, i)}
              onDragEnter={(e) => dragEnter(e, i)}
              onDragLeave={(e) => dragLeave(e, i)}
              onDrop={(e) => drop(e, i)}
              onDragEnd={(e) => dragEnd(e, i)}

              onClick={() => onGroupClick(group)}
            >
              <div style={styles.menuInnerWrap}>
                <div>
                  <h5 key={"group" + i} style={{ ...styles.subtitle, color: group.hidden ? "#ddd" : colors.dark }}>
                    {getGroupName({ shopInfo, usePosMenuFirst, group, selectedLanguage })}
                  </h5>
                  <p style={{ ...styles.text, color: group.hidden ? "#ddd" : colors.dark }}>
                    {getItemText2({ shopInfo, itemList, showRemoved, usePosMenuFirst, group, itemOptions2, selectedLanguage })}
                  </p>
                </div>
                <div style={styles.btnWrap}>
                  <p style={styles.subtitle}>
                    <ChevronR />
                  </p>
                </div>
              </div>
            </div>
          );
        })
      }

      {isLoading && <LoaderFullPage />}
      <Alert
        title={alertMsg}
        visible={alertMsg !== ""}
        onClickCancel={() => setAlertMsg("")}
        onClickOk={() => {
          setAlertMsg("");
          udpateSortOrder();
        }} >
        <p>확인을 누르면 변경사항이 저장됩니다.</p>
      </Alert>

    </div>
  );
};

function getItemText2({ shopInfo, itemList, showRemoved, usePosMenuFirst, group, itemOptions2, selectedLanguage }) {
  const posName = itemOptions2.optionName

  let itemsByGrp = []

  if (posName === "OKPOS") {
    itemsByGrp = getSelectedItems({ posName, group, itemList, showRemoved })
  } else if (posName === "unionpos") {
    itemsByGrp = itemList.filter((item) =>
      (item.groupCode === group.GrpCode) && (!item.removed || showRemoved)
    );
  } else if (
    posName === "DUMMY_POS" ||
    posName === "EASY_POS" ||
    posName === "OASIS_POS"
  ) {
    itemsByGrp = itemList.filter((item) =>
      (item.groupCode === group.groupCode) && (!item.removed || showRemoved)
    );
  } else {
    // do nothing
  }

  const maxTxtLen = 50;

  const itemNameList = itemsByGrp.map(item => {
    return getItemName({ shopInfo, item, usePosMenuFirst, selectedLanguage })
  })

  const txt = itemNameList.join(" | ");
  const _txt = txt.length < maxTxtLen ? txt : txt.substring(0, maxTxtLen) + " ...";

  return itemNameList.length + " 개의 메뉴 - " + _txt;
}
