import React, { useEffect, useState } from "react";
import { CardContainer, Button, FormBox, useStyles } from "../../../../components";
import { useDispatch } from "react-redux";
import { setShopInfo } from "../../../../redux/shop";
import { shopUpdate } from "../../../../lib/ec2-api-lib";
import { routePaths } from "../../../../navigation/routePaths";
import { RadioGroup, Radio } from "../../components/TestOrder/compos";

const str = {
  keyExistComment: "보안정보가 이미 존재합니다.",
  failure: "저장에 실패했습니다.",
  success: "성공적으로 저장하였습니다.",
  valComment: "'사용자에게 통보' 하기 위해서는 먼저 `스토어앱에 주문 통보` 가 선행 되어야 합니다."
}

export function UserAppUiOptions(props) {
  const { shopInfo } = props

  const { gStyle } = useStyles()
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false);

  let userAppUiOptions = shopInfo.userAppUiOptions || {}
  const optList = [
    { name: "Top", value: "top" },
    { name: "Left", value: "left" },
  ]
  const [options, setOptions] = useState(optList)

  useEffect(() => {
    const groupBarPosision = userAppUiOptions.groupBarPosition || "top" // default
    const defaultOptList = optList.map(opt => {
      if (opt.value === groupBarPosision) return { ...opt, selected: true }
      else return { ...opt, selected: false }
    })

    setOptions(defaultOptList)
  }, [shopInfo])

  const submitOptions = async () => {
    try {
      setIsLoading(true)

      let groupBarPosition = ""
      options.map(opt => {
        if (opt.selected) groupBarPosition = opt.value
        return null
      })

      const body = { userAppUiOptions: JSON.stringify({ groupBarPosition }) }
      const res = await shopUpdate({ shopId: shopInfo.shopId, body })
      if (res.err) throw Error(res.err.message)

      const newShopInfo = {
        ...shopInfo,
        userAppUiOptions: JSON.parse(res.result.Item.userAppUiOptions)
      }
      dispatch(setShopInfo({ shopInfo: newShopInfo }));

      alert(str.success)
    } catch (e) {
      alert(str.failure + " - " + e.message)
    } finally {
      setIsLoading(false)
      props.history.push(routePaths.home)
    }
  }

  const onOptionChange = (val) => {
    setOptions((pre) => {
      const newList = pre.map((opt) => {
        if (opt.value === val) return { ...opt, selected: true }
        else return { ...opt, selected: false }
      })
      return newList
    })
  }

  return (
    <CardContainer
      title="사용자 앱 UI 설정"
      onClickBack={() => props.history.push(routePaths.home)}
      variant="md"
    >
      <p style={gStyle.text}>그룹바 위치</p>
      <FormBox padding="15px 15px 15px">
        <RadioGroupComponent
          optionItemList={options}
          onChange={onOptionChange}
        />
      </FormBox>

      <br />
      <Button
        size="sm"
        width="100%"
        variant="primary"
        title="확인"
        isLoading={isLoading}
        onClick={submitOptions}
      />

    </CardContainer>
  );
}

function RadioGroupComponent(props) {
  const { onChange, optionItemList } = props
  let selValue = ""

  const [value, setValue] = useState(selValue)

  useEffect(() => {
    optionItemList.map(opt => {
      if (opt.selected) setValue(opt.value)
      return null
    })
  }, [optionItemList])

  const styles = {
    text: { margin: '0px 0px 5px' },
  }

  return (
    <RadioGroup value={value} onChange={onChange}>
      {optionItemList.map((optItem, i) => {
        return (
          <Radio key={i} value={optItem.value}>
            <p style={styles.text}>{`${optItem.name}`}</p>
          </Radio>
        )
      })}
    </RadioGroup>
  )
}