export default {
  kr: {
    home:{
      title: ""
    }
  },
  en: {
    home:{
      title: ""
    }
  }
}

export const targetLangs = [
  { name: "KR", displayName: "한국어" },
  { name: "EN", displayName: "English" },
  { name: "JP", displayName: "Japanese" },
  { name: "CN", displayName: "Chinese" },
];
