import React, { useState } from "react";
import { fileUpload } from "../../../lib/apiLib";
// import { shopItemUpdate, okposItemUpdate } from "../../../lib/ec2-api-lib";
import { updateItem } from "../../../lib-pos";
import { imageCompress, isJson } from "../../../lib/utils";
// import PostFormSection from "../../../components/PostFormSection";
import { FormInput, ModalForm, Button, useStyles, FormBox } from "../../../components";
// import { unionposShopItemUpdate } from "../../../lib-pos/unionpos/menu-db";
import { debug } from "../../../settings";
import { targetLangs } from "../../../settings/strings";
import { useInputFields } from "../../../lib/hooksLib";

export default function ModifyMenuModal(props) {
  const {
    shopInfo,
    item,
    itemOptions2,
    visible,
    setIsVisible,
    onload,
  } = props;
  const { gStyle } = useStyles();

  const title = `"${item.itemName}" 메뉴 편집`;
  // const inputFields = unionposShopItemUpdate.inputFields;
  const [isLoading, setIsLoading] = useState(false);
  const file = {};

  // if (debug) console.log('item', item);

  const nameLang = isJson(item.nameLang) ? JSON.parse(item.nameLang) : {};
  const descriptionLang = isJson(item.descriptionLang) ? JSON.parse(item.descriptionLang) : {};
  const supportedLangs = itemOptions2.menuSettings?.supportedLangs || [];

  const defaultNameLangFields = {}
  const defaultDescriptionLangFields = {}

  targetLangs.map((lang) => {
    if (supportedLangs.includes(lang.name)) {
      defaultNameLangFields[lang.name] = nameLang[lang.name] || ""
      defaultDescriptionLangFields[lang.name] = descriptionLang[lang.name] || ""
    }
    return null;
  })

  // if (debug) {
  //   console.log("name langFields", defaultNameLangFields)
  //   console.log("description langFields", defaultDescriptionLangFields)
  // } 

  const [itemFields, setItemFields] = useInputFields({
    itemName2: item.itemName2,
    description: item.description || "",
    image: item.image
  })
  const [nameLangFields, setNameLangFields] = useInputFields(defaultNameLangFields)
  const [descriptionLangFields, setDescriptionLangFields] = useInputFields(defaultDescriptionLangFields)

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const shopId = item.shopId;

      if (debug) console.log(itemFields);

      let fileRes = {};
      if (file.current) {

        // 
        // 메뉴 이미지 저장 300KB 초과하면 resize
        // 
        let resizedImg = file.current;
        if (file.current.size > 300000) {
          resizedImg = await imageCompress({
            image: file.current,
            maxSizeMB: 0.3,
            maxWidthOrHeight: 640
          })
          if (!resizedImg) throw new Error("이미지 처리에 문제가 발생하였습니다.");
        }

        fileRes = await fileUpload(resizedImg, shopId);
        if (fileRes.err) throw new Error("Error in fileUpload!");

        // 
        // Thumbnail 생성, 저장 - public/shopId/tn
        //
        resizedImg = await imageCompress({
          image: file.current,
          // maxSizeMB: 0.03,    // 30kB 미만
          // maxWidthOrHeight: 120
          maxSizeMB: 0.6,    // 100kB 미만
          maxWidthOrHeight: 150
        })
        if (!resizedImg) throw new Error("이미지 처리에 문제가 발생하였습니다.");

        // 
        // 저장만 하므로 fileRes를 덮어쓰지 말아야 한다.
        // 
        await fileUpload(resizedImg, shopId + "/tn");
        if (fileRes.err) throw new Error("Error in fileUpload!");
      }

      let body = {
        itemName2: itemFields.itemName2,
        nameLang: JSON.stringify(nameLangFields),
        description: itemFields.description,
        descriptionLang: JSON.stringify(descriptionLangFields),
        image: fileRes.key || itemFields.image || "",
      };

      if (debug) console.log('body', body);

      let res = await updateItem({ shopInfo, id: item.id, body })
      if (res.err) throw new Error("저장에 실패 하였습니다.!\n" + res.err.message);
      await onload();
    } catch (e) {
      alert(e.message);
    } finally {
      setIsLoading(false);
      setIsVisible(false);
    }
  };

  const onChangeInputFile = (e) => {
    file.current = e.target.files[0];
    if (debug) console.log("file.current", file.current);
  }

  const styles = {
    text: { ...gStyle.text, marginBottom: "3px" },
    formGroup: { margin: 0, padding: 5 }
  };

  return (
    <ModalForm
      visible={visible} setIsVisible={setIsVisible} title={title}
      onSubmit={onSubmit} isLoading={isLoading}
    >

      <div style={styles.formGroup}>
        <FormInput
          style={{ marginBottom: 0 }}
          label="메뉴 별칭(기본값) 편집"
          name="itemName2"
          // validated={validate()}
          value={itemFields.itemName2}
          onChange={setItemFields}
        />
      </div>



      {
        (supportedLangs.length) > 0 &&
        <div style={styles.formGroup}>
          <p style={styles.text}>언어별 메뉴명 편집</p>
          {/* <div style={{ border: "1px solid #555", padding: 10 }}> */}
          <FormBox>
            {
              targetLangs.map((lang, i) => {
                if (supportedLangs.includes(lang.name)) {
                  return (
                    <FormInput
                      key={lang.name}
                      label={lang.displayName}
                      name={lang.name}
                      value={nameLangFields[lang.name]}
                      onChange={setNameLangFields}
                    />
                  )
                } else return null
              })
            }
          </FormBox>
          {/* </div> */}
        </div>
      }

      <hr />

      <div style={styles.formGroup}>
        <FormInput
          style={{ marginBottom: 0 }}
          label="메뉴 설명(기본값) 편집"
          name="description"
          // validated={validate()}
          value={itemFields.description}
          placeholder="간단한 상품 설명..."
          onChange={setItemFields}
        />
      </div>

      {
        (supportedLangs.length) > 0 &&
        <div style={styles.formGroup}>
          <p style={styles.text}>언어별 메뉴 설명 편집</p>
          {/* <div style={{ border: "1px solid #555", padding: 10 }}> */}
          <FormBox>
            {
              targetLangs.map((lang, i) => {
                if (supportedLangs.includes(lang.name)) {
                  return (
                    <FormInput
                      key={lang.name}
                      label={lang.displayName}
                      name={lang.name}
                      value={descriptionLangFields[lang.name]}
                      onChange={setDescriptionLangFields}
                    />
                  )
                } else return null
              })
            }
          </FormBox>
          {/* </div> */}
        </div>
      }

      <hr />

      <div style={styles.formGroup}>
        <FormInput
          style={{ marginBottom: 0 }}
          inputType="file"
          label="상품 이미지"
          name="image"
          // validated={validate()}
          value={itemFields.image}
          onChangeInputFile={onChangeInputFile}
          onClickRemoveImage={() => setItemFields({ target: { name: "image", value: "" } })}
        />
      </div>

      {/* <Button
        size="sm"
        variant="primary"
        width="100%"
        title='수정'
        onClick={onSubmit}
        isLoading={isLoading}
      /> */}

    </ModalForm>
  )
}
