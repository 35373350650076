import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import {
  isExistUsername,
  userCreate,
  sendSms
} from "../../lib/ec2-api-lib"
import {
  Page,
  CardContainer,
  Button, UnderlineButton,
  FormInput, InputWithBtn,
  CustomModal,
  Alert,
  gStyle
} from "../../components";
import {
  createInputProps,
  createFields,
  validateInputValue
} from "../../components/PostFormSection"
import { useInputFields } from "../../lib/hooksLib";
import { db } from "../../settings";
import { debug } from "../../settings";

const str = {
  failedUser: "존재하지 않는 아이디입니다. ",
  failedCode: "인증코드 발송에 실패했습니다. ",
  noCode: "인증코드를 받지 않았습니다.",
  noCodeField: "인증코드를 입력하지 않았습니다.",
  failedCheckCode: "인증코드가 다릅니다.",
  sentCode: "인증코드가 전송되었습니다.",
  sucess: "성공적으로 변경되었습니다.",
  failedSetPw: "패스워드 설정에 실패했습니다. 다시 시도해 주세요."
}

export default function Signup2(props) {
  const { isMobileDevice } = useSelector((state) => state.platform);
  const [newuser, setNewuser] = useState(null);

  return (
    <Page>
      <CardContainer width={isMobileDevice ? 330 : 474} padding="40px 24px">
        {
          newuser === null
            ? <SignupSection {...props} setNewuser={setNewuser} />
            : <SignupConfirmSection {...props} newuser={newuser} />
        }

      </CardContainer>
    </Page>
  );
}

/**
 * 
 * @Signup_속성_및_actions
 *  1. username         : confirm isExsist
 *  2. phone_number     : validate
 *  3. password         : validate
 *  4. confirmPassword
 *  5. onSubmit         : getCode and go next
 */
function SignupSection(props) {
  const { setNewuser, setCode } = props;

  const inputFields = db.signup.inputFields;

  const [fields, setFields] = useInputFields(
    createFields({ inputFields, editItems: null })
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isVisibleModal, setVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [usernameChecked, setUsernameChecked] = useState(false);

  const checkUsernameExist = async () => {
    try {
      setIsLoading(true);
      let res;
      res = await isExistUsername({ username: fields.username });
      if (debug) console.log('isExsitUsername', res);
      
      // User exist === false
      if (res.result && res.result?.exist === false) {
        setModalTitle("사용가능한 아이디입니다.");
        setVisibleModal(true);
        setUsernameChecked(true);
      }
      // User exist === true
      else if (res.result?.exist) throw new Error("아이디가 이미 등록되어 있습니다.");
      // err
      else throw new Error("알 수 없는 에러가 발생하였습니다. 관리자에게 문의 바랍니다.");

    } catch (e) {
      setModalTitle(e.message);
      setVisibleModal(true);
    } finally { setIsLoading(false) }
  }

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      setNewuser(fields);
    } catch (e) {
      if (debug) console.log('signup Error: ', e.message);
      setIsLoading(false);
      setModalTitle(e.message);
      setVisibleModal(true);
    }
  }

  const validate = () => {
    let val = true;
    inputFields.map((item) => (val = val && validateInputValue(item, fields[item.name])));
    val = val && usernameChecked;
    val = val && (fields["password"] === fields["confirmPassword"])
    // console.log("val =", val)
    return val
  };

  return (
    <div>
      <h3 style={styles.title} >가맹점 회원 등록하기</h3>
      {inputFields.map((item, index) => {
        const myProps = createInputProps({ item, index, fields, setFields });

        if (item.name === "username") {
          myProps.btnText = "중복확인";
          myProps.onClick = checkUsernameExist;
          return <InputWithBtn key={index} {...myProps} isLoading={isLoading} />
        }

        if (item.name === "confirmPassword" && (fields.password !== fields.confirmPassword)) {
          myProps.validated = false;
        }

        return <FormInput key={index} {...myProps} isLoading={isLoading} />
      })}

      <hr />

      <div style={styles.btnWrap}>
        <Button
          width="45%"
          size="sm"
          variant="outline-primary"
          onClick={() => props.history.goBack()}
        >취소</Button>

        <Button
          width="45%"
          size="sm"
          variant="primary"
          disabled={!validate()}
          isLoading={isLoading}
          onClick={() => onSubmit(fields)}
        > 다음 (1/2)</Button>
      </div>

      <Alert title={modalTitle} visible={isVisibleModal} onClickOk={() => setVisibleModal(false)} />
    </div>
  );
}

function SignupConfirmSection(props) {
  const { newuser } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isVisibleModal, setVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [code, setCode] = useState("");

  const [fields, setFields] = useInputFields({ confirmationCode: "" });

  const validate = () => {
    return fields.confirmationCode.length > 5;
  };

  useEffect(() => {
    getVerifyCode();
  }, [])

  const getVerifyCode = async () => {
    try {
      if (debug) console.log(newuser);

      setIsLoading(true);

      // let res = await codeGet({ phone_number: newuser.phone_number });
      let res = await sendSms({ phone_number: newuser.phone_number });
      // if (res.result?.statusCode !== "202") throw new Error("인증코드가 전송에 실패했습니다.");
      if (res.err) throw new Error(str.failedCode + res.err.message);
      if (debug) console.log('res: ', res);

      setModalTitle("인증코드가 전송되었습니다. 휴대폰을 확인하세요!");
      setVisibleModal(true);

      setCode(res.result?.code);
      setIsLoading(false);
    } catch (e) {
      if (debug) console.log("Error:", e.message);
      setIsLoading(false);
      setModalTitle(e.message);
      setVisibleModal(true);
    }
  }

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      if (debug) console.log(code, fields.confirmationCode);

      if (fields.confirmationCode !== code) throw new Error("인증코드가 다릅니다.");

      const signupUser = {
        username: newuser.username,
        password: newuser.password,
        attributes: {
          phone_number: "+82" + newuser.phone_number.slice(1),
          email: newuser.email,
        },
      }

      const signedUser = await Auth.signUp(signupUser);
      if (debug) console.log("signedUser:", signedUser);

      let res = await userCreate({
        body: {
          username: newuser.username,
          userType: "shop",
          phone_number: newuser.phone_number,
          email: newuser.email,
          nickName: newuser.username,
          approved: false
        }
      });
      if (debug) console.log("createdUser res:", res);

      // res = await logIn(newuser.username, newuser.password);
      // if (debug) console.log("Login res:", res);

      setIsLoading(false);
      props.history.push('/login');
    } catch (e) {
      if (debug) console.log("Error:", e.message);
      setIsLoading(false);
      setModalTitle(e.message);
      setVisibleModal(true);
    }
  }

  // const onCancel = () => {
  //   // console.log('cancel');
  //   props.history.push('/signup');
  // }

  return (
    <div>
      <h3 style={styles.title}>본인 휴대전화 확인</h3>
      <FormInput
        name="confirmationCode"
        label="인증 코드"
        size="sm"
        validated={validate()}
        value={fields.confirmationCode}
        onChange={setFields}
        placeholder="인증코드번호를 입력하세요!"
      />

      <div style={styles.btnWrap}>
        <Button
          width="100%"
          size="sm"
          variant="primary"
          disabled={!validate()}
          isLoading={isLoading}
          onClick={onSubmit}
        >확인</Button>
      </div>

      <div style={styles.btnWrap}>
        <UnderlineButton title="인증번호 재전송" onClick={getVerifyCode} />
        {/* <UnderlineButton title="취소" onClick={onCancel}/> */}
      </div>

      <Alert title={modalTitle} visible={isVisibleModal} onClickOk={() => setVisibleModal(false)} />
    </div>
  );
}

const styles = {
  title: {
    ...gStyle.title,
    ...gStyle.bold,
    // fontSize: "24px",
    textAlign: "center",
    marginBottom: "20px"
  },
  btnWrap: {
    display: "flex",
    justifyContent: "space-between",
    // marginTop: 20, 
  }
}