import { 
  appName, 
  stage, 
  debug, 
  ec2Debug, ec2Api,
  apiKey,
  ec2ApiEndpoint,
  payApiDebug,
  oasispos
} from "./appSetting";
import { dev, prod } from "./awsSetting";

// 국세청 사업자조회 Open API //
// 참고사이트 : data.go.kr
// 검색 : 국세청 -> Open API
// 아이디 / 비번 : 카카오톡으로 로그인 하기.
// 유효기간 : 2024년 07월 08일 
// url과 serviceKey는 https://api.odcloud.kr/api/nts-businessman/v1/status?serviceKey=${license.serviceKey} 
// 형태로 입력해야 함.
export const enquireLicenseInfo = {
  serviceKey: 'OdcEoBC79yO%2FviLYtnHrqcC6SkRloaLQEIbiq1AMYictHwOzgb12If99G5haOWo5tyFWGMG0kd%2Fun6DjYR%2B%2FYg%3D%3D',
  url : 'https://api.odcloud.kr/api/nts-businessman/v1/status?serviceKey='
};

// const awsConfig = stage === "prod" ? prod : dev;
const awsConfig = prod;

export default {
  debug, ec2Debug, ec2Api, 
  payApiDebug,                  // Boolean
  oasispos,                     // Object
  appName,                      // String
  apiKey,                       // String
  stage,                        // String
  ec2ApiEndpoint,               // String
  ...awsConfig,                 // Object
  MAX_FILE_SIZE: 1000000,       // 이미지 업로드 시 max size = 1MB
  superAdminPhoneNo: "01053174673"
};
