import { isJson } from "../lib/utils";

export function parseShopInfo({ shopInfo }) {
  const payOptions = isJson(shopInfo.payOptions) ? JSON.parse(shopInfo.payOptions) : {}
  const orderNotifyOptions = isJson(shopInfo.orderNotifyOptions) ? JSON.parse(shopInfo.orderNotifyOptions) : {}
  const noticeOptions = isJson(shopInfo.noticeOptions) ? JSON.parse(shopInfo.noticeOptions) : {}
  const userAppUiOptions = isJson(shopInfo.userAppUiOptions) ? JSON.parse(shopInfo.userAppUiOptions) : {}
  const geoLocation = isJson(shopInfo.geoLocation) ? JSON.parse(shopInfo.geoLocation) : {}
  return { ...shopInfo, payOptions, geoLocation, orderNotifyOptions, noticeOptions, userAppUiOptions }
}

export function parseShopInfo2({ shopInfo }) {

  const names = [
    "geoLocation",
    "payOptions",
    "orderNotifyOptions", "noticeOptions",
    "userAppUiOptions",
  ]
  const newShopInfo = { ...shopInfo }
  names.map(name => {
    newShopInfo[name] = isJson(newShopInfo[name]) ? JSON.parse(shopInfo[name]) :{}
  })
  return newShopInfo
}
