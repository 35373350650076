import React, { useState } from "react";
import { CardContainer, FormInput, Button } from "../../../../components";
import { useDispatch } from "react-redux";
import { setShopInfo } from "../../../../redux/shop";
import { shopUpdate } from "../../../../lib/ec2-api-lib";
import { FormBox } from "../../../../components";
import { routePaths } from "../../../../navigation/routePaths";
import { debug } from "../../../../settings";

const str = {
  keyExistComment: "보안정보가 이미 존재합니다.",
  failure: "저장에 실패했습니다.",
  success: "성공적으로 저장하였습니다."
}

export function PayOptions(props) {
  const { shopInfo } = props

  const dispatch = useDispatch()
  let payOptions = shopInfo.payOptions || {}
  
  const [isLoading, setIsLoading] = useState(false);
  const [isPayable, setIsPayable] = useState(payOptions.isPayable || false)
  const [isPayOnly, setIsPayOnly] = useState(payOptions.isPayOnly || false)
  const [pgName, setPgName] = useState(payOptions.pgName || "KISPG")

  const submitOptions = async () => {
    try {
      setIsLoading(true)

      const body = { payOptions: JSON.stringify({isPayable, isPayOnly, pgName}) }
      const res = await shopUpdate({shopId: shopInfo.shopId, body})
      if (res.err) throw Error(res.err.message)
      if (debug) console.log('res.result.Item', res.result.Item)

      const newShopInfo = {
        ...shopInfo,
        payOptions: JSON.parse(res.result.Item.payOptions)
      }
      dispatch(setShopInfo({ shopInfo: newShopInfo }));

      alert(str.success)
    } catch (e) {
      alert(str.failure + " - " + e.message)
    } finally {
      setIsLoading(false)
      // props.history.push(routePaths.home)
    }
  }

  return (
    <CardContainer
      title="결제 옵션 설정"
      onClickBack={() => props.history.push(routePaths.home)}
      variant="md"
    >
      <FormBox >
        <FormInput
          label="PG(사) 선택"
          name="pgName"
          value={pgName}
          readOnly
        />
      </FormBox> <br />

      <FormBox >
        <FormInput
          label="선결제 기능 사용"
          name="isPayable"
          checked={isPayable}
          onChange={() => setIsPayable(!isPayable)}
          inputType="checkbox"
        />
      </FormBox> <br />
{/* 
      <FormBox>
        <FormInput
          label="결제만 가능 여부"
          name="isPayOnly"
          checked={isPayOnly}
          onChange={() => setIsPayOnly(!isPayOnly)}
          inputType="checkbox"
        />
      </FormBox> */}

      <Button
        size="sm"
        width="100%"
        variant="primary"
        title="확인"
        isLoading={isLoading}
        onClick={submitOptions}
      />

    </CardContainer>
  );
}
