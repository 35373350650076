import { createSlice } from "@reduxjs/toolkit";
import { isTablet, isMobile } from "react-device-detect"

const initialState = {
  isMobileDevice: false,
  isMobilePhone: false,
  isTablet: false,
  platformType: null, // android, ios
  details: null,
};

export const platform = createSlice({
  name: "platform",
  initialState,
  reducers: {
    setPlatform: (state, action) => {
      const { isMobileDevice, details } = checkBrowser();
      const isMobilePhone = isMobileDevice && !isTablet;
      const platformType = getMobileOsType(details);

      // console.log('isMobileDevice', isMobileDevice)
      // console.log('isTablet      ', isTablet)
      // console.log('isMobilePhone ', isMobilePhone)
      // console.log('details       ', details)
      // console.log('platformType  ', platformType)

      state.isMobileDevice = isMobileDevice;
      state.isMobilePhone = isMobilePhone
      state.isTablet = isTablet;
      state.platformType = platformType;
      state.details = details;
    }
  },
});

// Action creators are generated for each case reducer function
export const { setPlatform } = platform.actions;

export default platform.reducer;

const checkBrowser = () => {
  /* Storing user's device details in a variable*/
  let details = navigator.userAgent;

  /* Creating a regular expression 
  containing some mobile devices keywords 
  to search it in details string*/
  let regexp = /android|iphone|kindle|ipad/i;

  /* Using test() method to search regexp in details
  it returns boolean value*/
  let isMobileDevice = regexp.test(details);
  return { isMobileDevice, details };
};

export const getMobileOsType = (details) => {
  const types = ["Android", "iPhone", "Windows NT"];
  let type = null;
  types.forEach((item) => {
    if (details.indexOf(item) > 0) type = item;
  });
  return type;
};