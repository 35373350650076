const orderData = {
  shopId: "a1d3fade80bcdb6ccdc2",
  branch: 'psdl8080',
  tableGroup: 'F001',
  tableNo: 1,
  requestTime: '2024-08-30 11:56:26',
  content: '',
  personCnt: 1,
  orderList: [
    {
      orderNo: '',
      mGroup: '100',
      mGroupName: '메인메뉴',
      mCode: '800001',
      mName: '된장찌개',
      unitPrice: 11000,
      qty: 1,
      amount: 11000,
      dcAmount: 0,
      netAmount: 10000,
      vatAmount: 1000,
      isTakeOut: 0,
      state: '',
      optionList: [
        {
          "mcode": "10001",
          "mName": "테스트 옵션항목",
          "unitPrice": 1000,
          "qty": 1,
          "amount": 1000,
          "dcAmount": 0,
          "netAmount": 0,
          "vatAmount": 0
        }
      ]
    }
  ]
}

export const exampleOrderList = [
  {
    created: Date.now()-10000,
    shopId: "a1d3fade80bcdb6ccdc2",
    orderId: "1234",
    orderData: JSON.stringify(orderData)
  }
]