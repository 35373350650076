import { signup, findPassword } from "./schema/user";
import { 
  shopPosname, 
  shopUnionpos as shop, 
  shopSmilepos, shopOkpos, shopOasispos, shopEasypos,
  shopType, 
  posList 
} from "./schema/shop2";

/** ****************************************************************
 * @inputFields   :
 * 속성명          :필수여부, 디폴트값, 설명
 *  
 * @name          : 필수, string - 테이블의 속성 이름
 * @displayName   : 옵션, ""       - 폼 라벨 명칭
 * @placeholder   : 옵션, "" - input placeholder 와 동일
 * @defaultValue  : 옵션, "" - inputType, type 에 따라 value 값이 다름
 * @comment       : 옵션, "" 
 * @readOnly      : 옵션, false - input의 속성
 * @required      : 옵션, true - false라고 명시하지 않으면 필수 속성임. validate 참조
 * @validate      : 옵션, null | obj={type} - type = email | password | phone_number
 *                       추가 validation 정보
 * @type          : 옵션, text | password | number | email - input value 의 속성
 *                       html input 기본 속성과 동일
 * @inputType     : 옵션, text | password | select | textarea | checkbox | file 
 * @selectList    : 옵션, null | [] // 존재하면 inputType이 selection 임, 첫번째 항목이 default value
 *                       inputType select와 함께 사용함.
 * 
 * @editable      : 옵션, true - false 이면 수정화면에 보이지 않음.  
 * ****************************************************************/

export default {
  signup,       // 회원 등록용
  findPassword, // 비번 수정용

  // 
  // shop관련 form schema, 
  // shopItem | shopItemOption 등은 모두 lib-pos 로 이전함 (기존 schema와 구조가 다름)
  // 
  shopPosname, // 포스명 등록
  shop,        // Unionpos shopInfo
  shopSmilepos, // Smartro shopInfo
  shopOkpos, 
  shopOasispos,
  shopEasypos,

  shopType,
  posList,
};
