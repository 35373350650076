import React from "react";
import { Page } from "../../../../components";
import { useSelector } from "react-redux";
// import { NotiOptions } from "./NotiOpitons";
import { PayOptions } from "./PayOpitons";
import { KisPg } from "./PgKeys";

export default function PaymentSetting(props) {
  const { shopInfo } = useSelector(state => state.shop);

  return (
    <Page>
      <PayOptions {...props} shopInfo={shopInfo} />
      <br />
      <KisPg {...props} shopInfo={shopInfo} />
    </Page>
  );
}
