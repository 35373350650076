import React, { useState } from "react";
import { ChevronD, ChevronU } from "./Icons";
import Calendar from "react-calendar";
import './Calendar.css';
import { gStyle, colors } from "./ui";
import { Button } from "./Buttons";

function CalendarButton({ onClick, value, variant, showCaledar, btnSize, label }) {
  const variants = ['primary', 'info', 'secondary', 'danger'];
  const outlineVariants = ['outline-primary', 'outline-info', 'outline-secondary', 'outline-danger'];

  let textColor = colors.dark;
  if (variants.includes(variant)) textColor = colors.light;
  if (outlineVariants.includes(variant)) textColor = colors[variant.split('-')[1]];

  const styles = {
    wrapper: { width: btnSize, display: 'flex', alignItems: 'center' },
    btn: { width: "100%", justifyContent: "flex-start", },
    icon: { marginLeft: -25, color: textColor, zIndex: 1 }
  }
  return (
    <>
    {label && <p style={{ ...gStyle.text, marginBottom: 5, color: textColor }}>{label}</p>}
    <div style={styles.wrapper}>
      {/* <button onClick={onClick} style={styles.btn}>{value}</button> */}
      <Button title={value} onClick={onClick} variant={variant} style={styles.btn} size="sm" />
      {showCaledar
        ? <ChevronU style={styles.icon} onClick={onClick} />
        : <ChevronD style={styles.icon} onClick={onClick} />}
    </div>
        </>
  );
}

export function CalendarDropdown({ date, setDate, label, variant = "primary", btnSize = "200px" }) {
  const [showCalendar, setShowCalendar] = useState(false)

  const styles = {
    wrap: {
      marginBottom: 15,
      marginRight: 15,
      position: "relative"
    },
    calendarWrap: {
      top: 75,
      left: 0,
      position: "absolute",
      zIndex: 999
    }
  }

  return (
    <div style={styles.wrap}>
      {/* {label && <p style={{ ...gStyle.text, marginBottom: 5 }}>{label}</p>} */}
      <CalendarButton
        showCaledar={showCalendar}
        onClick={() => setShowCalendar(!showCalendar)}
        value={date.toLocaleDateString()}
        variant={variant}
        btnSize={btnSize}
        label={label}
      />
      {showCalendar &&
        <div style={styles.calendarWrap}>
          <Calendar
            value={date}
            onChange={setDate}
            onClickDay={(d) => {
              setShowCalendar(!showCalendar)
              setDate(d)
            }}
          />
        </div>
      }
    </div>
  )
}

export function PeriodWithCalendar(props) {
  const {
    startDate, setStartDate,
    endDate, setEndDate
  } = props
  // const today = new Date()
  // const _startDate = new Date(getTimestampFromNow({ now: new Date(), days: -(days - 1) }))
  // const [startDate, setStartDate] = useState(_startDate)
  // const [endDate, setEndDate] = useState(today)

  const styles = {
    wrap: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      // justifyContent: "space-between"
    }
  }

  return (
    <div style={styles.wrap} >
      <CalendarDropdown
        label="Start Date"
        date={startDate}
        setDate={setStartDate}
      />

      <CalendarDropdown
        label="End Date"
        date={endDate}
        setDate={setEndDate}
      />
    </div>
  )
}