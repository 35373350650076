import React, { useState, useRef } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
// import { ReactComponent as Off } from "../assets/icons/checker-off.svg";
// import { ReactComponent as On } from "../assets/icons/checker-on.svg";
import { Button } from "./Buttons";
import storeImage from "../assets/icons/store_image.svg";
import { useStyles, colors, gStyle } from "./ui";
import { debug } from "../settings";
import { EyeOpen, EyeClose, Trash, CheckedCircle, UnCheckedCircle, TextFile } from "./Icons";

const commonStyles = {
  wrap: { marginBottom: "15px" },
  label: { margin: "0px 20px 0px 0px", fontWeight: "500" }
}

const height = {
  sm: 44,
  lg: 50
}

/**
 *
 * @param {S} props.name
 * @param {S} props.label
 * @param {S} props.type        text | password | number | email - input value 의 속성
 * @param {*} props.value
 * @param {F} props.onChange
 * @param {S} props.placeholder
 * @param {B} props.validated
 * @param {B} props.readOnly
 * @param {S} props.size        sm as default
 * @param {S} props.comment
 * @param {O} props.styles
 *
 * @param {S} props.inputType   text | password | select | textarea | checkbox | file 
 * @param {A} props.selectList 
 *
 * @returns
 */
export function FormInput(props) {
  if (props.inputType === "checkbox") return <CheckBox {...props} />;
  else if (props.inputType === "file") return <FileInput {...props} />;
  else return <Input {...props} />;
}

export function CheckBox({ onChange, className, checked, name, label, style, checkboxStyle }) {
  const onClick = () => {
    // console.log('className', className);
    onChange({ target: { type: "checkbox", name } });
  }

  const myStyles = {
    wrap: {
      display: "flex",
      flexDirection: "row",
      justifyContent: checkboxStyle?.wrap?.justifyContent || "space-between",
      alignItems: "center"
    },
    checkbox: {
      fontSize: checkboxStyle?.checkbox?.fontSize || "18px",
      color: checkboxStyle?.checkbox?.color || colors.primary
    }
  }

  return (
    <div style={style ? { ...commonStyles.wrap, ...style } : commonStyles.wrap}>
      <div style={myStyles.wrap}>
        {/* {label && <p style={commonStyles.label}>{label}</p>} */}
        {label && <p style={gStyle.text}>{label}</p>}
        {
          checked
            ? <CheckedCircle style={myStyles.checkbox} onClick={onClick} />
            : <UnCheckedCircle style={myStyles.checkbox} onClick={onClick} />
          // ? <CheckedCircle style={{ fontSize: styles?.fontSize || "18px", color: colors.primary }} onClick={onClick} />
          // : <UnCheckedCircle style={{ fontSize: styles?.fontSize || "18px", color: colors.primary }} onClick={onClick} />
          // ? <On className={className} onClick={onClick} width={18} />
          // : <Off className={className} onClick={onClick} width={18} />
        }
      </div>
    </div>
  );
}

/**
 * @info_inputTypes text | select 
 * 
 * @param {*} param0 
 * @returns 
 */
export function Input({
  value,
  placeholder,
  inputType,
  onChange,
  className,
  type = "text",
  style, display, width, size = "sm",
  label, name, readOnly,
  validated,
  selectList = [],
}) {

  const { isMobileDevice } = useSelector((state) => state.platform);
  const { gStyle, colors } = useStyles();

  // let inputHeight = isMobileDevice || size === 'sm' ? height.sm : height.lg;
  let inputHeight = height.lg;
  if (isMobileDevice || size === 'sm') inputHeight = height.sm;
  if (inputType === "textarea") inputHeight = "80px";

  const styles = {
    fontSize: gStyle.text.fontSize,
    display: display,
    width: width || "100%",
    height: inputHeight,
    border: "1px solid",
    // borderColor: validated ? colors.secondary : colors.warning,
    borderColor: colors.secondary,
    paddingLeft: 10, paddingRight: 10,
    color: colors.dark,
    // borderRadius: 8,
    borderRadius: 5,
    backgroundColor: readOnly ? colors.readOnly : "white"
  };

  if (inputType === "select") styles.borderColor = colors.secondary;

  const myProps = {
    style: styles, label,
    name, type, className, value, placeholder, onChange, readOnly
  }

  if (inputType === "select") {
    return (
      <div style={commonStyles.wrap}>
        {label && <p style={gStyle.text}>{label}</p>}
        <select  {...myProps} >
          {selectList.map((item, index) => <option key={item + index} style={{ padding: 10 }}>{item}</option>)}
        </select>
      </div>
    )
  }

  if (inputType === "textarea") {
    return (
      <div style={commonStyles.wrap}>
        {label && <p style={commonStyles.label}>{label}</p>}
        <textarea {...myProps} />
      </div>
    )
  }

  if (inputType === "password") {
    return <InputPassword {...myProps} />

    // return (
    //   <div style={commonStyles.wrap}>
    //     {label && <p style={commonStyles.label}>{label}</p>}
    //     <input {...myProps} type={"password"} />
    //     <EyeOpen style={{ marginLeft: "-30px" }} />
    //   </div>
    // );
  }

  return (
    <div style={commonStyles.wrap}>
      {label && <p style={gStyle.text}>{label}</p>}
      <input {...myProps} />
    </div>
  );

  // return (
  //   <div style={commonStyles.wrap}>
  //     {label && <p style={commonStyles.label}>{label}</p>}
  //     {
  //       (inputType === "select")
  //         ?
  //         <select  {...myProps} >
  //           {selectList.map((item, index) => <option key={item + index} style={{ padding: 10 }}>{item}</option>)}
  //         </select>
  //         : (inputType === "textarea")
  //           ? <textarea {...myProps} />
  //           : <input {...myProps} />
  //     }
  //   </div>
  // );
}

function InputPassword(props) {
  const { label } = props;
  const [seen, setSeen] = useState(false);

  return (
    <div style={commonStyles.wrap}>
      {label && <p style={commonStyles.label}>{label}</p>}
      <input
        {...props}
        type={seen ? "text" : "password"}
      />
      {
        seen
          ? <EyeOpen style={{ marginLeft: "-30px" }} onClick={() => setSeen(!seen)} />
          : <EyeClose style={{ marginLeft: "-30px" }} onClick={() => setSeen(!seen)} />
      }

    </div>
  )
}

export function FileInput(props) {
  const {
    label,
    value,
    size = "sm",
    onChangeInputFile, onClickRemoveImage,
    title = "사진 선택",
    accept = 'image/*'
  } = props;

  const imgInput = useRef();
  // const [imageUrl, setImageUrl] = useState({});
  const [imagePreview, setImagePreview] = useState(value);
  const [filename, setFilename] = useState("")
  const [selectedImageFile, setSelectedImageFile] = useState(null);

  const onSelectImage = (event) => {
    if (debug) console.log('event.target.files', event.target.files);
    if (event.target.files && event.target.files.length) {
      setSelectedImageFile(event.target.files[0]);
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
        setImagePreview(URL.createObjectURL(event.target.files[0]));  // 이미지 미리보기 
      }
      if (event.target.files[0].name.match(/\.(pem)$/)) {
        setFilename(event.target.files[0].name);  // 파일명 보기
      }
      onChangeInputFile(event);
    }
  }

  const onClickImage = () => {
    imgInput.current.click();
  }

  const styles = {
    txt: { fontSize: "16px", color: "#2b2b2b", },
    imgPreview: { marginTop: 3, marginBottom: 3, width: '100%', borderRadius: 8 },
  }

  return (
    <div style={{ ...commonStyles.wrap, ...styles.txt }}>
      {label && <p style={commonStyles.label}>{label}</p>}
      <input
        style={{ display: 'none' }}
        type='file'
        accept={accept}
        ref={imgInput}
        onChange={onSelectImage}
      />

      <Button title={title} onClick={onClickImage} variant="outline-secondary" width="100%" size={size}>
        {
          accept === "image/*"
            ? <img src={storeImage} width={20} height={20} alt="image icon" />
            : <TextFile />
        }
        &nbsp;&nbsp;
      </Button>
      {imagePreview &&
        <div>
          <p style={{ margin: "5px 0" }}
            onClick={() => {
              setImagePreview("")
              onClickRemoveImage()
            }}
          ><Trash /> 기존이미지 삭제</p>
          <img src={imagePreview} style={styles.imgPreview} alt="pre-loaded" />
          <p style={{ fontSize: 12, textAlign: 'left', margin: 0 }}>
            첨부 파일: {selectedImageFile ? selectedImageFile.name : ""}
          </p>
        </div>
      }
      {filename &&
        <div>
          <p style={{ fontSize: 12, textAlign: 'left', margin: 0 }}>
            첨부 파일: {selectedImageFile ? selectedImageFile.name : ""}
          </p>
        </div>
      }
    </div>
  )
}

/**
 *
 * @param {S} props.onClick
 * @param {*} props.btnText
 * @param {*} props.size
 * @param {*} props.validated
 * @param {*} props.disabled
 * @returns_bootstrap_FormControl
 */
export function InputWithBtn(props) {
  const { onClick, btnText, size, validated, disabled } = props;
  // if (debug) console.log("disabled?", !validated || disabled === true);
  const styles = {
    wrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end'
    },
    inputWrap: { width: "60%" },
    btnWrap: { width: "35%", ...commonStyles.wrap }
  };
  return (
    <div style={styles.wrap}>
      <div style={styles.inputWrap}>
        <FormInput {...props} size={size} style={{ marginBottom: 0 }} />
      </div>
      <div style={styles.btnWrap}>
        <Button
          width="100%"
          variant="info"
          size={size}
          disabled={!validated || disabled === true}
          onClick={onClick}
        >
          {btnText}
        </Button>
      </div>
    </div>
  );
}

export const Search = (props) => {
  const {
    onChange,
    onClick,
    value,
    placeholder,
    btnDisabled,
    btnText,
    onKeyUp,
  } = props;
  return (
    <Form.Group as={Row} controlId="search-text">
      <Col sm="3">
        <Form.Control
          size="sm"
          type="text"
          onChange={onChange}
          placeholder={placeholder || "search text here..."}
          onKeyPress={onKeyUp}
          value={value || ""}
        />
      </Col>
      <Col sm="9">
        <Button
          size="sm"
          variant="outline-secondary"
          onClick={onClick}
          disabled={btnDisabled === "true"}
        >
          {btnText || "Search"}
        </Button>
      </Col>
    </Form.Group>
  );
};

export const SelectFilters = (props) => {
  const { defaultValue, list, onChange, onClick } = props;
  return (
    <Form.Group as={Row} controlId="categoryInput">
      <Col sm="3">
        <Form.Control
          onChange={onChange}
          size="sm"
          type="text"
          as="select"
          value={defaultValue}
        >
          {list.map((item, index) => {
            return <option key={index}>{item.name}</option>;
          })}
        </Form.Control>
      </Col>
      {/* <Form.Label column sm="1">Filter </Form.Label> */}
      <Button
        size="sm"
        variant="outline-secondary"
        onClick={onClick}
      >
        Filter
      </Button>
    </Form.Group>
  );
};
