import { createSlice } from "@reduxjs/toolkit";

/**
 * @Using_Slices 
 *  참조: https://redux-toolkit.js.org/usage/usage-guide 
 *  > Exporting and Using Slices
 * 
 * @Defining_Async_Logic_in_Slices
 *  참조: https://redux-toolkit.js.org/usage/usage-guide 
 *  > 우선은 간단한 reducer만 채용하고 API call은 추후에 진행함.
 * 
 * @RTK_Query
 *  참조: https://redux-toolkit.js.org/tutorials/rtk-query
 *  > Thunk 혹은 rtk query 를 사용할 것인지.....
 *  > RtkQuery는 Fetch를 사용한다?????
 *  > 따라서 AWS amplify  는 사용할 수 없다??????
 */

const initialState = {
  username: "",
  isSigned: false,
  user: {
    userType: 'shop',
  }
};

export const user = createSlice({
  name: "user",
  initialState,
  reducers: {    
    setUsername: (state, action) => {  
      /**
       * @info
       * action.payload => dispatch(fn(payload)) 에서와 같이 대응된다.
       * 즉 reducer함수의 파라미터는 action.payload에 전달된다.
       */
      state.username = action.payload.username;
      state.isSigned = true;
    },
    resetUsername: (state, action) => {
      state.user = initialState.user;
      state.username = initialState.username;
      state.isSigned = initialState.isSigned;
      // state = initialState;
    },
    setUser: (state, action) => {
      state.user = action.payload.user;
      state.isSigned = true;
    },
  },
});

export const { setUser, setUsername, resetUsername } = user.actions;

export default user.reducer;
