import {
  okposProxy,
  okposItemOptionGet, okposItemOptionCreate, okposItemOptionUpdate,
  okposItemUpdate, okposItemCreate, okposItemDelete, okposItemList
} from "../../lib/ec2-api-lib";
import { shopItem } from "./okpos-menu-db";
import { getMasterInfo, cleanupOnlOptionItemList, cleanupTuClsItemList } from "./okpos-utils";
import { isJson, delay } from "../../lib/utils";
import { debug } from "../../settings";

const Texts = {
  noItemFound: "NO ITEM FOUND",
  alertFail: "정보를 가져오는 데 실패했습니다!",
  alertFailUpdate: "정보를 수정하는 데 실패했습니다!",
}

const _createTableList = ({ TableList, TableList_pre }) => {
  const newList = TableList.map(table => {
    const preTables = TableList_pre.filter(tbl_pre => tbl_pre.TABLE_CD === table.TABLE_CD)
    if (preTables.length === 1) {
      return {
        ...preTables[0],
        ...table,
        TableCode: table.TABLE_CD,
        TableName: table.TABLE_NM,
        TableNo: table.TABLE_CD,
      }
    } else {
      return {
        ...table,
        TableCode: table.TABLE_CD,
        TableName: table.TABLE_NM,
        TableNo: table.TABLE_CD,
        // FLOOR_NO: table.TABLE_GR_CD,
      }
    }
  })

  return newList
}

const _createTuClsList = ({ TuClsList, TuClsList_pre }) => {

  const newList = TuClsList.map(grp => {

    let newGrp = {};
    let grp_pre = [];

    grp_pre = TuClsList_pre.filter(grpPre => grpPre.TU_CLS_CD === grp.TU_CLS_CD);

    if (grp_pre.length === 1) {
      newGrp = { ...grp_pre[0], ...grp }
      if (grp_pre[0].name === undefined) newGrp.name = grp.TU_CLS_NM
      if (grp_pre[0].TU_CLS_NM !== grp.TU_CLS_NM) {
        newGrp.name = grp.TU_CLS_NM
        newGrp.GrpName = grp.TU_CLS_NM
        newGrp.displayName = grp.TU_CLS_NM
      }

    } else {
      newGrp = {
        ...grp,
        GrpCode: grp.TU_CLS_CD,
        name: grp.TU_CLS_NM,
        GrpName: grp.TU_CLS_NM,
        displayName: grp.TU_CLS_NM,
        sortOrder: 0,
        hidden: false
      }
    }
    return newGrp;
  })
  return newList
}

const _createGrpList = ({ GrpList, GrpList_pre }) => {
  // 
  // GrpList 를 기준으로 매핑하면 자연스럽게 GrpList_pre에서 삭제하는 효과가 있다.
  // 
  let newList = GrpList.map(grp => {

    let newGrp = {};
    let grp_pre = [];

    grp_pre = GrpList_pre.filter(grpPre => grpPre.GrpCode === grp.LCLS_CD);

    // 
    // pre group이 있으며 유지 merge
    //
    if (grp_pre.length === 1) {
      newGrp = { ...grp, ...grp_pre[0] }

      // 
      // name이_없을 경우를 대비
      //
      if (grp_pre[0].name === undefined) newGrp.name = grp.LCLS_NM

      // 
      // name이_변경되면_displayName도_변경
      // 
      newGrp.displayName = (grp_pre[0].name !== grp.LCLS_NM)
        ? grp.LCLS_NM
        : grp_pre[0].displayName
    }

    // 
    // pre group이 없으면 생성
    //
    else {
      newGrp = {
        ...grp,
        GrpCode: grp.LCLS_CD,  // LCLS_CD - UnionPos와 호환
        name: grp.LCLS_NM,     // LCLS_NM - UnionPos와 호환
        GrpName: grp.LCLS_NM,
        displayName: grp.LCLS_NM,
        sortOrder: 0,
        hidden: false
      }
    }
    return newGrp;
  });

  return newList;
}

const _createMidGrpList = ({ MidGrpList, MidGrpList_pre }) => {
  // 
  // GrpList 를 기준으로 매핑하면 자연스럽게 GrpList_pre에서 삭제하는 효과가 있다.
  // 
  let newList = MidGrpList.map(grp => {

    let newGrp = {};
    let grp_pre = [];

    grp_pre = MidGrpList_pre.filter(grpPre =>
      grpPre.MCLS_CD === grp.MCLS_CD
    );

    // 
    // pre group이 있으며 유지 merge
    //
    if (grp_pre.length === 1) {
      newGrp = { ...grp, ...grp_pre[0] }

      // 
      // name이_없을 경우를 대비
      //
      if (grp_pre[0].name === undefined) newGrp.name = grp.MCLS_NM

      // 
      // name이_변경되면_displayName도_변경
      // 
      newGrp.displayName = (grp_pre[0].name !== grp.MCLS_NM)
        ? grp.MCLS_NM
        : grp_pre[0].displayName
    }

    // 
    // pre group이 없으면 생성
    //
    else {
      newGrp = {
        ...grp,
        GrpCode: grp.LCLS_CD,  // LCLS_CD - UnionPos와 호환
        MidGrpCode: grp.MCLS_CD,
        name: grp.MCLS_NM,     // LCLS_NM - UnionPos와 호환
        displayName: grp.MCLS_NM,
        sortOrder: 0,
        hidden: false
      }
    }
    return newGrp;
  });

  return newList;
}

const _createSmGrpList = ({ SmGrpList, SmGrpList_pre }) => {
  // 
  // GrpList 를 기준으로 매핑하면 자연스럽게 GrpList_pre에서 삭제하는 효과가 있다.
  // 
  let newList = SmGrpList.map(grp => {

    let newGrp = {};
    let grp_pre = [];

    grp_pre = SmGrpList_pre.filter(grpPre =>
      grpPre.SCLS_CD === grp.SCLS_CD
    );

    // 
    // pre group이 있으며 유지 merge
    //
    if (grp_pre.length === 1) {
      newGrp = { ...grp, ...grp_pre[0] }

      // 
      // name이_없을 경우를 대비
      //
      if (grp_pre[0].name === undefined) newGrp.name = grp.SCLS_NM

      // 
      // name이_변경되면_displayName도_변경
      // 
      newGrp.displayName = (grp_pre[0].name !== grp.SCLS_NM)
        ? grp.SCLS_NM
        : grp_pre[0].displayName
    }

    // 
    // pre group이 없으면 생성
    //
    else {
      newGrp = {
        ...grp,
        GrpCode: grp.LCLS_CD,  // LCLS_CD - UnionPos와 호환
        MidGrpCode: grp.MCLS_CD,
        SmGrpCode: grp.SCLS_CD,
        name: grp.SCLS_NM,     // LCLS_NM - UnionPos와 호환
        displayName: grp.SCLS_NM,
        sortOrder: 0,
        hidden: false
      }
    }
    return newGrp;
  });

  return newList;
}

const _createSdsClsList = ({ SdsClsList, SdsClsList_pre }) => {
  let newList = SdsClsList.map(cls => {
    const key = "SDS_CLS_CD"
    let cls_pre = SdsClsList_pre.filter(clsPre => clsPre[key] === cls[key]);
    if (cls_pre.length === 1) return { ...cls_pre[0], ...cls }
    else return cls;
  });
  return newList;
}

const setItemOptions = async ({ shopId, preOptions, isNew, optionName, masterInfo, setComment }) => {
  try {

    setComment("Createing ItemOptions...")

    const d = masterInfo;

    let GrpList = d["CLSLMCODE"] || [];
    let MidGrpList = d["CLSMMCODE"] || [];
    let SmGrpList = d["CLSSMCODE"] || [];

    let SdaClsList = d["SDACLCODE"] || [];  // SDACLCODE
    let SdaCdList = d["SDACDCODE"] || [];   // SDACDCODE

    let SdsGrList0 = d["SDSGRCODE"] || [];  // SDSGRCODE
    let SdsClsList0 = d["SDSCLCODE"] || [];  // SDSCLCODE
    let SdsCdList0 = d["SDSCDCODE"] || [];   // SDSCDCODE

    let SdsGrList = []
    let SdsClsList = [];
    let SdsCdList = [];

    /**
     * @USE_YN 이 N 이면 삭제함
     */
    SdsGrList0.map(item=>{
      if(item["USE_YN"] === "Y") SdsGrList.push(item)
      return null
    })

    SdsClsList0.map(item=>{
      if(item["USE_YN"] === "Y") SdsClsList.push(item)
      return null
    })

    SdsCdList0.map(item=>{
      if(item["USE_YN"] === "Y") SdsCdList.push(item)
      return null
    })

    let TableList = d["TABLE"] || [];       // TABLE

    let TuClsList = d["TUCLSCODE"] || [];
    // let TuKeyList = d["TUKEYCODE"] || []; // itemList에 포함

    let TuClsList_pre = [];
    let GrpList_pre = [];
    let MidGrpList_pre = [];
    let SmGrpList_pre = [];
    let TableList_pre = [];
    let SdsClsList_pre = []

    if (!isNew) {
      if (isJson(preOptions.TuClsList)) TuClsList_pre = JSON.parse(preOptions.TuClsList);
      if (isJson(preOptions.GrpList)) GrpList_pre = JSON.parse(preOptions.GrpList);
      if (isJson(preOptions.MidGrpList)) MidGrpList_pre = JSON.parse(preOptions.MidGrpList);
      if (isJson(preOptions.SmGrpList)) SmGrpList_pre = JSON.parse(preOptions.SmGrpList);
      if (isJson(preOptions.TableList)) TableList_pre = JSON.parse(preOptions.TableList);
      if (isJson(preOptions.SdsClsList)) SdsClsList_pre = JSON.parse(preOptions.SdsClsList);
    }

    // const newGrpList = _createGrpList2({ groupList: GrpList, groupList_pre: GrpList_pre, type: "Grp" });
    // const newMidGrpList = _createGrpList2({ groupList: MidGrpList, groupList_pre: MidGrpList_pre, type: "MidGrp" });
    // const newSmGrpList = _createGrpList2({ groupList: SmGrpList, groupList_pre: SmGrpList_pre, type: "SmGrp" });

    const newTuClsList = _createTuClsList({ TuClsList, TuClsList_pre });

    // console.table(newTuClsList)
    // throw Error("TuClsList")

    const newGrpList = _createGrpList({ GrpList, GrpList_pre });
    const newMidGrpList = _createMidGrpList({ MidGrpList, MidGrpList_pre });
    const newSmGrpList = _createSmGrpList({ SmGrpList, SmGrpList_pre });
    const newTableList = _createTableList({ TableList, TableList_pre })
    const newSdsClsList = _createSdsClsList({ SdsClsList, SdsClsList_pre })  // required 포함

    let res = {}

    // 
    // 신규이면 dummy itemOption 생성
    // 
    if (isNew) {
      res = await okposItemOptionCreate({ shopId, body: { GrpList: JSON.stringify([]) } })
      if (res.err) throw Error(res.err.message + " - itemOptions create");
    }

    const bodyList = [
      { TuClsList: JSON.stringify(newTuClsList) },
      { GrpList: JSON.stringify(newGrpList), },
      { MidGrpList: JSON.stringify(newMidGrpList), },
      { SmGrpList: JSON.stringify(newSmGrpList), },
      { SdaClsList: JSON.stringify(SdaClsList), },
      { SdaCdList: JSON.stringify(SdaCdList) },
      { SdsGrList: JSON.stringify(SdsGrList) },
      { SdsClsList: JSON.stringify(newSdsClsList) }, // required 포함
      { SdsCdList: JSON.stringify(SdsCdList) },
      { TableList: JSON.stringify(newTableList) },
    ]

    let errMsg = ""
    for (let i = 0; i < bodyList.length; i++) {
      const optionItem = bodyList[i]
      setComment(`Updating ItemOption ${Object.keys(optionItem)[0]}`)

      const params = { shopId, body: optionItem }
      res = await okposItemOptionUpdate(params)
      if (res.err) {
        errMsg = res.err.message + " - itemOptions update";
        break;
      }
    }
    if (errMsg !== "") throw Error(errMsg)
    return {}
  } catch (e) {
    if (debug) console.log(e)
    return { err: { message: e.message } };
  }
}

const createMenuItems = async ({ masterInfo }) => {
  try {
    let posItemList = masterInfo["PRODS"] || [];
    return { posItems: posItemList };
  } catch (e) {
    return { err: { message: e.message } };
  }
}

const cleanUpItemList = async ({ shopId, posItems, itemList, setComment }) => {
  try {
    let newItemList = [];
    let delItemList = [];

    await Promise.all(itemList.map(async (serverItem, i) => {
      const index = posItems.findIndex(posItem => posItem.PROD_CD === serverItem.itemCode);
      if (index < 0) delItemList.push(serverItem)
      else newItemList.push(serverItem);
      return null;
    }));

    let errMsg = ""
    for (let i = 0; i < delItemList.length; i++) {
      const delItem = delItemList[i]
      const cmt = `Menu Item ${delItem.itemCode}, ${i + 1} of ${delItemList.length}`

      setComment(`Deleting ${cmt}`)
      let res = await okposItemDelete({ shopId, id: delItem.id })
      if (res.err) {
        errMsg = "메뉴아이템 삭제 중 에러가 발생했습니다. 한번 더 수행해 주세요!"
        break;
      }
    }
    if (errMsg !== "") throw new Error(errMsg);

    return { posItems, newItemList }
  } catch (e) {
    return { err: { message: e.message } };
  }
}

const savePosItems = async ({ posItems, shopId, newItemList, inputFields, preOptions, setComment }) => {
  try {

    const serverItems = newItemList;
    // const newItemList2 = []

    let errMsg = ""
    for (let i = 0; i < posItems.length; i++) {
      const posItem = posItems[i]
      const index = serverItems.findIndex((serverItem) => serverItem.itemCode === posItem.PROD_CD);
      let itembody = {};
      let res;
      const cmt = `Menu Item ${posItem.PROD_CD}, ${i + 1} of ${posItems.length}`

      /** itemCode가 없으면 신규 아이템이므로 create 함 */
      if (index < 0) {
        setComment(`Creating ${cmt}`)
        itembody = createItemByInputFields({ inputFields, posItem, serverItem: {}, idx: i });
        res = await okposItemCreate({ shopId, id: "", body: itembody })
      }

      /** 동일한 itemCode가 있으면 update 함 */
      else {
        setComment(`Updating ${cmt}`)
        itembody = createItemByInputFields({ inputFields, posItem, serverItem: serverItems[index], idx: i });
        res = await okposItemUpdate({ shopId, id: serverItems[index].id, body: itembody })
      }

      // newItemList2.push(itembody)

      if (res.err) {
        errMsg = res.err.message
        break;
      }
    }
    if (errMsg !== "") throw new Error(errMsg);

    return {};
  } catch (e) {
    return { err: { message: e.message } }
  }
}

/**
 * @info_메뉴항목_생성_변경에서_가장_중요한_함수임 
 * 
 *  1. field.posName 이 있는 경우 
 *    1.1 posItem이 있는 경우
 *    1.2 posItem이 없는 경우 (undefined) -> 스킵
 *  2. field.name 만 있는 경우
 *    2.1. 오늘서버에 항목이 있는 경우
 *    2.2. 오늘서버에 항목이 없는 경우 serverItem==={} - 처음 가져올 때
 *      2.2.1 field.defaultValue.posName 있는 경우
 *      2.2.2 field.defaultValue 가 value 인 경우
 */
function createItemByInputFields({ inputFields, posItem, serverItem, idx }) {
  let newItem = {};
  for (let i = 0; i < inputFields.length; i++) {
    let field = inputFields[i]; // 형태 : { name: "itemName", posName: "ItemName" }

    /** 1. field.posName 있는 경우 - 포스메뉴 전용 속성 */
    if (field.posName !== undefined) {

      /** 1.1 posItem이 없으면, 즉 undefined 이면 저장하지 않음. */
      if (posItem[field.posName] !== undefined) {
        newItem[field.name] = posItem[field.posName];
      }

      /** 2. field.name 만 있는 경우 - ONL 편집 가능 속성 */
    } else {

      /** 2.1 오늘 서버에 항목이 있는 경우 */
      if (serverItem[field.name] !== undefined) {
        newItem[field.name] = serverItem[field.name];

        /** menu명이 변경되면 itemName2 - displayName도 변경됨. */
        if (
          field.name === "itemName2"
          && (serverItem["itemName"] !== posItem["PROD_NM"])
        ) {
          newItem["itemName2"] = posItem["PROD_NM"]
        }
      }

      /** 2.2 오늘 서버에 항목이 없는 경우 - 아이템 생성 */
      else {

        /** 2.2.1 기본값을 posItem에서 가져옴 */
        if (field.defaultValue?.posName !== undefined) {
          newItem[field.name] = posItem[field.defaultValue.posName];
        }

        /** 2.2.2 기본값을 defaultValue로  */
        else {
          // 
          // v0.3.9 부터 적용 포스 정보를 기본으로 세팅함.
          // 
          if (field.name === "sortOrder") newItem[field.name] = idx;
          if (field.name === "image") newItem[field.name] = "";
          if (field.name === "removed") newItem[field.name] = false;
          if (field.name === "isSoldout") newItem[field.name] = posItem["SOLD_OUT_YN"] === "Y";
          if (field.name === "isNew") newItem[field.name] = false;
          if (field.name === "isHot") newItem[field.name] = false;
          if (field.name === "isBest") newItem[field.name] = false;

          // description 기본값 undefined
          // nameLang 기본값 undefined
        }
      }
    }
  }
  return newItem;
}

async function updateOkposMenu2({ shopId, masterInfo, itemList, setComment }) {
  try {
    // 
    // shpItemOption 테이블에 저장할 key { shopId, optionName:"OKPOS" }
    // 
    const optionName = "OKPOS";

    let preOptions = {};
    let isNew = true;
    // let itemList =;
    let res;

    // 
    // pre itemOptions
    // 
    res = await okposItemOptionGet({ shopId });
    if (res.err) {
      if (res.err.message !== Texts.noItemFound)
        throw new Error(Texts.alertFail + " - itemOptionGet OKPOS");
    } else {
      preOptions = res.result?.Item;
      isNew = false;
    }

    let resp;

    resp = await setItemOptions({ shopId, preOptions, isNew, optionName, masterInfo, setComment });
    if (resp.err) throw new Error(resp.err.message);

    resp = await createMenuItems({ masterInfo });
    if (resp.err) throw new Error(resp.err.message + " - createMenuItems");


    resp = await cleanUpItemList({
      shopId,
      posItems: resp.posItems,
      itemList,
      setComment
    });
    if (resp.err) throw new Error(resp.err.message);

    if (debug) {
      console.log('pre itemList len          ', itemList.length)
      console.log('posItems len              ', resp.posItems.length)
      console.log('itemList len after cleanUp', resp.newItemList.length)
    }

    resp = await savePosItems({
      posItems: resp.posItems,
      shopId,
      itemList,
      newItemList: resp.newItemList,
      inputFields: shopItem.inputFields,
      optionName,
      preOptions,
      setComment
    });
    if (resp.err) throw new Error(resp.err.message);

    return {};
  } catch (e) {
    return { err: { message: e.message } }
  }
}

async function setPolicy({ shopInfo, setComment }) {
  setComment(`Setting Policy...`)
  return okposProxy({
    apiName: "setPolicy",
    body: {
      storeCode: shopInfo.storeCode
    }
  })
}

export async function okposMenuUpdate({ shopInfo, itemList, itemOptions, setComment }) {
  const delayTime = 500
  try {

    const resSetPolicy = await setPolicy({ shopInfo, setComment })
    if (resSetPolicy.err) {
      if (debug) console.log('Error', resSetPolicy.err)
      alert("프린트 설정에 문제가 있습니다. 관리자에게 문의하세요. 메뉴 업데이트는 계속 진행됩니다.")
    }

    if (debug) console.log('res setPolicy', resSetPolicy.result)

    // throw Error('Test setPolicy')

    await delay(delayTime)

    const resMaster = await getMasterInfo({ storeCode: shopInfo.storeCode, setComment })
    if (resMaster.err) throw Error(resMaster.err.message)

    const resMenuUpdate = await updateOkposMenu2({
      shopId: shopInfo.shopId,
      masterInfo: resMaster.result.Item,
      itemList,
      itemOptions,
      setComment
    });
    if (resMenuUpdate.err) throw Error(resMenuUpdate.err.message);

    await delay(delayTime)

    // 
    // Option itemList, classList, groupList 정리
    // 
    let resItemList = await okposItemList({ shopId: shopInfo.shopId })
    if (resItemList.err) throw new Error(resItemList.err.message);

    let resCleanupOnlOption = await cleanupOnlOptionItemList({ shopInfo, itemList: resItemList.result.Items, setComment })
    if (resCleanupOnlOption.err) throw new Error(resCleanupOnlOption.err.message);

    let resCleanupTuClsList = await cleanupTuClsItemList({ shopInfo, itemList: resItemList.result.Items, setComment })
    if (resCleanupTuClsList.err) throw new Error(resCleanupTuClsList.err.message);

    return { result: { message: "Menu Updated Successfully!" } }
  } catch (e) {
    return { err: { message: e.message } };
  }
}
