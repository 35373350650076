import React, { useEffect, useRef } from "react";
import { Page, CardContainer, } from "../../components";
// import IconExamples from "./IconExamples";
// import ButtonExamples from "./ButtonExamples";
// import TextExamples from "./TextExamples";
// import FormExamples from "./FormExamples";
// import AlarmSound from "./AlarmSound";
// import CustomDropdownTest from "./customDD/CustomDropdownTest";
// import CalendarTest from "./CalendarTest";
import LzStringTest from "./LzStringTest";
import { gStyle as styles, colors } from "../../components";

export default function UiViewTest(props) {

  useEffect(()=>{
    // document.body.requestFullscreen();
  }, [])

  const myStyle = {
    padding: "40px",
    title: {
      ...styles.title,
      ...styles.bold,
      color: colors.primary,
      textAlign: "center",
      fontSize: "40px"
    },
  } 

  return (
    <Page>
      <h1 style={myStyle.title}>UI Components</h1>
      <br />

      <LzStringTest />

      {/* <CardContainer title="CalendarDropdown" padding={myStyle.padding} variant="md">
        <CalendarTest />
      </CardContainer> */}

      {/* <CardContainer title="Form Styling" padding={myStyle.padding} variant="md">
        <FormExamples />
      </CardContainer> */}

      {/* <CardContainer title="Custom DropDown Example" padding={myStyle.padding} variant="lg">
        <CustomDropdownTest />
      </CardContainer> */}

      {/* <CardContainer title="알람 음 처리" padding={myStyle.padding} variant="sm">
        <AlarmSound />
      </CardContainer> */}

      {/* <CardContainer title="React Bootstrap Icons" padding={myStyle.padding} variant="md">
        <IconExamples />
      </CardContainer> */}

      {/* <CardContainer title="Buttons" padding={myStyle.padding} variant="lg">
        <ButtonExamples />
      </CardContainer> */}

      {/* <CardContainer title="Text Styling" padding={myStyle.padding}>
        <TextExamples />
      </CardContainer> */}

    </Page>
  )
}
