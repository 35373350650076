import React, { useEffect, useState } from "react";
import { ModalForm, Button } from "../../../components";
import { delOrderStatus, updateOrderStatus, sendSmsText } from "../../../lib/ec2-api-lib";
import { sendKakaoAlim } from "../../../lib/pay-api-lib";
import { orderStatus } from "./index";
import { getOrderSummay } from "../../../lib-pos/getOrderSummary";
import { debug } from "../../../settings";

const str = {
  noItemFound: "NO ITEM FOUND",
  alertFail: "정보를 가져오는 데 실패했습니다!",
  alertFailUpdate: "정보를 수정하는 데 실패했습니다!",
  unknown: "알 수 없는 에러가 발생하였습니다!",
  orderDel: "삭제",
  orderCancel: "주문 취소"
}

const modes = {
  acceptOrderInfo: "ACCEPT_ORDER",
  serveReadyInfo: "SERVE_MENU",
  delOrder: "DELETE_ORDER"
}

export default function ModalOrderStatus(props) {
  const {
    visible,
    setVisible,
    shopInfo,
    orderInfo,
    setOrderList,
    itemOptions, itemList
  } = props;

  const title = orderInfo.orderSerialNo + " 주문 처리"
  const [isLoading, setIsLoading] = useState(false)

  const onClick = async (mode) => {
    try {
      setIsLoading(true)

      let res = {}
      if (mode === modes.acceptOrderInfo) {

        res = await _updateStatus(orderStatus.accepted);
        if (res.err) throw Error(res.err.message);

        if (shopInfo.orderNotifyOptions?.toUser) {
          // res = await notifyToUser(`${orderInfo.orderSerialNo}번 주문이 접수되었습니다.`)
          res = await sendKakao(orderStatus.accepted)
          if (res.err) throw Error(res.err.message);
          alert("주문 접수 통보 완료!")
        }
      }

      else if (mode === modes.serveReadyInfo) {

        res = await _updateStatus(orderStatus.serveReady);
        if (res.err) throw Error(res.err.message);

        if (shopInfo.orderNotifyOptions?.toUser) {
          // res = await notifyToUser(`${orderInfo.orderSerialNo}번 메뉴가 준비되었습니다.`)
          res = await sendKakao(orderStatus.serveReady)
          console.log('res serveReady', res)
          if (res.err) throw Error(res.err.message);
          alert("메뉴 준비 통보 완료!")
        }
      }

      else if (mode === modes.delOrder) {
        res = await delOrderStatus({ shopId: shopInfo.shopId, orderId: orderInfo.orderId });
        if (res.err) throw Error(res.err.message);

        setOrderList(pre => {
          const newOrderList = [...pre]
          const index = newOrderList.findIndex(order => order.orderId === orderInfo.orderId);
          newOrderList.splice(index, 1);
          return newOrderList;
        });
      }

      else {
        throw Error(str.unknown)
      }

    } catch (e) {
      alert(e.message)
    } finally {
      setIsLoading(false)
      setVisible(false)
    }
  }

  const notifyToUser = async (txt) => {
    // console.log(shopInfo.orderNotifyOptions?.toUser, txt)
    const phone_number = orderInfo.phone_number
    const body = { textContent: txt }
    return sendSmsText({ phone_number, body })
  }

  const sendKakao = async (type) => {
    try {
      console.log('='.repeat(50), "orderSummary")
      console.log('type     :', type)
      console.log('orderNo  :', orderInfo.orderSerialNo)
      console.log('phoneNo  :', orderInfo.phone_number)

      const { orderName, orderAmt, err } = getOrderSummay({
        shopInfo,
        orderData: orderInfo.orderData,
        itemList,
        itemOptions
      })
      if (err) throw Error(err.message)

      console.log('orderName  :', orderName)
      console.log('orderAmt   :', orderAmt)

      return sendKakaoAlim({
        type,
        orderNo: orderInfo.orderSerialNo,
        orderName,
        orderAmt: orderAmt + "원",
        phone_number: orderInfo.phone_number
      })
    } catch (e) {
      return { err: { message: e.message } }
    }
  }

  const _updateStatus = async (orderStatus) => {

    let res = await updateOrderStatus({
      shopId: shopInfo.shopId,
      orderId: orderInfo.orderId,
      body: { orderStatus }
    });
    if (res.err) return { err: { message: res.err.message } }

    if (debug) console.log('res', res.result)

    setOrderList(pre => {
      const newOrderList = [...pre]
      const idx = newOrderList.findIndex(order => order.orderId === orderInfo.orderId);
      const newOrder = { ...newOrderList[idx], orderStatus }
      newOrderList.splice(idx, 1, newOrder);
      return newOrderList;
    });
    return {}
  }

  const styles = {
    wrap: { width: "100%", marginTop: 15 }
  }

  return (
    <ModalForm
      visible={visible}
      setIsVisible={setVisible}
      title={title}
      backdrop="static"
    >
      <div style={styles.wrap}>

        {orderInfo.orderStatus === orderStatus.accepted &&
          <Button
            variant="info"
            size="sm"
            width="100%"
            title={'메뉴 준비완료 ' + (shopInfo.orderNotifyOptions?.toUser ? "알림" : "")}
            onClick={() => onClick(modes.serveReadyInfo)}
            isLoading={isLoading}
          />
        }

        {orderInfo.orderStatus === orderStatus.ordered &&
          <Button
            variant="primary"
            size="sm"
            width="100%"
            title={'주문 접수 ' + (shopInfo.orderNotifyOptions?.toUser ? "알림" : "")}
            onClick={() => onClick(modes.acceptOrderInfo)}
            isLoading={isLoading}
          />
        }

        <br />

        <Button
          variant="secondary"
          size="sm"
          // title={orderInfo.orderStatus === orderStatus.serveReady ? str.orderDel : str.orderCancel}
          title={str.orderDel}
          onClick={() => onClick(modes.delOrder)}
          isLoading={isLoading}
        />
      </div>
    </ModalForm>
  )
}
