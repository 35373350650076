
export function getSelectedItems({ posName, itemList, group, showRemoved }) {

  let selectedItems = []

  if (posName === "OKPOS") {
    let selItems = []
    let selItems2 = []

    // 
    // tuCls.itemList 기준으로 items 선택
    // 
    const itemCodeList = group.itemList || []
    if (itemCodeList.length > 0) {
      itemCodeList.map(itemCode => {
        let items = itemList.filter(item => item.itemCode === itemCode)
        if (items.length < 1) return null
        // if (!items[0].removed || showRemoved) selItems.push(items[0])
        selItems.push(items[0])
        return null
      })
    }

    // 
    // item.tuClassList 기준으로 items 선택
    // 
    selItems2 = itemList.filter(item => {
      // return item.tuClassList?.includes(group.GrpCode) && (!item.removed || showRemoved)
      return item.tuClassList?.includes(group.GrpCode)
    })

    //
    // 두 items 중 큰 것을 기준으로 함.
    // 
    selectedItems = selItems
    if (selItems.length < selItems2.length) {
      const itemCodes = selItems.map(o => o.itemCode)
      selItems2.map(item2 => {
        if (!itemCodes.includes(item2.itemCode)) selectedItems.push(item2)
        return null
      })
    }

  }

  else if (posName === "UNIONPOS") {
    // selectedItems = itemList.filter((item) => (item.groupCode === group.GrpCode) && (!item.removed || showRemoved));
    selectedItems = itemList.filter((item) => (item.groupCode === group.GrpCode));
  }

  else if (
    posName === "DUMMY_POS" || 
    posName === "EASY_POS" ||
    posName === "OASIS_POS"
  ) {
    selectedItems = itemList.filter((item) => (item.groupCode === group.groupCode));
  }

  else {
    // do nothing
  }

  return selectedItems
}
