import { Storage, Auth, API } from "aws-amplify";
import axios from "axios";
import { conf, debug } from "../settings";
import { enquireLicenseInfo } from "../settings/conf";

/** ***************************************************************************
 * @StorageApi_AWS_S3
 *  **************************************************************************/

/**
 * Storage_put 은 /public/filename 형태로 저장 /public 이 퍼블릭이므로 filename 만 있으면 불러올수 있음.
 */
export async function s3UploadPublic(file, foldername) {
  const filename = `${Date.now()}-${file.name}`;
  const filepath = foldername
    ? foldername + "/" + filename
    : filename;
  const stored = await Storage.put(filepath, file, {
    contentType: file.type,
  });
  return stored.key; // stored = {key}
}

/**
 * Storage_vault_put 은 /private/userid/filename 형태로 저장 여기서는 사용하지 않음.
 */
export async function s3UploadPrivate(file) {
  const filename = `${Date.now()}-${file.name}`;
  const stored = await Storage.vault.put(filename, file, {
    contentType: file.type,
  });
  return stored.key; // stored = {key}
}

/**
 * @info_filename_실제_선택파일명과_동일하게_하였음 파일이 너무 많아지므로, 단 여기에서는 문제가 없어보임.
 *
 * @param {Object} file file object
 * @param {*} prefix s3Url/public/prefix/filename 형태로 저장됨
 * @returns
 */
export async function fileUpload(file, prefix) {
  try {

    if (file && file.size > conf.MAX_FILE_SIZE) {
      const msg = `파일크기는 ${conf.MAX_FILE_SIZE / 1000000
        } MB 미만이어야 합니다.`;
      return { err: { message: msg } };
    }
    if (file) {
      // const filename = `${Date.now()}-${file.name}`;
      const filename = `${file.name}`;

      // 
      // 한글 파일 문제 해결을 위해 filename을 utf8로 인코딩하려고 했으나.
      // S3 upload시 Error 발생함.
      // const filename = encodeURI(file.name);
      // console.log("filename", filename);

      const filepath = prefix
        ? prefix + "/" + filename
        : filename;
      const stored = await Storage.put(filepath, file, {
        contentType: file.type,
      });
      if (debug) console.log("stored", stored);

      //
      // stored.key === filepath 이다.
      //
      if (debug) console.log('image path', `${conf.s3.publicUrl}/${stored.key}`);
      return { key: `${conf.s3.publicUrl}/${stored.key}` };
    }
    return { err: { message: "NOFILE" } };
  } catch (e) {
    return { err: { message: e.message } };
  }
}

/** ***************************************************************************
 * @ 인증번호 get
 *  **************************************************************************/

/** ***************************************************************************
 * @smsApi
 *  **************************************************************************/

const sms = conf.apiGateway.SMS.API_NAME;

export const codeGet = ({ phone_number }) => {
  // if (debug) console.log("phone_number: ", phone_number);
  return API.get(sms, `/code`, {
    queryStringParameters: { phone_number },
  });
};

/** ***************************************************************************
 * @PasswordApi_AWS_Cognito
 *  **************************************************************************/
// export function changePassword(oldPassword, newPassword) {
//   console.log("old: ", oldPassword, newPassword);
//   Auth.currentAuthenticatedUser().then((user) => {
//     return Auth.changePassword(
//       user,
//       oldPassword,
//       newPassword
//     );
//   });
// }
// export function forgotPassword(username) {
//   return Auth.forgotPassword(username);
// }
// export function forgotPasswordSubmit(
//   username,
//   code,
//   password
// ) {
//   return Auth.forgotPasswordSubmit(
//     username,
//     code,
//     password
//   );
// }

/** ***************************************************************************
 * @Auth_Api_AWS_Cognito
 *  **************************************************************************/
export const logIn = (username, password) => {
  return Auth.signIn(username, password);
};
export const logOut = () => {
  return Auth.signOut();
}
export const signUp = ({ username, password, attributes }) => {
  return Auth.signUp({ username, password, attributes });
};
export const confirmSignUp = ({ username, confirmationCode }) => {
  return Auth.confirmSignUp(username, confirmationCode);
};
export const resendSignUp = ({ username }) => {
  return Auth.resendSignUp(username);
};
export const currentSession = () => {
  return Auth.currentSession();
};
export const currentUser = () => {
  return Auth.currentUserPoolUser();
};
export const currentCredentials = () => {
  return Auth.currentCredentials();
};

/** ***************************************************************************
 * @CommonStack_Auth
 * @User
 *  **************************************************************************/
const user = conf.apiGateway.USER.API_NAME;

export const userCreate = ({ body }) => {
  return API.post(user, `/user`, { body: body });
};
export const userGet = ({ username }) => {
  return API.get(user, `/user?username=${username}`);
};
export const userUpdate = ({ username, body }) => {
  return API.put(user, `/user?username=${username}`, {
    body: body,
  });
};
export const userDelete = ({ username }) => {
  return API.del(user, `/user?username=${username}`);
};
export const userAttachPolicy = ({ body }) => {
  // {target: identityId, policyName: 'myPolicy'}
  return API.put(user, `/attach-policy`, { body: body });
};
export const isExistUsername = ({ username }) => {  
  return API.get(user, `/cognito-user?username=${username}`);
}
export const changePassword = ({ username, password }) => {
  return API.put(user, `/cognito-user`, { queryStringParameters: { username: username, password: password } });
}

/** ***************************************************************************
 * @TablePay_stack
 *
 * @Shop
 *  **************************************************************************/
const shop = conf.apiGateway.SHOP.API_NAME;

export const shopCreate = ({ shopId, body }) => {
  return API.post(shop, `/shop/${shopId}`, { body: body });
};
export const shopGet = ({ shopId }) => {
  return API.get(shop, `/shop/${shopId}`);
};
export const shopUpdate = ({ shopId, body }) => {
  return API.put(shop, `/shop/${shopId}`, { body: body });
};
export const shopDelete = ({ shopId }) => {
  return API.del(shop, `/admin/shop/${shopId}`);
};

/** ***************************************************************************
 * @ShopItem
 *  **************************************************************************/
export const shopItemCreate = ({ shopId, body }) => {
  return API.post(shop, `/shopitem/${shopId}`, {
    body: body,
  });
};
export const shopItemGet = ({ shopId, id }) => {
  return API.get(shop, `/shopitem/${shopId}?id=${id}`);
};
export const shopItemList = ({ shopId }) => {
  return API.get(shop, `/shopitem/${shopId}/list`);
};
export const shopItemUpdate = ({ shopId, id, body }) => {
  return API.put(shop, `/shopitem/${shopId}?id=${id}`, {
    body: body,
  });
};
export const shopItemDelete = ({ shopId, id }) => {
  return API.del(shop, `/shopitem/${shopId}?id=${id}`);
};

/** ***************************************************************************
 * @ShopItemOption
 *  **************************************************************************/
export const shopItemOptionCreate = ({ shopId, body }) => {
  // body={optionName=required, 저장할 속섲들...}
  return API.post(shop, `/shopitem-option/${shopId}`, {
    body: body,
  });
};
export const shopItemOptionGet = ({
  shopId,
  optionName,
}) => {
  return API.get(
    shop,
    `/shopitem-option/${shopId}?optionName=${optionName}`
  );
};
export const shopItemOptionList = ({ shopId }) => {
  return API.get(shop, `/shopitem-option/${shopId}/list`);
};
export const shopItemOptionUpdate = ({
  shopId,
  optionName,
  body,
}) => {
  // body={수정할 속성들...}
  return API.put(
    shop,
    `/shopitem-option/${shopId}?optionName=${optionName}`,
    { body: body }
  );
};


/** ***************************************************************************
 * @NfcTag
 *  **************************************************************************/

const tag = conf.apiGateway.TAG.API_NAME;

export const nfcTagCreate = ({ ver, body }) => {
  return API.post(tag, `/admin/nfctag/${ver}`, {
    body: body,
  });
};
export const nfcTagGet = ({ ver, uid }) => {
  return API.get(tag, `/admin/nfctag/${ver}?uid=${uid}`);
};
export const nfcTagUpdate = ({ ver, uid, body }) => {
  return API.put(tag, `/admin/nfctag/${ver}?uid=${uid}`, {
    body: body,
  });
};


/** ***************************************************************************
 * @Order_Api
 *  **************************************************************************/
const order = conf.apiGateway.ORDER.API_NAME;

export const orderCreate = ({ shopId, body }) => {
  return API.post(order, `/order-records/${shopId}`, { body: body });
};
export const orderGet = ({ shopId, orderId }) => {
  return API.get(order, `/order-records/${shopId}?orderId=${orderId}`);
};
export const orderList = ({ shopId }) => {
  return API.get(order, `/order-records/${shopId}/list`);
};


/** ***************************************************************************
 * @Pos_Api
 *  **************************************************************************/
const pos = conf.apiGateway.POS.API_NAME;

export const unionposProxy = ({ apiName, body }) => {
  // 
  // apiName: 아래 중 하나임.
  //  shopInfo | groupList | itemList | soldoutList | imageList |
  //  tableList | descriptionList | order | tableOrderList
  // body: { StoreCode }
  return API.post(pos, `/unionpos?apiName=${apiName}`, { body });
};

export const unionposCopyImage = ({ body }) => {
  /**
    * @info_unionpos_이미지를_download_하여_aws_s3Strorage로_copy함
    * @body shopId, imgUrl
    */
  return API.post(pos, `/unionpos/copy-image`, { body });
};



/** ***************************************************************************
 * @사업자등록번호 조회
 * @param_licenseNumber : string array
 *  **************************************************************************/
export const enquireLicense = async (licenseNumber) => {
  const url = enquireLicenseInfo.url + enquireLicenseInfo.serviceKey;
  const body = {
    "b_no": licenseNumber
  }
  try {
    let result = '';
    const res = await axios({
      method: 'POST',
      json: true,
      url: url,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      data: body
    })
    if (debug) console.log('res axios', res)
    // result = JSON.stringify(res.data.data[0].b_stt);
    result = res.data.data[0].b_stt;
    return result;
  } catch (e) {
    if (debug) console.log('axios.post Error: ', e);
    return 'error';
  }
};