import axios from "axios";
import { debug, conf } from "../settings";
import { ec2Api } from "../settings/appSetting";
import { strCompress, strDecompress } from "./utils";

export const axiosCall = async ({ method, path, body }) => {
  try {
    // const baseUrl = conf.ec2Debug ? "http://localhost:5000" : conf.ec2ApiEndpoint
    const baseUrl = ec2Api.debug ? ec2Api.endpoint_debug : ec2Api.endpoint_public

    let res = await axios({
      method,
      url: baseUrl + path,
      data: body,
      headers: { 'x-taptag-key': conf.apiKey }
    });

    if (res.status !== 200) throw new Error("Response Error");
    return res.data;
  } catch (e) {
    return { err: { message: e.message } };
  }
}

/** @Misc **************************************/
export const sendEmail = async ({ body }) => {
  const path = `/misc/send-email`;
  // body: {senderInfo, contactInfo, msg}
  return await axiosCall({ method: "POST", path, body });
};
export const sendSms = async ({ phone_number }) => {
  const path = `/misc/sms?phone_number=${phone_number}`;
  return await axiosCall({ method: "GET", path });
};
export const sendSmsText = async ({ phone_number, body }) => {
  const path = `/misc/sms?phone_number=${phone_number}`;
  return await axiosCall({ method: "POST", path, body });
};
export const getOrderRecords = ({ shopId, start_time, end_time }) => {
  const path = `/misc/order-records?shopId=${shopId}&start_time=${start_time}&end_time=${end_time}`;
  return axiosCall({ method: "GET", path });
};

/** @user **************************************/
export const userCreate = async ({ username, body }) => {
  // body = {userType, phone_number, email, nickName, approved=false}
  const path = `/user?username=${username}`;
  return await axiosCall({ method: "POST", path, body });
};
export const userGet = async ({ username }) => {
  const path = `/user?username=${username}`;
  return await axiosCall({ method: "GET", path });
};
export const userList = async ({ userType }) => {
  const path = `/user/list?userType=${userType}`;
  return await axiosCall({ method: "GET", path });
};
export const userUpdate = async ({ username, body }) => {
  const path = `/user?username=${username}`;
  return await axiosCall({ method: "PUT", path, body });
};

/** @cognito **************************************/
export const isExistUsername = async ({ username }) => {
  const path = `/user/cognito?username=${username}`;
  return await axiosCall({ method: "GET", path });
};
export const cognitoListUsers = async ({ phone_number }) => {
  const path = `/user/cognito/list-users?phone_number=${phone_number}`;
  return await axiosCall({ method: "GET", path });
};
export const changePassword = async ({ username, password }) => {
  const path = `/user/cognito/changepw?username=${username}&password=${password}`;
  return await axiosCall({ method: "POST", path });
};

/** @Shops **************************************/
export const shopCreate = async ({ shopId, body }) => {
  const path = `/shop?shopId=${shopId}`;
  return await axiosCall({ method: "POST", path, body });
};
export const shopGet = async ({ shopId }) => {
  const path = `/shop?shopId=${shopId}`;
  // const path = `/shop/${shopId}`; // deprecated
  return await axiosCall({ method: "GET", path });
};
export const shopUpdate = async ({ shopId, body }) => {
  const path = `/shop?shopId=${shopId}`;
  return await axiosCall({ method: "PUT", path, body });
};

/** @ShopIitems **************************************/
export const shopItemCreate = ({ shopId, body, id }) => {
  let path = `/shopitem/${shopId}`;
  if (id) path += `?id=${id}`;
  return axiosCall({ method: "POST", path, body });
};
export const shopItemGet = ({ shopId, id }) => {
  const path = `/shopitem/${shopId}?id=${id}`;
  return axiosCall({ method: "GET", path });
};
export const shopItemUpdate = ({ shopId, id, body }) => {
  const path = `/shopitem/${shopId}?id=${id}`;
  return axiosCall({ method: "PUT", path, body });
};
export const shopItemDelete = async ({ shopId, id }) => {
  const path = `/shopitem/${shopId}?id=${id}`;
  return await axiosCall({ method: "DELETE", path });
};
export const shopItemList = async ({ shopId, token }) => {
  let path = `/shopitem/${shopId}/list/auth?token=${token}`;

  // 
  // user 앱에서는 토큰이 필요하지만 shop 앱에서는 필요없다.
  // 
  path += '&mode=debug';
  return await axiosCall({ method: "GET", path });
};

export const okposItemCreate = ({ shopId, body, id }) => {
  let path = `/shopitem/okpos/${shopId}`;
  if (id) path += `?id=${id}`;
  return axiosCall({ method: "POST", path, body });
};
export const okposItemGet = ({ shopId, id }) => {
  const path = `/shopitem/okpos/${shopId}?id=${id}`;
  return axiosCall({ method: "GET", path });
};
export const okposItemUpdate = ({ shopId, id, body }) => {
  const path = `/shopitem/okpos/${shopId}?id=${id}`;
  return axiosCall({ method: "PUT", path, body });
};
export const okposItemDelete = async ({ shopId, id }) => {
  const path = `/shopitem/okpos/${shopId}?id=${id}`;
  return await axiosCall({ method: "DELETE", path });
};
export const okposItemList = async ({ shopId, token }) => {
  let path = `/shopitem/okpos/${shopId}/list/auth?token=${token}`;

  // 
  // user 앱에서는 토큰이 필요하지만 shop 앱에서는 필요없다.
  // 
  path += '&mode=debug';
  return await axiosCall({ method: "GET", path });
};

/** @ShopIitemOptions **************************************/

export const shopItemOptionCreate = ({ shopId, optionName, body }) => {
  let path = `/shopitem-option/${shopId}`;
  if (optionName) path += `?optionName=${optionName}`;
  return axiosCall({ method: "POST", path, body });
};

// export const shopItemOptionGet = ({ shopId, optionName }) => {
//   let path = `/shopitem-option/${shopId}?optionName=${optionName}`;
//   return axiosCall({ method: "GET", path });
// };

/** @Decompress_logic_added */
export const shopItemOptionGet = async ({ shopId, optionName }) => {
  try {

    let path = `/shopitem-option/${shopId}?optionName=${optionName}`;
    // return axiosCall({ method: "GET", path });
    let res = await axiosCall({ method: "GET", path });
    if (res.err) throw Error(res.err.message)

    const newOptions = { ...res.result.Item }

    if (optionName === "OASIS_POS") {
      if (newOptions.groupList_comp) {
        newOptions.groupList = strDecompress(newOptions.groupList_comp)
        delete newOptions.groupList_comp
      }
      if (newOptions.setMenuList_comp) {
        newOptions.setMenuList = strDecompress(newOptions.setMenuList_comp)
        delete newOptions.setMenuList_comp
      }
    }

    if (optionName === "EASY_POS") {
      if (newOptions.subItemList_comp) {
        newOptions.subItemList = strDecompress(newOptions.subItemList_comp)
        delete newOptions.subItemList_comp
      }
    }

    return { result: { Item: newOptions } }
  } catch (e) {
    return { err: { message: e.message } }
  }
};


export const shopItemOptionList = ({ shopId }) => {
  let path = `/shopitem-option/${shopId}/list`;
  return axiosCall({ method: "GET", path });
};

// export const shopItemOptionUpdate = ({ shopId, optionName, body }) => {
//   const path = `/shopitem-option/${shopId}?optionName=${optionName}`;
//   return axiosCall({ method: "PUT", path, body });
// };

/** @Compress_logic_added */
export const shopItemOptionUpdate = ({ shopId, optionName, body }) => {
  const path = `/shopitem-option/${shopId}?optionName=${optionName}`;

  const newBody = { ...body }
  if (optionName === "OASIS_POS") {
    Object.keys(body).map(key => {
      if (key === "setMenuList" || key === "groupList") {
        newBody[key + "_comp"] = strCompress(body[key])
        newBody[key] = JSON.stringify([])
      }
      return null
    })
  }

  if (optionName === "EASY_POS") {
    Object.keys(body).map(key => {
      if (key === "subItemList") {
        newBody[key + "_comp"] = strCompress(body[key])
        newBody[key] = JSON.stringify([])
      }
      return null
    })
  }

  return axiosCall({ method: "PUT", path, body: newBody });
};

export const okposItemOptionCreate = ({ shopId, body }) => {
  let path = `/shopitem-option/okpos/${shopId}`;
  return axiosCall({ method: "POST", path, body });
};
export const okposItemOptionGet = ({ shopId }) => {
  let path = `/shopitem-option/okpos/${shopId}`;
  return axiosCall({ method: "GET", path });
};
export const okposItemOptionList = ({ shopId }) => {
  let path = `/shopitem-option/okpos/${shopId}/list`;
  return axiosCall({ method: "GET", path });
};
export const okposItemOptionUpdate = ({ shopId, body }) => {
  const path = `/shopitem-option/okpos/${shopId}`;
  return axiosCall({ method: "PUT", path, body });
};
export const okposItemOptionDelete = async ({ shopId }) => {
  const path = `/shopitem-option/okpos/${shopId}`;
  return await axiosCall({ method: "DELETE", path });
};

export const onlItemOptionCreate = ({ shopId, body, posName }) => {
  if (!posName) return { err: { message: "NO POSNAME" } }
  let path = `/shopitem-option/onl/${shopId}?posName=${posName}`;
  return axiosCall({ method: "POST", path, body });
};
export const onlItemOptionGet = ({ shopId, posName }) => {
  if (!posName) return { err: { message: "NO POSNAME" } }
  let path = `/shopitem-option/onl/${shopId}?posName=${posName}`;
  return axiosCall({ method: "GET", path });
};
export const onlItemOptionUpdate = ({ shopId, body, posName }) => {
  if (!posName) return { err: { message: "NO POSNAME" } }
  const path = `/shopitem-option/onl/${shopId}?posName=${posName}`;
  return axiosCall({ method: "PUT", path, body });
};
export const onlItemOptionDelete = ({ shopId, posName }) => {
  if (!posName) return { err: { message: "NO POSNAME" } }
  let path = `/shopitem-option/onl/${shopId}?posName=${posName}`;
  return axiosCall({ method: "DELETE", path });
};

/** @NTAG424 **************************************/
export const ntag424VerifyPiccData = async ({ body }) => {
  // 
  // body = {ver, piccdata, tableNo, mac}
  // 
  const path = "/nfctag/ntag424/verify-piccdata";
  return await axiosCall({ method: "POST", path, body });
};

export const ntag424VerifyToken = async ({ body }) => {
  // 
  // body= { token, tableNo}
  // 
  const path = "/nfctag/ntag424/verify-token";
  return await axiosCall({ method: "POST", path, body });
};

/** @Pos_Api **************************************/

/** @deprecated */
export const unionposProxy = async ({ apiName, body }) => {
  // 
  // apiName: 아래 중 하나임.
  //  shopInfo | groupList | itemList | soldoutList | imageList | tableList | descriptionList | order
  // 
  let path = `/unionpos?apiName=${apiName}`;
  if (debug) path += '&mode=debug';
  return await axiosCall({ method: "POST", path, body });
};

export const unionposProxy2 = async ({ apiName, body }) => {
  // 
  // apiName: 아래 중 하나임.
  //  shopInfo | groupList | itemList | soldoutList | imageList | tableList | descriptionList | order
  // 
  let path = `/pos/unionpos?apiName=${apiName}`;
  if (apiName === "order") path += '&mode=debug';
  return await axiosCall({ method: "POST", path, body });
};

export const unionposCopyImage = async ({ body }) => {
  // body { shopId, imageUrl }
  let path = `/pos/unionpos/copy-image`;
  return await axiosCall({ method: "POST", path, body });
};

/** @SmilePos **************************************/
export const smileposProxy = async ({ apiName, body }) => {
  // 
  // order
  // orderAdd
  // viewOrders
  // isMenuUpdated
  // updatedMenu
  // tableList
  // 
  let path = `/pos/smilepos?apiName=${apiName}`;
  if (debug) path += '&mode=debug';
  return await axiosCall({ method: "POST", path, body });
};

/** @OKPos **************************************/
export const okposProxy = async ({ apiName, body }) => {
  // 
  // storeInfo | setPolicy | order | orderInfo
  // 
  let path = `/pos/okpos?apiName=${apiName}`;
  if (debug) path += '&mode=debug';
  return await axiosCall({ method: "POST", path, body });
};
export const okposUpdateMenu = async ({ body }) => {
  // 
  // menuUpdate
  // 
  let path = `/pos/okpos/menu-update`;
  // if (debug) path += '&mode=debug';
  return await axiosCall({ method: "POST", path, body });
};

/** @DummyPos_OrderStatus **************************************/
export const dummyposGetOrderList = async ({ shopId, lastModified }) => {
  const path = `/pos/dummypos/order-status?shopId=${shopId}&lastModified=${lastModified}`;
  return await axiosCall({ method: "GET", path });
};

export const dummyposUpdateOrderByOrderId = async ({ shopId, orderId, orderStatus = "A" }) => {
  let path = `/pos/dummypos/order-status/order?shopId=${shopId}`;
  path += `&orderId=${orderId}&orderStatus=${orderStatus}`;
  return await axiosCall({ method: "PUT", path, body: {} });
};

/** @OrderStatus **************************************/
export const getOrderStatusList = async ({ shopId }) => {
  const path = `/pos/order-status/list?shopId=${shopId}`;
  return await axiosCall({ method: "GET", path });
};

export const getLastOrderTime = async ({ shopId }) => {
  let path = `/pos/order-status/last-order-time?shopId=${shopId}`;
  return await axiosCall({ method: "GET", path });
};

export const delOrderStatus = async ({ shopId, orderId }) => {
  let path = `/pos/order-status/order?shopId=${shopId}&orderId=${orderId}`;
  return await axiosCall({ method: "DELETE", path });
};

export const updateOrderStatus = async ({ shopId, orderId, body }) => {
  let path = `/pos/order-status/order?shopId=${shopId}&orderId=${orderId}`;
  return await axiosCall({ method: "PUT", path, body });
};

/** @PAYMENT **********************************************/

/** @deprecated */
export const savePaymentKeysKCP = async ({ body }) => {
  const path = `/payment/setting/kcp/keys`;
  return axiosCall({ method: "POST", path, body });
};

/** @deprecated */
export const getPaymentKeysKCP = async ({ shopId }) => {
  const path = `/payment/setting/kcp/keys?shopId=${shopId}`;
  return axiosCall({ method: "GET", path });
};

export const savePaymentKeys = async ({ shopId, body }) => {
  // body = {[pgName]: JSON.stringify({...keys})}
  const path = `/payment/setting/keys?shopId=${shopId}`;
  return axiosCall({ method: "POST", path, body });
};

export const getPaymentKeys = async ({ shopId }) => {
  const path = `/payment/setting/keys?shopId=${shopId}`;
  return axiosCall({ method: "GET", path });
};

// /** @deprecated */
// export const setPaymentOptions = async ({ shopId, body }) => {
//   const path = `/payment/setting/options?shopId=${shopId}`;
//   return axiosCall({ method: "POST", path, body });
// };

export const goToPay = ({ shopId, orderId }) => {
  const paymentEndpoint = "http://pay.onl.taptag.co.kr/kcp/trade_reg";
  let payUrl = paymentEndpoint
  payUrl += `?shopId=${shopId}&orderId=${orderId}`
  window.location.href = payUrl
};

/** @deprecated */
export const payDataCreate = ({ posName, shopId, ver, body }) => {
  const path = `/payment/pay-record?shopId=${shopId}&posName=${posName}&ver=${ver}`;
  return axiosCall({ method: "POST", path, body });
};

export const getPayRecords = ({ shopId, start_time, end_time }) => {
  const path = `/payment/pay-record/list?shopId=${shopId}&start_time=${start_time}&end_time=${end_time}`;
  return axiosCall({ method: "GET", path });
};
