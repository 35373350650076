export const stage = "prod";
export const appName = "onl-shop";

export const ec2ApiEndpoint = "https://api.onl.taptag.co.kr";

export const authEndpoint = "https://auth.onl.taptag.co.kr/";

export const payEndpoint = "https://pay.onl.taptag.co.kr";

export const apiKey = "F43653B64C291AFDE25B7365A64970DE";

export const ec2Debug = false;
// export const ec2Debug = true;

export const dqrSettingDebug = false;
// export const dqrSettingDebug = true;

export const payApiDebug = false;
// export const payApiDebug = true;

export const debug = false;
// export const debug = true;


///////////////////////////////////////////////////////////////////////////////
export const ec2Api = {
  debug: ec2Debug,
  // endpoint_debug: "http://172.30.1.27:5000",
  endpoint_debug: "http://localhost:5000",
  endpoint_public: ec2ApiEndpoint,
}

export const payApi = {
  debug: payApiDebug,
  endpoint_debug: "http://172.30.1.27:5050",
  endpoint_public: payEndpoint,
}

export const prePayPosList = ["UNIONPOS", "OASIS_POS"]

// 
// dev 에서만 사용
// 
export const oasispos = {
  debug: ec2Debug,
  apiEndpoint_local: "http://localhost:5000",
  apiEndpoint_public: ec2ApiEndpoint,
}

//////////////////////////////////////////////////////////////////////////////
